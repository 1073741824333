.audit {
	line-height: normal;
	background: #F7F7F9;

	section {
		.section__title-block {
			font-size: 60px;
		}
		.section__description {
			margin-left: 130px;
			font-size: 24px;
		}
	}

	&__row {
		.section__description {
			margin-left: 0;
		}
		.blocks {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			max-width: 1041px;
			grid-gap: 50px;
			margin-bottom: 60px;

			.block {
				padding: 35px;
				background: #fff;
				display: grid;
				grid-template-rows: auto 1fr auto;
				transition: 0.3s all;

				&.active {
					box-shadow: inset 0 0 0 3px $primary-red !important;
				}

				&:hover {
					box-shadow: inset 0 0 0 3px #000;
				}

				&.disable {
					h3 {
						opacity: 0.3;
					}

					ul {
						opacity: 0.3;
					}

					.button {
						opacity: 0.3;
						button {
							box-shadow: inset 0 0 0 2px #BEBEBE;
							color: #BEBEBE;
						}

						&:hover {
							opacity: 1;
						}
					}

					&:hover {
						h3, ul, .button {
							opacity: 1;
						}
					}
				}

				h3 {
					margin-bottom: 0px;
					display: inline-block;
					font-size: 16px;
					font-weight: 900;
					line-height: 140%;
					letter-spacing: 4.2px;
					text-transform: uppercase;
					color: #1e1e23;
					transition: all .3s;
				}

				ul {
					margin: 35px 0;
					& > li {
						// font-weight: 500;
						font-size: 18px;
						line-height: 160%;
						position: relative;
						padding-left: 18px;
						padding-bottom: 18px;

						&:last-child {
							padding-bottom: 0;
						}

						
						&:before {
							position: absolute;
							content: '';
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background: #575757;
							left: 0;
							top: 14px;
						}
					}
				}

				.button {
					a {
						&.btn {
							background: #FFFFFF;
							box-shadow: inset 0 0 0 2px #000;
							transition: .3s;
							width: 100%;
	
							&.active {
								box-shadow: inset 0 0 0 2px $primary-red;
								color: #fff !important;
								background: $primary-red;
							}
	
							&:hover {
								box-shadow: inset 0 0 0 2px $primary-red;
								color: $primary-red;
							}
						}
					}
				}
			}
		}
	}

	.careers__form {
		padding-top: 30px;
		display: none;

		.wpcf7-form {
			// max-width: 100%;
			padding: 50px 40px;
		}
	}
}

@media (max-width: 1200px) {
	.audit__row {
		.blocks {
			grid-template-columns: 1fr 1fr;
		}
	}
}


@media (max-width: 992px) {
	.audit__row {
		.blocks {
			grid-template-columns: 1fr;
		}
	}
}
.client-block {
  background: #fff;
  padding: 40px;
  margin-bottom: 40px;
  transition: 0.2s all;
  width: 100%;

  a {
    text-decoration: none !important;
  }

  &.white {
    .content {
        .right_side {
            .client_stage {
                button {
                    color: #000;
                }
            }

            .read_more {
              a {
                background: $primary;
              }
            }
        }

        .left_side {
          @media (max-width: 992px) {
              border-bottom: 1px solid #00000012;
          }
        }
    }
  }

  @media (max-width: 992px) {
    padding: 35px 30px;
  }

  .content {
      display: grid;
      grid-template-columns: 1fr 220px;
      grid-gap: 60px;

      @media (max-width: 992px) {
        grid-gap: 20px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr;
      }

      .left_side {
          display: grid;
          grid-template-rows: auto 1fr auto;
          position: relative;
          grid-gap: 23px;

          .title {
            display: grid;
            grid-gap: 20px;

            a {
              @media (max-width: 992px) {
                padding-bottom: 20px;
              }
            }
          }


          .client_logo {
            display: none;
            grid-row: 1;

            img {
              max-width: 160px;

              @media (max-width: 992px) {
                margin: 0 auto;
              }
            }

            @media (max-width: 1200px) {
              display: block;
            }
          }

          @media (max-width: 992px) {
            grid-gap: 0;
            border-bottom: 1px solid #ffffff26;
            padding-bottom: 12px;
          }

          & > .date {
            display: none;
            color: #cfcfcf;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
          }

          @media (max-width: 1200px) {
            & > .date {
              display: block;
            }
          }


          &:after {
              position: absolute;
              right: -38px;
              background: #f2f2f2;
              width: 1px;
              height: 100%;
              content: '';
              top: 0;

              @media (max-width: 1200px) {
                right: auto;
                left: 0;
                bottom: -38px;
                top: auto;
                width: 100%;
                height: 1px;
              }

              @media (max-width: 992px) {
                display: none;
                // grid-gap: 20px;
              }
          }

          a {
              &.title {
                  color: #1e1e23;

                  h4 {
                      font-size: 22px;
                      font-weight: 600;
                      line-height: 130%;

                      @media (max-width: 1367px) {
                        font-size: 20px;
                      }
                  }
              }
          }

          & > p {
              font-size: 14px;
              color: #1e1e23;
              line-height: 160%;

              a {
                position: relative;
                display: inline-block;
              }
          }

          .details {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 60px;
              position: relative;
              
              .empty {
                font-size: 12px;
              }
              &:before {
                  position: absolute;
                  top: -13px;
                  content: '';
                  left: 0;
                  height: 1px;
                  width: 100%;
                  background: #f2f2f2;

                  @media (max-width: 992px) {
                    display: none;
                  }
              }

              &:after {
                position: absolute;
                top: 0;
                content: '';
                left: 50%;
                width: 1px;
                height: 100%;
                background: #f2f2f2;

                @media (max-width: 992px) {
                  display: none;
                }
            }

              h4 {
                display: inline-block;
                font-size: 12px;
                font-weight: 900;
                line-height: 140%;
                letter-spacing: 2.2px;
                text-transform: uppercase;
                color: #1e1e23;
                transition: all .3s;
                position: relative;
              }

              ul {
                li {
                  position: relative;
                  padding-left: 13px;

                  &:before {
                      position: absolute;
                      left: 0;
                      top: 11px;
                      content: '';
                      width: 4px;
                      height: 4px;
                      background: $primary_red;
                  }

                  button {
                      padding: 0;
                      font-family: "Mont";
                      font-size: 14px;
                      text-align: left;
                      line-height: 20px;
                  }
                }
              }

              @media (max-width: 992px) {
                grid-template-columns: 1fr;
              }

              .author {
                  color: #cfcfcf;
                  letter-spacing: 2.2px;
                  text-transform: uppercase;
                  font-size: 12px;
                  font-weight: 800;
                  transition: 0.2s all;
              }

              .date {
                  color: #cfcfcf;
                  letter-spacing: 2.2px;
                  text-transform: uppercase;
                  font-size: 12px;
                  font-weight: 600;
                  transition: 0.2s all;

                  @media (max-width: 1200px) {
                    display: none;
                  }
              }

              @media (max-width: 992px) {
                grid-gap: 20px;
              }
          }
      }

      .right_side {
          display: grid;
          grid-template-rows: 46px auto auto auto;
          grid-gap: 20px;


          @media (max-width: 1200px) {
            .client_logo {
              display: none;
            }
          }

          h4 {
            font-size: 12px;
            font-weight: 900;
            line-height: 140%;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            color: #1e1e23;
            transition: all .3s;
            position: relative;
          }

          .client_logo {
              img {
                  max-width: 190px;
                  max-height: 36px;
                  display: block;
              }
          }

          .client_stage, .client_time {
              font-size: 14px;

              h4 {
                padding-bottom: 4px;
              }

              button {
                  padding: 0;
                  color: #fff;
                  font-family: "Mont";
                  font-size: 14px;
              }
          }

          .read_more {
            text-align: right;
            @media (max-width: 1200px) {
              margin-bottom: -20px;
            }
              a {
                display: inline-block;
                position: relative;
                color: #fff;
                background: $primary_red;
                right: -40px;
                padding: 3px 40px 3px 15px;
                font-size: 12px;
                font-weight: 700;
                line-height: 140%;
                letter-spacing: 2.2px;
                text-transform: uppercase;
                transition: 0.2s all;

                @media (max-width: 1200px) {
                  right: 0;
                  padding: 3px 15px;
                }
              }

              @media (min-width: 1200px) {
                &:hover {
                  a {
                    padding: 3px 55px 3px 15px;
                  }
                }
              }
          }

          .cats {
            overflow: hidden;
            a {
                background: #1e1e23;
                display: block;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 2.2px;
                line-height: normal;
                font-weight: 600;
                color: #fff;
                padding: 5px 5px 3px 7px;
                margin-right: 5px;
                float: left;
                margin-bottom: 5px;
                transition: 0.2s all;

                &:hover {
                  background: transparent;
                  color: #1e1e23;
                  box-shadow: inset 0 0 0 1px #1e1e23;
                }
            }

            button.subcat_link {
                    background: $primary_red;
                    display: block;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 2.2px;
                    line-height: normal;
                    font-weight: 600;
                    color: #fff;
                    padding: 5px 5px 3px 7px;
                    margin-right: 5px;
                    font-family: "Mont", serif;
                    float: left;
                    margin-bottom: 5px;
                    transition: 0.2s all;

                    &:hover {
                      background: transparent;
                      color: $primary_red;
                      box-shadow: inset 0 0 0 1px $primary_red;
                    }
            }
          }

          .tags {
            position: relative;
            padding-left: 21px;
            font-size: 14px;
            font-weight: 600;

            & > button {
              &:last-child {
                margin-right: 0;
                &::after {
                  display: none;
                }
              }
            }

            button {
              background: none;
              border: 0;
              font-family: inherit;
              font-size: 14px;
              padding: 0;
              font-weight: 600;
              position: relative;
              margin-right: 5px;

              &:after {
                position: relative;
                content: ', ';
              }

              &:hover {
                text-decoration: underline;
              }
            }

            @media (max-width: 1200px) {
              margin-top: 15px;
            }

            &:before {
              position: absolute;
              content: '';
              left: 0;
              top: 3px;
              width: 12px;
              height: 13px;
              background: url('../img/tag_icon.svg') center no-repeat;
              background-size: contain;
            }
          }
      }
  }


  &.dark {
      background: #1e1e23;
      color: #fff;

      a {
          color: #fff;
      }

      .content {
        .left_side {
            &:after {
                background: #2e2e33;
            }
            a {
                &.title {
                    color: #fff;
                }
            }

            .details{
                h4 {
                    color: #fff;
                }
                &:before, &:after {
                    background: #2e2e33;
                }

                ul {
                    li {
                        button {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .right_side {
            color: #fff;
            h4 {
                color: #fff;
            }
        }
      }
  }

  &.red {
    background: $primary_red;
    color: #fff;

    a {
        color: #fff;
    }
    .content {
      .left_side {
          &:after {
              background: #fc6565;
          }
          a {
              &.title {
                  color: #fff;
              }
          }

          .details{
              h4 {
                  color: #fff;
              }
              &:before, &:after {
                  background: #fc6565;
              }

              ul {
                  li {
                      button {
                          color: #fff;
                      }

                      &:before {
                          background: #fff;
                      }
                  }
              }
          }
      }

      .right_side {
          color: #fff;
          h4 {
              color: #fff;
          }

          .read_more {
              a {
                  background: #fff;
                  color: $primary_red;
              }
          }
      }
    }
}
}




.post-type-archive-clients, body.post-type-archive-vacancies {

  //.searchandfilter > ul > li.sf-field-taxonomy-clientblockchain > ul > li {
  //    input[type=checkbox]:checked+.sf-label-checkbox::before {
  //      background-color: #1e1e23;
  //      border-color: #1e1e23;
  //      color: #fff;
  //    }
  //}

  .searchandfilter {
    ul {
      li {
        h4 {
          font-size: 22px;
          font-weight: 900;
          text-transform: uppercase;
          padding-left: 0;
          position: relative;
          z-index: 111;
        }

        li {
          &.active-child {
            & > label {
              position: relative;
              font-weight: bold;
              &::after {
                content: '';
                right: -14px;
                top: 6px;
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $primary_red;
              }
            }
          }
        }
      }
    }
    & > ul {
      position: relative;
      z-index: auto;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      min-height: auto;
      display: flex;
      flex-direction: column;

      & > li {
        &.sf-field-taxonomy-clientblockchain > ul > li {
          input[type=checkbox]:checked+.sf-label-checkbox::before {
            background-color: #1e1e23;
            border-color: #1e1e23;
            color: #fff;
          }
        }
      }

      & > li.sf-field-taxonomy-clientcat, & > li.sf-field-taxonomy-vacancycat {
        & > ul {
          & > li {
            & > label {
              &:before {
                background: url('../img/plus.svg') center no-repeat;
                border: 0;
                top: 3px;
              }

              &.active {
                &:before {
                  background: url('../img/minus.svg') center no-repeat;
                  border: 0;
                }
              }
            }
            & > ul {
              display: none;
              padding-left: 23px;
            }
          }
        }
      }
   }

  }

}

.careers {
  background: $background-light-gray;
  &__title {
    &-all {
      display: flex;
    }
    &-right {
      .section__title:first-child {
        margin-left: 35px;
      }
    }
    .section {
      &__title {
        line-height: 110%;
      }
      &__description {
        max-width: 160px;
        font-size: 30px;
        line-height: 120%;
        &-mobile {
          padding-bottom: 5px;
          display: none;
          max-width: 100% !important;
          font-size: 32px;
        }
      }
    }
  }
  &__title .section__description {
    max-width: 130px;
  }
  &__title-right .section__title:last-child {
    margin: 0;
  }
}

.careers__contacts .careers__title,
.blogPage__block .careers__title {
  .section__description {
    margin-right: 25px;
    max-width: 195px;
  }
}

.single-careers {
  .guides__block-list li {
    background: $guides-color-light;
  }
  .careers__contacts {
    padding: 0 0 120px 0;
  }
  .careers__title .section__description {
    display: flex;
    align-items: center;
  }
}

@media(max-width: 1199px) {
  .careers {
    &__title {
      &-right {
        padding-left: 90px;
        display: flex;
        .section__title:last-child {
          margin-left: 20px;
        }
      }
      .section {
        &__title {
          padding-bottom: 10px;
          font-size: 72px;
          &:last-child {
            margin: 0;
          }
        }
        &__description {
          display: none;
          &-mobile {
            display: block;
          }
        }
      }
    }
  }
  .single-careers {
    .careers__title {
      .section__title:last-child {
        margin: 0;
      }
      .section__description {
        display: none;
        &-mobile {
          display: block;
        }
      }
    }
    .careers__form {
      padding: 150px 0;
    }
    .careers__contacts {
      padding: 120px 0;
    }
  }
}

@media(max-width: 991px) {
  .careers {
    &__title-right {
      padding-left: 60px;
      flex-direction: column;
      .section__title:last-child {
        margin-left: 0;
      }
    }
    &__title {
      .section {
        &__description {
          font-size: 22px;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .careers {
    &__title {
      .section {
        &__title {
          font-size: 40px;
        }
        &__description {
          font-size: 16px;
        }
      }
    }
  }
  .single-careers {
    .careers__form,
    .careers__contacts {
      padding: 60px 0;
    }
  }
}

@media(max-width: 375px) {
  .careers {
    &__title {
      .section__title {
        font-size: 34px;
      }
    }
  }
}
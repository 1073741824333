.front-end-block {
  width: 140px;
  height: 140px;
  background-color: transparent;
  position: relative;
}

.front-end-element {
  width: 50px;
  height: 4px;
  background-color: $primary-red;
}

//-----------------------------------------------------------
// VARIABLES
//-----------------------------------------------------------
$colors: (
	'grey': (
	),
	'red': (
	),
	'blue': (
	),
	'green': (
	),
);

$primary: #1E1E23;
$black: #000;
$white: #fff;
$light-grey: #575757;
$primary-light: #9c9ea5;
$primary-extralight: #f9f9fa;
$primary-black: #9F9FA0;
$primary-red: #FC5959;
$primary-red-hover: #c3000d;
$guides-color-light: #E7E7E8;
$guides-color-dark: #1e1e23;
$guides-color-black: #2B2B2E;
$background-gray: #EDEDED;
$background-light-gray: #F7F7F9;
$subscribe-color: #D6D6D6;

//-------------------------------------------------
// Fonts
$mont: "Mont";

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 900;

//-------------------------------------------------
// Break points

$sm: 320;
$sm-roomy: 375;
$md: 760;
$lg: 980;
$xl: 1280;
$xxl: 1400;
$xxxl: 1920;

//-------------------------------------------------
// MISC

$transition-time: 0.3s;
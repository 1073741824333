.bottom-zone {
  position: sticky;
  bottom: 0;
  z-index: 99999;
  backface-visibility: hidden;
  @media (max-width: 768px) {
    display: none;
  }
}

.banner {
  &-main {
    margin: 0;
    font-family: Open Sans,sans-serif;
    color: #fff;
    height: 140px;
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: auto;
    //cursor: pointer;
  }

  &-container {
    width: 138px;
    height: 138px;
    background-color: $primary-red;
    border-radius: 69px;
    position: relative;
    transition: all .4s ease-in-out 0s;
    float: right;
    padding: 12px 0;
    box-shadow: 0 0 30px 0 #00000014;

    .dehive-txt {
      text-align:center;
      margin-top:7px;
      height:19px;
      transition:all .3s ease-out .4s;
      overflow:hidden;

      img {
        margin: 0 auto;
        max-width: 72px;
      }
    }

    .second-block {
      width:0;
      height:138px;
      overflow:hidden;
      opacity:0;

      .block-text {
        font-size:15px;
        white-space:nowrap;
        margin-top:18px;
      }

      .button {
        margin-top:5px;
        display: inline-block;
        margin-bottom:7px;
        position:relative;
        cursor: pointer;
        border-radius:2px;
        overflow:hidden;
      }
      .button,
      .button span {
        width:86px;
        height:25px;
      }
      .button span {
        font-size: 11px;
        white-space: nowrap;
        display: block;
        background-color: #fff;
        color: $primary;
        text-decoration: none;
        line-height: 25px;
        text-align: center;
      }
      .button:after {
        animation-delay:.05s;
        animation-timing-function:ease-in-out;
        animation-iteration-count:infinite;
      }
      .button:after,
      .button:before {
        content:"";
        display:block;
        width:14px;
        height:48px;
        background:#fff;
        position:absolute;
        z-index:1;
        transform:rotate(45deg);
        transition:all .1s;
        -webkit-animation-name:slideme-data;
        animation-name:slideme-data;
        -webkit-animation-duration:3s;
        animation-duration:3s;
        top:-12px;
        left:-50px;
        opacity:.6;
      }
      .button:before {
        animation-delay:.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count:infinite;
      }
      .button:hover:after,
      .button:hover:before {
        -webkit-animation-name:none;
        animation-name:none;
        display:none;
      }
    }

    .first-block {
      //padding-top:29px;
      float:left;

      .logo {
        width:55px;
        height:17px;
        margin-left:42px;

        img {
          width:100%;
          height:100%;
        }
      }

      .dehive-logo {
        width:138px;
        height:82px;
        position: relative;
        animation-name:pulse-off-data;
        animation-duration:1s;
        animation-timing-function:ease-in;
        animation-delay:.3s;
        animation-fill-mode:both;
        animation-iteration-count:infinite;

        img {
          width:100%;
          height:100%;
          transition:all .4s ease-in-out .4s;
        }
      }
    }

    &:hover {
      width:383px;
      transition:all .3s ease-in-out .3s;

      @media (max-width: 768px) {
        //width: 100%;
        width: calc(100% - 180px);
        float: left;
        padding: 12px 30px;
      }

      .first-block {
        @media (max-width: 768px) {
          display: none;
        }
      }
      .dehive-logo {
        img {
          transform: translateY(19px);
        }
      }

      .dehive-txt {
        height:0;
        opacity:0;
        transition:all .3s ease-out;
      }

      .second-block {
        width:220px;
        opacity:1;
        transition:all .4s ease-in-out .4s;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  &-closer {
    width:22px;
    height:22px;
    float:right;
    cursor:pointer;
    background: $primary;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    left: 0;

    svg {
      width: 10px;
      height: 10px;
      display: block;
      margin: 0 auto;
      position: relative;
      top: 6px;

      path {
        fill: #fff;
      }
    }
  }
}
@-webkit-keyframes pulse-off-data {
  0% { transform:scale(.95) }
  25% { transform:scale(.9) }
  75% {  transform:scale(1) }
  to { transform:scale(.95) }
}
@keyframes pulse-off-data {
  0% { transform:scale(.95) }
  25% { transform:scale(.9) }
  75% { transform:scale(1) }
  to { transform:scale(.95) }
}
@-webkit-keyframes slideme-data {
  0% { left:-30px }
  25% { left:120% }
  to { left:120% }
}

.careers {
  &__contacts {
    padding: 0 0 120px 0;
    position: relative;
    z-index: 1;

    .guides__block-list li {
      background: $guides-color-light;
    }

    &__block {
      display: flex;

      &-left {
        position: relative;
        display: inline-block;

        img {
          max-width: 390px;
        }
      }

      &-right {
        margin-left: 20px;
        max-width: 305px;

        h3 {
          font-size: 44px;
          font-weight: 400;
          line-height: 120%;
          color: $black;
        }

        span {
          margin-top: 20px;
          display: block;
          font-size: 14px;
          font-weight: 900;
          line-height: 140%;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          color: $black;
        }

        a {
          margin-top: 20px;
          display: block;
          font-size: 22px;
          font-weight: 400;
          line-height: 135%;
          text-decoration: underline;
          color: $black;
        }
      }
    }
  }
  &__social-responsive {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      margin: 0;
    }
    .link-linkedin-mobile {
      display: none;
      margin-left: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .careers {
    &__contacts {
      padding-top: 120px;
      &__block {
        flex-direction: column;
        &-left {
          >img {
            max-width: 100%;
            width: 100%;
            height: 422px;
            overflow: hidden;
            object-fit: cover;
            object-position: top left;
          }
        }
        &-right {
          margin: 30px 0 0 0;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          h3 {
            font-size: 40px;
          }
          span {
            margin-top: 15px;
            font-size: 20px;
          }
          a {
            font-size: 24px;
          }
          .link-linkedin-mobile img {
            width: 42px;
            height: 42px;
          }
          .careers__social-phone {
            display: none;
          }
        }
      }
    }
    &__social-responsive {
      .link-linkedin-mobile {
        display: block;
      }
    }
  }
}


@media (max-width: 991px) {
  .careers {
    &__contacts {
      &__block {
        &-right {
          h3 {
            font-size: 32px;
          }
          span {
            font-size: 14px;
          }
          a {
            font-size: 18px;
          }
          .link-linkedin-mobile img {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}
@media(max-width: 767px) {
  .careers {
    &__contacts {
      padding: 60px 0;
      &__block {
        &-left {
          >img {
            width: 100%;
            height: 300px;
          }
        }
        &-right {
          h3 {
            font-size: 24px;
          }
          span {
            font-size: 12px;
          }
          a {
            font-size: 16px;
          }
          .link-linkedin-mobile img {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
    &__social-responsive {
      margin-top: 20px;
    }
  }
}

@media(max-width: 480px) {
  .careers {
    &__contacts {
      &__block {
        &-left {
          > img {
            height: 200px;
          }
        }
      }
    }
    &__social-responsive {
      align-items: flex-end;
      .link-linkedin-mobile {
        margin: 20px 0 0 20px;
      }
    }
  }
}


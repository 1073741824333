body.home {
  #blog {
    &.blog {
      background: #1e1e23;
      color: #fff;

      .guides__block-list li {
        background: #505053;
      }

      .blog__more {
        text-align: right;
        padding: 0 25px;

        .btn {
          display: inline-block;
        }
      }

      @media (max-width: 480px) {
        .section__title-block {
          width: 325px;
          margin: 0 auto;
        }
      }

      .blog_items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 60px;
        padding: 0 25px;

        @media (max-width: 1200px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 992px) {
          grid-template-columns: 1fr;
          padding: 0;
        }

        .post-item {
          padding: 35px 45px;
          display: grid;
          grid-template-rows: auto auto 1fr auto;
          grid-gap: 25px;

          @media (max-width: 768px) {
            padding: 20px 25px;
          }

          .category {
            span, a, button {
              letter-spacing: 0.3em;
              text-transform: uppercase;
              font-size: 10px;
              display: inline-block;
              line-height: normal;
              padding: 5px 5px 3px 7px;
              font-weight: 800;
              margin-right: 7px;
            }
          }

          a {
            &.title {
              font-size: 22px;
              color: #000;
              font-weight: bold;
              line-height: 1.2;
            }
          }

          .author {
            color: #cfcfcf;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;

            .date {
              font-weight: 600;
            }
          }
        }

        & > .post-item {
          &:first-child {
            background: #000;
            color: #fff;

            .category {
              span, a, button {
                color: #0a0a0a;
                background-color: #f7f7f9;
              }
            }
            a {
              &.title {
                color: #fff;
              }
            }

            @media (max-width: 992px) {
              background: #f7f7f9;
              color: #0a0a0a;
  
              .category {
                span, a, button {
                  color: #f7f7f9;
                  background-color: #0a0a0a;
                }
              }
              a {
                &.title {
                  color: #0a0a0a;
                }
              }
            }
          }

          &:nth-child(2) {
            background: $primary-red;
            color: #fff;

            .category {
              span, a, button {
                color: $primary-red;
                background-color: #f7f7f9;
              }
            }
            .author {
              color: #fff;
            }
            a {
              &.title {
                color: #f7f7f9;
              }
            }
          }

          &:nth-child(3) {
            background: #f7f7f9;
            color: #0a0a0a;
            .category {
              span, a, button {
                color: #f7f7f9;
                background-color: #0a0a0a;
              }
            }
            
            .author {
              color: #0a0a0a;
            }
            a {
              &.title {
                color: #0a0a0a;
              }
            }
            @media (max-width: 992px) {
              background: #000;
              color: #fff;
  
              .category {
                span, a, button {
                  color: #0a0a0a;
                  background-color: #f7f7f9;
                }
              }
              .author {
                color: #fff;
              }
              a {
                &.title {
                  color: #fff;
                }
              }
            }
          }

          &:nth-child(5) {
            background: #9f9fa0;
            color: #fff;

            .category {
              span, a, button {
                color: #9f9fa0;
                background-color: #fff;
              }
            }
            a {
              &.title {
                color: #fff;
              }
            }

          }

          &:nth-child(6) {
            background: #000;
            color: #fff;

            .category {
              span, a, button {
                color: #0a0a0a;
                background-color: #f7f7f9;
              }
            }
            a {
              &.title {
                color: #fff;
              }
            }

          }

          &:nth-child(4) {
            background: #f7f7f9;
            color: #0a0a0a;

            .category {
              span, a, button {
                color: #f7f7f9;
                background-color: #0a0a0a;
              }
            }
            a {
              &.title {
                color: #0a0a0a;
              }
            }

          }
        }
      }

    }
  }
}

.blog {
  background: $white;
  color: $primary;
  position: relative;
  z-index: 1;
  .guides__block-list li {
    background: $guides-color-light;
  }
  &__description {
    margin-bottom: 30px;
  }
  &__block {
    max-width: 1041px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  &__more {
    max-width: 1041px;
    display: block;
    .btn {
      padding: 17px 0;
      width: 310px;
      line-height: 120%;
    }
  }
}

@media(max-width: 1199px) {
  .blog {
    &__block {
      max-width: 100%;
      justify-content: space-between;
    }
    &__more {
      margin: initial;
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .btn {
        width: 365px;
      }
    }
  }
}

@media(max-width: 991px) {
  .blog {
    &__more .btn {
      width: 314px;
    }
  }
}
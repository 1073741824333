.jfilestyle {
  display: inline-block;
  margin: 0 0 10px;
  padding: 0;
  position: relative;
  border-collapse: separate;

  input, label {
    padding: 10px 15px;
    line-height: normal;
    font-size: 14px;
    vertical-align: middle;
    font-family: sans-serif
  }

  input {
    border:1px solid silver;
    background:#d9d9d9;
    margin:0 -5px 0 0;
    border-radius:0;
    color:#8d8d8d;
    cursor:default
  }

  label {
    display:inline-block;
    text-align:center;
    margin:0;
    width:auto;
    border-radius:0;
    font-weight:400;
    background: #FC5959;
    color: #fff;
    border: 1px solid #FC5959;

    &:hover {
      cursor:pointer;
      opacity:.9
    }

    &[disable] {
      pointer-events:none;
      opacity:.6;
      filter:alpha(opacity=65);
      cursor:not-allowed
    }
  }

  &.jfilestyle-corner {
    input, label {
      &:last-child {
        margin-left:-1px
      }
    }
  }

  .count-jfilestyle {
    background:#303030;
    color:#fff;
    border-radius:50%;
    padding:1px 5px;
    font-size:12px;
    vertical-align:middle
  }
}

.wpcf7 {
  &-list-item {
    margin: 0 !important;
    position: relative;
    display: flex;
    cursor: pointer;
  }
  .wpcf7-response-output {
    margin: 0 !important;
    font-size: 14px;
  }
  &-form {
    br {
      display: none;
    }
    span.wpcf7-not-valid-tip {
      font-size: 12px;
      position: absolute;
    }

    
    textarea {
      width: 100%;
      height: 90px;
      background: transparent;
      border: none;
      border-bottom: 2px solid #e4e4e4;
      font-size: 22px;
      font-weight: 400;
      color: $guides-color-dark;
      cursor: initial;
      font-family: inherit;
      &::placeholder {
        color: #cacaca;
      }
    }
    .input_block {
      margin-bottom: 40px;
    }
    input[type=text],
    input[type=email],
    input[type=url],
    input[type=tel] {
      width: 100%;
      height: 50px;
      background: transparent;
      border: none;
      border-bottom: 2px solid #e4e4e4;
      font-size: 22px;
      font-weight: 400;
      color: $guides-color-dark;
      opacity: 1;
      font-family: $mont;

      &::placeholder {
        color: #cacaca;
      }
    }
    .control--checkbox {
      margin-top: 20px;
      display: flex;
      .checkbox-text {
        font-size: 13px;
        line-height: 200%;
        color: $guides-color-dark;
        a {
          font-weight: 800;
          text-decoration: underline;
        }
      }
      .wpcf7-not-valid-tip {
        position: absolute;
        width: 200px;
      }
    }

    .wpcf7-checkbox {
      label {
        position: relative;
        cursor: pointer;
      }
      input[type=checkbox] {
        margin-right: 15px;
        position: relative;
        visibility: hidden;
        + span {
          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 20px;
            width: 20px;
            top: 2px;
            left: 0;
            background: rgba(214, 214, 214, 0.4);
            border: 1px solid #D6D6D6;
            box-sizing: border-box;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 7px;
            width: 6px;
            height: 12px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            visibility: hidden;
          }
        }
        &:checked + span:before {
          background: $primary-red;
          border: 1px solid $primary-red;
        }
        &:checked + span:after {
          visibility: visible;
        }
      }
    }
  }
}
.wpcf7-response-output.wpcf7-validation-errors {
  margin: 10px 0;
  font-size: 14px;
}

@media (max-width: 1199px) {
  .wpcf7-form textarea {
    height: auto;
    max-height: 56px;
  }
}

@media (max-width: 767px) {
  .wpcf7-form .checkbox-text {
    font-size: 15px;

    a {
      text-decoration: underline;
    }
  }
}

@media (max-width: 480px) {
  .wpcf7-form {
    .input_block {
      margin-bottom: 20px;
    }
    input[type=text],
    input[type=email],
    input[type=url],
    input[type=tel] {
      font-size: 18px;
    }
    textarea {
      font-size: 18px;
    }
  }
}
@charset "UTF-8";

 @font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?12246336');
  src: url('../fonts/fontello.eot?12246336#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?12246336') format('woff2'),
       url('../fonts/fontello.woff?12246336') format('woff'),
       url('../fonts/fontello.ttf?12246336') format('truetype'),
       url('../fonts/fontello.svg?12246336#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
 
.icon-plus:before { content: '\2b'; }
.icon-minus:before { content: '\2d'; }
.icon-tasks:before { content: '\e027'; }
.icon-left-open-big:before { content: '\e800'; }
.icon-right-open-big:before { content: '\e801'; }
.icon-up-open-big:before { content: '\e802'; }
.icon-down-open-big:before { content: '\e803'; }
.icon-telegram:before { content: '\e804'; }
.icon-mail:before { content: '\e805'; }
.icon-mail-1:before { content: '\e806'; }
.icon-tag:before { content: '\e807'; }
.icon-tags:before { content: '\e808'; }
.icon-right-open:before { content: '\e809'; }
.icon-left-open:before { content: '\e80a'; }
.icon-down-open:before { content: '\e80b'; }
.icon-up-open:before { content: '\e80c'; }
.icon-twitter:before { content: '\f099'; }
.icon-facebook:before { content: '\f09a'; }
.icon-linkedin:before { content: '\f0e1'; }
.icon-paper-plane:before { content: '\f1d8'; }
.icon-paper-plane-empty:before { content: '\f1d9'; }
.icon-whatsapp:before { content: '\f232'; }
.icon-search:before { content: '🔍'; }
.icon-clock:before { content: '🕔'; }

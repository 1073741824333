.single-post-item .content .post-content {

.qutote-lab {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 20px;
    align-items: center;
    position: relative;
    border: 1px solid #f2f2f2;
    padding: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    &:before {
        position: absolute;
        content: '';
        width: 24px;
        left: 10px;
        top: -10px;
        z-index: 2;
        height: 24px;
        background: url('../img/double-quotes-v.svg') center no-repeat;
    }

    &_photo {
        position: relative;

        img {
            max-width: 100%;
            display: block;

            @media (max-width: 768px) {
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }
    }

    &_text {
        position: relative;
        font-size: 14px;

        &-author {
            margin-top: 20px;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 10px;
            align-items: center;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }

            h4 {
                line-height: normal;
                margin: 0 !important;
                font-weight: 900;
                letter-spacing: 2.2px;
                text-transform: uppercase;
                font-size: 12px;
                border-right: 2px solid #f2f2f2;
                padding-right: 10px;

                @media (max-width: 768px) {
                    border: 0;
                }
            }

            span {
                line-height: normal;
                font-size: 12px;
            }
        }
    }
}


}
.careers__form {
  padding: 0 0 120px 0;
  position: relative;
  z-index: 1;
  .serviceName {
    font-size: 24px;
    margin-bottom: 30px;
    span {
      font-weight: bold;
    }
  }
  .guides__block-list li {
    background: $guides-color-light;
  }
  .wpcf7-form {
    padding: 50px 40px 80px 40px;
    max-width: 1041px;
    background: $white;
    .add-file {
      margin: 10px 0 20px 0;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      & > p {
        display: none;
      }
      input {
        border: none;
        width: auto;
        height: auto;

        &[type=text] {
          font-size: 18px;
          padding: 0 15px;
        }
      }
      input[name="cv"] {
        margin-bottom: 10px;
      }
      input[type='file'] {
         width: 200px;
         height: 25px;
         opacity: 1;
      }

      .cv-file-wrap {
        position: relative;
        #button-file {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 102px;
          height: 25px;
          position: absolute;
          top: -1px;
          left: 0;
          font-size: 14px;
          font-weight: 400;
          color: $guides-color-dark;
          line-height: 160%;
          letter-spacing: 0.5px;
          background: $background-light-gray;
          border: 1px solid $subscribe-color;
          border-radius: 3px;
          transition: .4s ease-in-out;
          cursor: pointer;
          &:hover {
            border: 1px solid $primary-red;
            background: $primary-red;
            color: $white;
          }
        }
      }
    }
    .btn-wrap {
      margin-top: 40px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
  .input_block_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .input_block {
      width: 430px;
    }
  }
}

@media(max-width: 1199px) {
  .careers__form {
    padding-top: 120px;
    background: $white;
    .guides__block-list {
      display: none;
    }
    .wpcf7-form {
      padding: 20px 0 0 0;
      .input_block {
        width: 100%;
        &:last-child {
          margin-bottom: 30px;
        }
      }
      .btn-wrap {
        align-items: flex-start;
      }
    }
  }
}

@media(max-width: 767px) {
  .careers__form {
    padding: 60px 0;
    .wpcf7-form {
      .add-file {
        margin-bottom: 60px;
        grid-template-columns: 1fr;
        text-align: right;

        span {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .btn-wrap {
        margin-top: 65px;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .wpcf7-form .control--checkbox .wpcf7-not-valid-tip {
    top: 45px;
  }
}

#relatedposts {
  background: #f7f7f9;
  padding: 55px 0;
  overflow: hidden;

  .head {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    h3 {
      font-size: 22px;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .navArrows {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

    }
  }

  .posts-list {
    overflow: hidden;

    &.cases {
      .swiper-slide {
        grid-template-rows: auto auto 1fr auto;
        cursor: pointer;
        padding: 30px 35px;

        &:hover {
          border: 0;
          box-shadow: none;
        }

        &.dark {
          background: $primary;
          color: #fff;

          a.title, .category a, .author {
            color: #fff;

            &:hover {
              color: #fff;
            }
          }

        }
        &.red {
          background: $primary-red;
          color: #fff;


          a.title, .category a, .author {
            color: #fff;

            &:hover {
              color: #fff;
            }
          }

          .category {
            a {
              &:before {
                background: #fff;
              }
            }
          }
        }
        .client-logo {
          img {
            max-width: 160px;
          }
        }
        .category {
          a {
            padding: 0;
            font-family: "Mont";
            position: relative;
            font-size: 14px;
            text-align: left;
            border: 0;
            background: none;
            text-transform: none;
            letter-spacing: normal;
            color: $primary;
            margin: 0;
            float: none;
            line-height: 20px;
            font-weight: normal;
            padding: 4px 0 3px 13px;

            &:hover {
              border: 0;
              box-shadow: none;
              color: $primary;
            }

            &:before {
              position: absolute;
              left: 0;
              top: 11px;
              content: '';
              width: 4px;
              height: 4px;
              background: $primary-red;
            }
          }

          & > h4 {
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: 900;
            line-height: 140%;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            transition: all .3s;
            position: relative;
          }

          ul.services {
            margin-bottom: 15px;
          }
        }
      }
    }

    &-wrap {
      position: relative;

      .swiper-button {
        &-prev {
          color: #000;
          border: 1px solid #000;
          opacity: 0.3;
          transform: none;

          &:hover {
            opacity: 1;
          }
        }

        &-next {
          color: #000;
          border: 1px solid #000;
          opacity: 0.3;
          pointer-events: all !important;
          cursor: pointer;
          transform: none;

          &:hover {
            opacity: 1;
          }
        }
      }

      .swiper-pagination {
        display: none;

        @media (max-width: 768px) {
          display: block;
          position: relative;
          bottom: 0;
          margin-top: 15px;

          .swiper-pagination-bullet-active {
            background: $primary-red;
          }

          .swiper-pagination-clickable .swiper-pagination-bullet {
            outline: none;
          }

        }
      }

    }

    @media (max-width: 768px) {
      overflow: visible;
    }

    .swiper-slide {
      padding: 35px 50px;
      height: initial;
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-gap: 25px;
      background: #ffffff;
      color: #0a0a0a;
      transition: 0.2s all;

      @media (max-width: 768px) {
        padding: 25px 35px;
      }

      a {
        transition: none;
      }

      .category {
        span, a, button {
          letter-spacing: 0.3em;
          text-transform: uppercase;
          font-size: 10px;
          display: inline-block;
          line-height: normal;
          padding: 5px 5px 3px 7px;
          font-weight: 800;
          margin-right: 7px;
          color: #f7f7f9;
          background-color: $primary-red;
          transition: 0.2s all;

          &:hover {
            background: transparent;
            color: $primary-red;
            box-shadow: inset 0 0 0 1px $primary-red;
          }
        }
      }

      a {
        &.title {
          font-size: 18px;
          color: #0a0a0a;
          font-weight: bold;
          line-height: 1.2;
        }
      }

      .author {
        color: #cfcfcf;
        letter-spacing: 2.2px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 900;
        transition: 0.2s all;
      }

      @media (min-width: 768px) {
        &:hover {
          box-shadow: inset 0 0 0 2px #1e1e23;
        }
      }
    }

    @media (max-width: 768px) {
      .swiper-slide {
        transform:scale(0.7);
        opacity: 0.6;

        &.swiper-slide-prev, &.swiper-slide-next {
          transform:scale(0.8);
          opacity: 0.8;
        }

        &.swiper-slide-active {
          transform: scale(1);
          opacity: 1;
          z-index: 111;
        }
      }
    }



  }
}
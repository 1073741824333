.modeling-block {
  width: 140px;
  height: 140px;
  background-color: transparent;
  position: relative;
}

#container {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  text-align: center;
}

svg polygon {
  stroke: #d6d6d6;
  fill: transparent;
  stroke-width: 32;
}

#container > svg {
  margin: 0 auto;

  position: relative;
  left: -10px;
}
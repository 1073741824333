//-----------------------------------------------------------
// BASE
//-----------------------------------------------------------
body {
	font-family: $mont;
	font-weight: 400;
	//font-display:swap;
	color: $primary;
	overflow-x: hidden;
	line-height: 1.5;

	&.blog, &.post-type-archive-vacancies {
		.searchandfilter {
			> ul {
				> li {
					> ul {
						> li {
							&:nth-child(2) {
								input[type=checkbox]:checked+.sf-label-checkbox::before {
									background-color: $primary;
									border-color: $primary;
									color: #fff;
								}
							}
						}
					}

					&.sf-field-taxonomy-tecchlevels {
						> ul {
							> li {
								input[type=checkbox]:checked+.sf-label-checkbox::before {
									background-color: $primary;
									border-color: $primary;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
}

.svg-sprite, .svg-sprite svg{
	opacity: 0;
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	display: none;
}

.search-none {
	padding: 100px 0;
}

.d-none {
	display: none !important;
}
//
//.wpcf7-response-output {
//	display: none;
//}

.swiper-pagination-bullet {
	margin: 0 3px;
}

.breadcrumbs {
	padding: 0 0 5px 0;
	& > ul {
		list-style: none;

		li {
			display: inline;
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			color: #000;

			&+li {
				&:before {
					padding: 8px;
					content: '/';
					font-weight: normal;
				}
			}

			a {
				font-weight: 500;
				color: #000;

				&:hover {
					color: $primary_red;
				}
			}
		}
	}
}



/*Full screen modal*/
.fullscreen-modal {
	background: #fff;
	position: fixed;
	width: 100%;
	padding: 0;
	z-index: 999999;
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-perspective-origin: 50% 100%;
	perspective-origin: 50% 100%;
	-webkit-transition: -webkit-transform .3s ease;
	transition: -webkit-transform .3s ease;
	transition: transform .3s ease;
	height: 100%;
	overflow: hidden;
	top: 0;
	-webkit-transform: perspective(900px) rotateX(-90deg);
	transform: perspective(900px) rotateX(-90deg);

	.guides__block {
		position: relative;
		height: 100%;
	}

	&.active {
		visibility: visible;
		-webkit-transform: perspective(900px) rotateX(0);
		transform: perspective(900px) rotateX(0);
		opacity: 1;
		height: 100%;
	}

	.content {
		display: grid;
		max-width: 800px;
		padding: 27px 0 40px 0;
		width: 100%;
		margin: 0 auto;
		align-items: center;
		height: 100%;
		grid-template-rows: auto 1fr;

		.title {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-gap: 20px;

			.close {
				cursor: pointer;

				svg {
					width: 24px;
					height: 24px;
					display: block;

					path {
						fill: #000;
						opacity: 0.4;
						transition: 0.3s all;
					}
				}

				&:hover {
					svg {
						path {
							opacity: 0.7;
						}
					}
				}
			}
		}

		.cont {
			display: grid;
			align-items: center;
		}


		h2 {
			font-weight: 700;
			display: block;
			text-align: center;
			margin-bottom: 0px;
			font-size: 42px;
			text-transform: uppercase;
			padding-bottom: 0;
		}

		h4 {
			font-size: 32px;
			line-height: 48px;
			padding-bottom: 20px;
		}

		p {
			padding: 0;

			&.description {
				font-size: 22px;
				line-height: 36px;
				padding: 30px 0 50px 0;
				display: block;
				width: 100%;
				max-width: 780px;
			}
		}

		.contact-form {
			.input_block_wrap {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 60px;
			}

			.wpcf7-form {
				.control--checkbox {
					.checkbox-text {
						font-size: 14px;

						a {
							text-decoration: underline;
						}
					}
				}
			}

			.btn-wrap {
				margin-top: 40px;

				.btn-red {
					margin: 0 auto;
					width: 100%;
					max-width: 520px;
				}
			}
		}
	}
}



@media (max-width: 992px) {
	.fullscreen-modal {
		.content {
			overflow-y: scroll;
			h4 {
				font-size: 18px;
				line-height: 32px;
			}

			h2 {
				font-size: 28px;
				line-height: 44px;
				text-align: left;
			}

			p {
				&.description {
					font-size: 18px;
					line-height: 32px;
					padding: 20px 0 30px;
				}
			}

			.contact-form {
				.input_block_wrap {
					grid-template-columns: 1fr;
					grid-gap: 0;
				}
			}
		}
	}
}

.section__title-block {
	&.blog-head {
		display: grid;
		grid-template-columns: 1fr 330px;
		align-items: center;
		grid-gap: 30px;

		@media (max-width: 1200px) {
			grid-template-columns: 1fr auto;
			grid-gap: 10px;
			
			.search-block {
				display: none;
				grid-column: 1/3;

				&.active {
					display: grid;
					grid-column: 1/3;
				}
			}
		}

		button {
			&.blog-search {
				display: none;
				background: none;
				padding: 0;
				margin: 0;

				@media (max-width: 1200px) {
					display: block;
				}
			}
		}

		.search {
			max-width: 330px;

			@media (max-width: 1200px) {
				max-width: 100%;
			}

			.searchandfilter label {
				display: block;
				position: relative;
				height: 40px;

				input {
					width: 100%;
					border: 0;
					font-size: 14px;
					padding: 12px;
					top: 0;
					position: absolute;
				}

				&:before {
					position: absolute;
					right: 19px;
					top: 50%;
					transform: translateY(-50%);
					content: '';
					width: 14px;
					height: 14px;
					background: url('../img/search_icon.svg') center no-repeat;
					background-size: cover;
					z-index: 22;
					opacity: 0.3;
				}
			}
		}
	}
}


.blog-pagination {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 30px;
	align-items: center;


	.pagination {
		display: flex;

		a {
			margin-left: 10px;
			border: 1px solid #1e1e23;
			padding: 5px 7px 3px 7px;
			font-weight: 600;
			font-size: 14px;
			color: #1e1e23;
		}
	}

	.current-page {
		color: #cfcfcf;
		letter-spacing: 2.2px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 800;
	}
}

.searchandfilter ul li {
	padding: 0 !important;
}
body.blog {
	.searchandfilter > ul > li:not(.sf-field-author) > ul > li.sf-level-0 > label {
		font-size: 22px;
		font-weight: 900;
		text-transform: uppercase;
		pointer-events: none;
		margin-bottom: 15px;
		padding-left: 0;
		
		&:before, &:after {
			display: none;
		}
	}
}
.searchandfilter ul li ul li ul {
	margin-left: 0 !important;
	margin-bottom: 10px;

	& > li {
		& > ul {
			margin-left: 26px !important;
			margin-bottom: 0;
		}
	}

	@media (max-width: 1200px) {
		margin-bottom: 5px;
	}
}


.checkbox, .radio, .searchandfilter ul li li {
    display: block;
	padding: 4px 0 !important;

    .wpcf7-list-item {
      display: block;
      margin: 0;
	}
	
	
	input[type=checkbox], input[type=radio] {
		display: none;
		
		&:checked + .sf-label-checkbox {
			font-weight: bold;

			&:hover {
				&:after {
					display: none;
				}
			}
		}

        &:checked + .sf-label-checkbox::before {
          	content: "";
          	color: #333;
          	font-size: 18px;
          	line-height: 22px;
          	text-decoration: inherit;
          	text-align: center;
          	border: 1px solid $primary_red;
			background: $primary_red url('../img/check.svg');
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: 2px 3px;
        }
      }

    .wpcf7-list-item-label, .sf-label-checkbox {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      left: 0;
      position: relative;
	  padding-left: 25px;
	  line-height: 22px;

      &::before {
        background: #e4e4e4;
        border: 1px solid #dbdbdb;
        border-radius: 0px;
        content: "";
        height: 14px;
        left: 0;
        position: absolute;
		width: 14px;
		top: 2px;
      }

      &:hover {
		cursor: pointer;
		
		&::before {
			border-color: #afafaf;
		}
      }
    }
  }

  .radio {
    .wpcf7-list-item-label {
      &::before {
        border-radius: 100%;
      }
    }
  }


  .sf-field-taxonomy-clientcat .scroll-wrapper {
	max-height: 200px;
  }

  .sf-field-taxonomy-clientblockchain .scroll-wrapper {
	max-height: 200px;
  }

  .swiper-button {
	&-prev {
	  color: #fff;
	  border: 1px solid #fff;
	  width: 32px;
	  transform: translateY(22%);
	  height: 32px;
	  outline: none;
	  left: -47px;
	  opacity: 0.3;
	  transition: 0.2s all;
	  &:hover {
		opacity: 1;
	  }

	  &:after {
		font-size: 14px;
	  }
	}

	&-next {
	  color: #fff;
	  border: 1px solid #fff;
	  width: 32px;
	  height: 32px;
	  transform: translateY(22%);
	  right: -47px;
	  outline: none;
	  opacity: 0.3;
	  transition: 0.2s all;
	  &:hover {
		opacity: 1;
	  }
	  &:after {
		font-size: 14px;
	  }
	}
}

.spoiler {
	position: relative; 
	margin: 10px 0;
	width: 100%;

	> .head {
		padding: 16px 46px 16px 70px; 
		position: relative; 
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 18px; 
		font-weight: bold; 
		color: #1e1e23;
		background: #fff;

		@media (max-width: 992px) {
			font-size: 15px;
		}

		&:before {
			content: ''; 
			background: url('../img/down-arrow.svg') center no-repeat;
			position: absolute; 
			top: 50%; 
			transform: translateY(-50%) rotate(0deg); 
			color: $primary_red; 
			right: 20px; 
			line-height: 50px;
			width: 16px;
			height: 16px;
			transition: 0.3s all;
		}

		&.active {
			&:before {
				transform: translateY(-50%) rotate(-180deg);
			}
		}
	}

	> .cont {
		display: none; 
		background: #fff;
		padding: 20px 20px 20px 70px; 
		width: 100%;

		> h4, > h3, > h5, > h6, > h2 { 
			margin-top: 20px; 
		}		
	}
}

.blockchain-block {
  width: 140px;
  height: 140px;
  background-color: transparent;
  position: relative;
}

.blockchain-element {
  width: 10px;
  height: 10px;
  background-color: #F55559;
}
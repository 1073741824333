.staticPage {
  background: #F7F7F9;
  .guides__block-list li {
    background: $guides-color-light;
  }

  &__block {
    max-width: 1041px;
    display: flex;
    flex-direction: column;
  }
  &_date {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.5px;
    color: $light-grey;
    span {
      padding-left: 5px;
    }
    &_text {
      margin-top: 40px;
    }
  }
  &_title {
    margin-top: 60px;
    font-size: 14px;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: $primary;
  }
  &_list {
    padding: 20px 0 0 25px;
    a {
      li {
        padding-bottom: 20px;
        font-size: 22px;
        line-height: 160%;
        color: $light-grey;
        list-style-type: disc;
        span {
          color: $primary-red;
        }
      }
      &:last-child {
        li {
          padding: 0;
        }
      }
    }
  }
  &_description {
    h4 {
      margin: 60px 0 40px 0;
      font-size: 14px;
      font-weight: 900;
      line-height: 140%;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: $primary;
    }
    p {
      margin: 20px 0;
      font-size: 22px;
      line-height: 160%;
      color: $light-grey;
      &:last-child {
        margin: 0;
      }
    }
    ul {
      margin: 20px 0;
      padding-left: 25px;
      li {
        padding-bottom: 20px;
        font-size: 22px;
        line-height: 160%;
        color: $light-grey;
        list-style-type: disc;
        &:last-child {
          padding: 0;
        }
      }
    }
  }
}

@media(max-width: 1199px) {
  .staticPage {
    &__block {
      width: 100%;
    }
    &_list a li {
      font-size: 24px;
    }
    &_description {
      h4 {
        font-size: 18px;
      }
      p {
        font-size: 24px;
      }
      ul li {
        font-size: 24px;
      }
    }
  }
}

@media(max-width: 991px) {
  .staticPage {
    &_list a li {
      font-size: 20px;
    }
    &_description {
      h4 {
        font-size: 14px;
      }
      p {
        font-size: 20px;
      }
      ul li {
        font-size: 20px;
      }
    }
  }
}

@media(max-width: 767px) {
  .staticPage {
    &_list a li {
      font-size: 18px;
    }
    &_title {
      margin-top: 40px;
    }
    &_list a li {
      padding-bottom: 10px
    }
    &_description {
      h4 {
        margin: 40px 0 20px 0;
        font-size: 12px;
      }
      p {
        margin: 15px 0;
        font-size: 18px;
      }
      ul {
        margin: 15px 0;
        li {
          font-size: 18px;
        }
      }
    }
  }
}
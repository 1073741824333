
.go-back a::before, .go-back a::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .has-children > a::before, .go-back a::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .has-children > a::after, .go-back a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .cd-dropdown h2,
  .cd-dropdown-content a,
  .cd-dropdown-content ul a,
  .cd-dropdown-content .cd-divider {
    padding: 0 20px;
  }
  
  .cd-dropdown {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) translateY(-100%);
    -moz-transform: translateZ(0) translateY(-100%);
    -ms-transform: translateZ(0) translateY(-100%);
    -o-transform: translateZ(0) translateY(-100%);
    transform: translateZ(0) translateY(-100%);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
    transition: transform 0.5s 0s, visibility 0s 0.5s;
  }
  
  .cd-dropdown .cd-close {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
  }
  .cd-dropdown .cd-close::after,
  .cd-dropdown .cd-close::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    height: 20px;
    width: 2px;
    background-color: #ffffff;
  }
  .cd-dropdown .cd-close::after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  .cd-dropdown .cd-close::before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
  .cd-dropdown.dropdown-is-active {
    visibility: visible;
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
    transition: transform 0.5s 0s, visibility 0s 0s;
  }
  @media only screen and (min-width: 768px) {
    .cd-dropdown .cd-close {
      top: 5px;
    }
  }
  @media only screen and (min-width: 1200px) {
  
    .cd-dropdown-content .cd-secondary-dropdown > .go-back,
    .cd-dropdown-content .cd-dropdown-gallery > .go-back,
    .cd-dropdown-content .cd-dropdown-icons > .go-back {
      display: none;
    }
  
    .cd-dropdown {
      height: auto;
      width: auto;
      background-color: transparent;
      color: #111433;
      opacity: 0;
    }
    .open-to-left .cd-dropdown {
      right: 0;
      left: auto;
    }
    .cd-dropdown h2, .cd-dropdown .cd-close {
      display: none;
    }
    .cd-dropdown.dropdown-is-active {
      opacity: 1;
    }
  }
  
  .cd-dropdown-content, .cd-dropdown-content ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transform 0.3s;
    padding: 0;
  }
@media (max-width: 1200px) {
  .dropdown-is-active .cd-dropdown-content ul > li {
    text-transform: uppercase;
    font-weight: bold;
  }
    .cd-dropdown-content > li {
        padding: 12px 0;
        text-transform: uppercase;
        font-weight: bold;
      }
      .dropdown-is-active .cd-dropdown-content ul > li {
        a {
          display: inline-block;
        }
      &.current-menu-item {
        a {
          color: $primary_red;
          &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            content: '';
            background: $primary_red;
          }
        }
      }
    }
}
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    display: block;
    overflow: hidden;
    padding: 0;
  }

  .cd-dropdown-content a:hover,
  .cd-dropdown-content ul a:hover {
      color: #fc5959;
  }
  .dropdown-is-active .cd-dropdown-content ul>li.go-back a:hover{
    &::before, &::after {
        background: #fc5959;
      }
  }
  
  .cd-dropdown-content a,
  .cd-dropdown-content .cd-search,
  .cd-dropdown-content .cd-divider,
  .cd-dropdown-content ul a,
  .cd-dropdown-content ul .cd-search,
  .cd-dropdown-content ul .cd-divider {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
  }
  .cd-dropdown-content.is-hidden, .cd-dropdown-content ul.is-hidden {
    transform: translateX(100%);
  }
  .cd-dropdown-content.is-hidden > li > a,
  .cd-dropdown-content.is-hidden > li > .cd-search,
  .cd-dropdown-content.is-hidden > .cd-divider,
  .cd-dropdown-content.move-out > li > a,
  .cd-dropdown-content.move-out > li > .cd-search,
  .cd-dropdown-content.move-out > .cd-divider,
  .cd-dropdown-content ul.is-hidden > li > a,
  .cd-dropdown-content ul.is-hidden > li > .cd-search,
  .cd-dropdown-content ul.is-hidden > .cd-divider,
  .cd-dropdown-content ul.move-out > li > a,
  .cd-dropdown-content ul.move-out > li > .cd-search,
  .cd-dropdown-content ul.move-out > .cd-divider {
    opacity: 0;
  }
  .cd-dropdown-content.move-out > li > a,
  .cd-dropdown-content.move-out > li > .cd-search,
  .cd-dropdown-content.move-out > .cd-divider,
  .cd-dropdown-content ul.move-out > li > a,
  .cd-dropdown-content ul.move-out > li > .cd-search,
  .cd-dropdown-content ul.move-out > .cd-divider {
    transform: translateX(-100%);
  }
  .dropdown-is-active .cd-dropdown-content,
  .dropdown-is-active .cd-dropdown-content ul {
    -webkit-overflow-scrolling: touch;
  }
  @media only screen and (min-width: 1200px) {
    .cd-dropdown-content,
    .cd-dropdown-content ul {
      padding-top: 60px;
    }
    .cd-dropdown-content a,
    .cd-dropdown-content ul a {
      font-size: 1.8rem;
    }
  }
  @media only screen and (min-width: 1200px) {
    .cd-dropdown-content,
    .cd-dropdown-content ul {
      padding-top: 0;
      overflow: visible;
    }
    .cd-dropdown-content a,
    .cd-dropdown-content ul a {
      color: #1E1E24;
      list-style-type: none;
      text-align: left;
    }
    .cd-dropdown-content .cd-divider,
    .cd-dropdown-content ul .cd-divider {
      background-color: transparent;
      color: #b3b3b3;
      border-top: 1px solid #ebebeb;
    }
    .cd-dropdown-content .cd-divider + li > a,
    .cd-dropdown-content ul .cd-divider + li > a {
      border-top-width: 1px;
    }
    .cd-dropdown-content.is-hidden > li > a,
    .cd-dropdown-content.is-hidden > li > .cd-search,
    .cd-dropdown-content.is-hidden > .cd-divider,
    .cd-dropdown-content.move-out > li > a,
    .cd-dropdown-content.move-out > li > .cd-search,
    .cd-dropdown-content.move-out > .cd-divider,
    .cd-dropdown-content ul.is-hidden > li > a,
    .cd-dropdown-content ul.is-hidden > li > .cd-search,
    .cd-dropdown-content ul.is-hidden > .cd-divider,
    .cd-dropdown-content ul.move-out > li > a,
    .cd-dropdown-content ul.move-out > li > .cd-search,
    .cd-dropdown-content ul.move-out > .cd-divider {
      opacity: 1;
    }
  }
  
  .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
    padding-left: 90px;
  }
  .cd-dropdown-content .cd-dropdown-gallery img {
    position: absolute;
    display: block;
    height: 40px;
    width: auto;
    left: 20px;
    top: 50%;
    margin-top: -20px;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    padding-left: 75px;
    position: relative;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
    color: #111433;
    font-size: 1.3rem;
    display: none;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
  }
  
  .has-children > a::after {
    content: '+';
    padding-left: 5px;
  }
  
  @media only screen and (min-width: 1200px) {
    .cd-dropdown-content {
      z-index: 11;
      display: inline-block;
      position: relative;
      min-width: 262px;
      max-width: 262px;
    }
  
    .cd-dropdown-content > li {
      color: #1E1E24;
      list-style-type: none;
      padding: 3px 0;
      text-align: left;
    }
  
    .cd-dropdown-content > li.is-active > a {
      color: #fc5959;
    }
  
    .cd-dropdown-content > li > a {
      background: transparent;
      color: #1E1E24;
      font-size: 28px;
      font-weight: 600;
      text-decoration: none;
      transition: all .3s ease;
      width: auto;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      display: inline-block;
    }
  
  
    .cd-dropdown-content > li:last-of-type a {
      border-bottom: none;
    }
    .no-touch .cd-dropdown-content > li:not(.has-children) a:hover {
      color: #fc5959;
    }
    .cd-dropdown-content.move-out > li > a,
    .cd-dropdown-content.move-out > li > .cd-search,
    .cd-dropdown-content.move-out > .cd-divider {
      transform: translateX(0);
      opacity: 1;
    }
  
    .cd-dropdown-content .cd-secondary-dropdown,
    .cd-dropdown-content .cd-dropdown-gallery,
    .cd-dropdown-content .cd-dropdown-icons {
      transform: translateX(0);
      height: auto;
      background-color: transparent;
      left: calc(100% - -130px);
    }
    .cd-dropdown-content .cd-secondary-dropdown::after,
    .cd-dropdown-content .cd-dropdown-gallery::after,
    .cd-dropdown-content .cd-dropdown-icons::after {
      clear: both;
      content: "";
      display: table;
    }
    .open-to-left .cd-dropdown-content .cd-secondary-dropdown,
    .open-to-left .cd-dropdown-content .cd-dropdown-gallery,
    .open-to-left .cd-dropdown-content .cd-dropdown-icons {
      left: auto;
      right: 100%;
    }
    .cd-dropdown-content .cd-secondary-dropdown.is-hidden,
    .cd-dropdown-content .cd-dropdown-gallery.is-hidden,
    .cd-dropdown-content .cd-dropdown-icons.is-hidden {
      transform: translateX(0);
    }
    .cd-dropdown-content .cd-secondary-dropdown.fade-in,
    .cd-dropdown-content .cd-dropdown-gallery.fade-in,
    .cd-dropdown-content .cd-dropdown-icons.fade-in {
      -webkit-animation: cd-fade-in 0.2s;
      -moz-animation: cd-fade-in 0.2s;
      animation: cd-fade-in 0.2s;
    }
    .cd-dropdown-content .cd-secondary-dropdown.fade-out,
    .cd-dropdown-content .cd-dropdown-gallery.fade-out,
    .cd-dropdown-content .cd-dropdown-icons.fade-out {
      -webkit-animation: cd-fade-out 0.2s;
      -moz-animation: cd-fade-out 0.2s;
      animation: cd-fade-out 0.2s;
    }
  
    .no-touch .cd-dropdown-content .cd-secondary-dropdown > .see-all a:hover,
    .no-touch .cd-dropdown-content .cd-dropdown-gallery > .see-all a:hover,
    .no-touch .cd-dropdown-content .cd-dropdown-icons > .see-all a:hover {
      color: #ffffff;
      background-color: #111433;
    }
    .cd-dropdown-content .cd-secondary-dropdown .cd-dropdown-item,
    .cd-dropdown-content .cd-secondary-dropdown a,
    .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item,
    .cd-dropdown-content .cd-dropdown-gallery a,
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item,
    .cd-dropdown-content .cd-dropdown-icons a {
      border: none;
    }
    .cd-dropdown-content .cd-dropdown-gallery,
    .cd-dropdown-content .cd-dropdown-icons {
      padding: 20px 30px 100px;
    }
    .cd-dropdown-content .cd-dropdown-gallery > .see-all,
    .cd-dropdown-content .cd-dropdown-icons > .see-all {
      width: calc(100% - 60px);
    }
  
    .cd-dropdown-content .cd-secondary-dropdown > li.has-children > a {
      position: relative;
    }

    .cd-dropdown-content .cd-secondary-dropdown > li.has-children.is-active > a {
        color: #fc5959;
    }
  
    .cd-dropdown-content .cd-secondary-dropdown > li.has-children > a::after {
      content: '+';
      padding-left: 5px;
    }
  
    .cd-dropdown-content .cd-secondary-dropdown > li {

      & > a {
        font-size: 21px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 11px 0;
        letter-spacing: -0.2px;
        display: inline-block;
        white-space: nowrap;
        position: relative;
      }

      &.current-menu-item {
        a {
          color: $primary_red;
          &:before {
            position: absolute;
            right: 0;
            bottom: 15px;
            width: 100%;
            height: 3px;
            content: '';
            background: $primary_red;
          }
        }
      }

    }

    .cd-dropdown-content {
      .cd-secondary-dropdown.move-out > li > a {
        transform: translateX(0);
      }

      .cd-secondary-dropdown > li > ul {
        transform: translate(0);
        position: relative;
        height: auto;
      }

      .cd-secondary-dropdown > li > ul > .go-back {
        display: none;
      }

      .cd-secondary-dropdown a {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 6px 0;
        display: block;
        letter-spacing: -0.2px;
      }
    }

    .no-touch .cd-dropdown-content .cd-secondary-dropdown a:hover {
      color: #fc5959;
    }
    .cd-dropdown-content .cd-secondary-dropdown ul {
      /* padding-bottom: 25px; */
      overflow: hidden;
      height: auto;
      left: calc(100% - -130px);
    }
    .cd-dropdown-content .cd-secondary-dropdown .go-back a {
      padding-left: 20px;
      color: transparent;
    }
    .no-touch .cd-dropdown-content .cd-secondary-dropdown .go-back a:hover {
      color: transparent;
    }
    .cd-dropdown-content .cd-secondary-dropdown .go-back a::before,
    .cd-dropdown-content .cd-secondary-dropdown .go-back a::after {
      left: 0;
    }
    .cd-dropdown-content .cd-secondary-dropdown .see-all {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .cd-dropdown-content .cd-dropdown-gallery {
      width: 600px;
      padding-bottom: 100px;
    }
    .cd-dropdown-content .cd-dropdown-gallery > li {
      width: 48%;
      float: left;
      margin-right: 4%;
    }
    .cd-dropdown-content .cd-dropdown-gallery > li:nth-of-type(2n) {
      margin-right: 0;
    }
    .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
      padding: 0;
      height: auto;
      line-height: normal;
      color: #fc5959;
      margin-bottom: 2em;
    }
    .cd-dropdown-content .cd-dropdown-gallery > li:nth-last-of-type(2) a,
    .cd-dropdown-content .cd-dropdown-gallery > li:last-of-type a {
      margin-bottom: 0;
    }
    .cd-dropdown-content .cd-dropdown-gallery img {
      position: static;
      height: auto;
      width: 100%;
      margin: 0 0 0.6em;
    }
    .cd-dropdown-content .cd-dropdown-icons {
      width: 600px;
    }
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
      height: 80px;
      line-height: 1.2;
      padding: 24px 0 0 85px;
    }
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item:hover {
      background: #ebebeb;
    }
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
      color: #fc5959;
      font-weight: bold;
    }
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
      display: block;
      font-size: 1.2rem;
    }
    .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
      left: 25px;
    }
    .cd-dropdown-content > .has-children > ul {
      visibility: hidden;
      transition: transform 0.3s;
      transform: translateY(100%);
      opacity: 0;
    }
    .cd-dropdown-content > .has-children > ul.is-hidden {
      transform: translateY(100%);
      opacity: 0;
    }
    .cd-dropdown-content > .has-children > ul.is-active {
      visibility: visible;
      transform: translateY(0%);
      opacity: 1;
  
    }
    .cd-dropdown-content > .has-children > .cd-secondary-dropdown.is-hidden > li > ul {
      transform: translateY(100%);
      transition: transform 0.3s;
      opacity: 0;
    }
    .cd-dropdown-content > .has-children > .cd-secondary-dropdown.is-active > li > ul {
      position: absolute;
      visibility: visible;
      transform: translateY(0%);
      transition: transform 0.3s;
      opacity: 1;
        top: -2px;
    }
    .cd-dropdown-content > .has-children > .cd-secondary-dropdown.is-active > li > ul.is-hidden {
      transform: translateY(100%);
      transition: transform 0.3s;
      opacity: 0;
    }
    .cd-dropdown-content > .has-children > a.is-active {
      box-shadow: inset 2px 0 0 #fc5959;
      color: #fc5959;
    }
    .cd-dropdown-content > .has-children > a.is-active::before,
    .cd-dropdown-content > .has-children > a.is-active::after {
      background: #fc5959;
    }
    .open-to-left .cd-dropdown-content > .has-children > a.is-active {
      box-shadow: inset -2px 0 0 #fc5959;
    }
  }


  @media (min-width: 1367px) {
    .cd-dropdown-content > li > a { font-size: 32px; }
  }
  
  @-webkit-keyframes cd-fade-in {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @-moz-keyframes cd-fade-in {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes cd-fade-in {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @-webkit-keyframes cd-fade-out {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: visible;
    }
  }
  @-moz-keyframes cd-fade-out {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: visible;
    }
  }
  @keyframes cd-fade-out {
    0% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: visible;
    }
  }
  
  .has-children > a, .go-back a {
    position: relative;
  }
  
  .has-children > a {
    padding-right: 40px;
  }
  .has-children > a::before, .has-children > a::after {
    right: 20px;
    transform-origin: 9px 50%;
  }
  
  @media only screen and (min-width: 1200px) {
    .open-to-left .cd-dropdown-content > .has-children > a {
      padding-left: 40px;
      padding-right: 20px;
    }
    .open-to-left .cd-dropdown-content > .has-children > a::before,
    .open-to-left .cd-dropdown-content > .has-children > a::after {
      right: auto;
      left: 20px;
      transform-origin: 1px 50%;
    }
  }
  .cd-dropdown-content .go-back a {
    padding-left: 20px !important;
  }
  .cd-dropdown-content .go-back a::before, .cd-dropdown-content .go-back a::after {
    left: 0;
    transform-origin: 1px 50%;
  }
  
  .cd-main-content {
    background-color: #e6e6e6;
    min-height: calc(100vh - 100px);
    padding: 2em 5%;
    line-height: 2;
  }
  
  .no-js .cd-dropdown-wrapper:hover .cd-dropdown {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  
  .no-js .cd-dropdown-wrapper .cd-close {
    display: none;
  }
  

  @media (max-width: 1361px) {
    .cd-dropdown-content { min-width: 262px; max-width: 262px;}
    .cd-dropdown-content>li { padding: 2px 0; }
    .cd-dropdown-content>li>a { font-size: 24px; }
    .cd-dropdown-content .cd-secondary-dropdown>li>a { padding: 5px 0; }
  }

  @media (max-width: 992px) {
    .cd-dropdown-content>li { padding: 12px 0; }
  }

  @media (max-width: 1200px) {
    .cd-dropdown-content { min-width: 0; max-width: 100%;}
  }

  @media (max-width: 767px) {
    .cd-dropdown-content { min-width: 0; max-width: 100%;}
    .cd-dropdown-content>li>a { font-size: 18px; }
  }

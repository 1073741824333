$transition--length: .4;

main>div.single-careers .pageHeader {
    overflow: hidden;
}
.fullscreen-modal.vacancy {
    .wpcf7-form .add-file {
        margin: 10px 0 20px 0;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        @media (max-width: 992px) {
            grid-template-columns: 1fr;
        }

        & > p {
            display: none;
        }

        input {
            border: none;
            width: auto;
            height: auto;
    
            &[type=text] {
              padding: 0;
              font-size: 18px;
              padding: 0 15px;
            }
        }

        input[name="cv"] {
            margin-bottom: 10px;
        }
        input[type='file'] {
            width: 200px;
            height: 25px;
            opacity: 1;
        }

        .cv-file-wrap {
            position: relative;
        }
    }

    .content {
        .contact-form {
            .input_block_wrap {
                grid-gap: 30px;
            }
        }
    }
}
.single-careers {
    font-size: 14px;
    color: #4c4c4c;

    .career-contacts-block {
        max-height: 0;
        transition: max-height 0.25s ease-in;

        ul {
            display: flex;
            justify-content: space-between;
            padding: 40px 0 0;
            gap: 20px;

            @media (max-width: 992px) {
                display: grid;
                grid-gap: 24px;
            }

            li {
                position: relative;
                display: block;
                transition-delay: .3s;
                opacity: 0;
                transform: translate(100%, 0%);
                transition: opacity .2s ease, transform .3s ease;
                padding: 0;

                a {
                    display: grid;
                    grid-template-columns: 24px 1fr;
                    grid-gap: 14px;
                    align-items: center;
                    font-weight: bold;
                    transition: 0.3s all;
                    opacity: 0.5;

                    &:hover {
                        opacity: 1;
                    }

                    svg {
                        position: relative;
                        top: -2px;
                    }
                }

                &:before {
                    display: none;
                }
            }
        }

        &.active {
            max-height: 100%;
            transition: max-height 1.15s ease-out;

            $menu--items--count: 4;

            ul {
                visibility:visible;

                li {
                    opacity: 1;
                    transform: translateY(0%);
                    transition: opacity .3s ease, transform .3s ease, color .3s ease;
                    @for $i from 0 through $menu--items--count {
                        &:nth-child(#{$i}){
                            transition-delay: $transition--length * $i / 8 + .5 + s;
                        }
                    }
                }
            }
        }
    }

    h4 {
        font-size: 22px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $primary;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    ul {
        li {
            position: relative;
            padding: 3px 0 3px 14px;

            &:before {
                position: absolute;
                content: '';
                width: 4px;
                left: 0;
                height: 4px;
                background: $primary-red;
                top: 10px;
            }
        }
    }


    .title {
        display: grid;
        grid-template-columns: 26px 1fr;
        grid-gap: 22px;
        margin-bottom: 20px;
        align-items: center;

        @media (max-width: 992px) {
            grid-template-columns: 14px 1fr;
            grid-gap: 12px;
        }

        img {
            position: relative;
            top: -2px;
        }

        h1 {
            font-size: 48px;
            color: $primary;

            @media (max-width: 992px) {
                font-size: 36px;
            }

            @media (max-width: 768px) {
                font-size: 22px;
            }
        }

    }
    .vacancy {
        width: 100%;
    }
    .content {
        background: #fff;
        box-shadow: 0px 4px 179px 0px rgba(32, 31, 32, 0.05);
        position: relative;

        .patt-first {
            position: absolute;
            z-index: -1;
            top: -100px;
            right: -100px;

            &.animate {
                animation: spin 15s linear infinite;
            }

            img {
                width: 350px;
                height: 350px;
            }

            @media (max-width: 992px) {
                top: -52px;
                right: -25px;
                img {
                    width: 120px;
                    height: 120px;
                }
                &.blockchain {
                    img {
                        transform: rotate(25deg);
                    }
                }
            }
        }

        .patt-second {
            position: absolute;
            z-index: -1;
            top: 35%;
            left: -80px;

            &.animate {
                animation: spin-two 15s linear infinite;
            }

            img {
                width: 200px;
                height: 200px;
            }

            @media (max-width: 992px) {
                top: 45%;
                left: -30px;

                img {
                    width: 100px;
                    height: 100px;
                }
            }

            &.blockchain {
                img {
                    transform: rotate(25deg);

                    @media (max-width: 992px) {
                        transform: none;
                    }
                }
            }
        }

        .description-info {
            padding: 40px;
            display: grid;
            grid-template-columns: 1fr 265px;
            grid-gap: 60px;

            @media (max-width: 1200px) {
                grid-template-columns: 1fr 200px;
            }

            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                padding: 30px;
                grid-gap: 40px;

                .info {
                    grid-row: 1;

                    &_title {
                        .date {
                            display: block;
                        }
                    }
                }
            }

            .description {
                display: grid;
                grid-template-rows: auto 1fr auto;
                .foot {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-gap: 30px;
                    align-items: center;

                    @media (max-width: 992px) {
                        grid-template-columns: 1fr;
                    }

                    .date {
                        color: #cfcfcf;
                        letter-spacing: 2.2px;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        transition: 0.2s all;

                        @media (max-width: 992px) {
                            display: none;
                        }
                    }
                }
                p {
                    margin-bottom: 20px;

                    // &:last-child {
                    //     margin: 0;
                    // }
                }
            }


            .subcat_link {
                
                display: block;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 2.2px;
                line-height: normal;
                font-weight: 600;
                color: #fff;
                padding: 5px 5px 3px 7px;
                margin-right: 5px;
                font-family: "Mont";
                float: left;
                // margin-bottom: 5px;
                transition: 0.2s all;

                &.category {
                  background: $primary-red;
                  border: 1px solid $primary-red;

                  &:hover {
                    background: transparent;
                    color: $primary-red;
                  }
                }

                &.techlevel {
                  background: $primary;
                  border: 1px solid $primary;

                  &:hover {
                    background: transparent;
                    color: $primary;
                  }
                }

                &.tag {
                    border: 1px solid $primary;
                    color: $primary;
                    
                    &:hover {
                        background: $primary;
                        color: #fff;
                    }
                }
              }

            .info {
                position: relative;

                &_title {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-gap: 20px;
                    .date {
                        display: none;
                    }
                }

                .cont {
                    display: grid;
                    grid-gap: 12px;
                }

                &:before {
                    position: absolute;
                    left: -30px;
                    top: 0;
                    content: '';
                    height: 100%;
                    background: #f2f2f2;
                    width: 1px;

                    @media (max-width: 992px) {
                        left: 0;
                        top: auto;
                        bottom: -20px;
                        width: 100%;
                        height: 1px;
                    }
                }

                .location, .work-time {
                    display: grid;
                    font-weight: bold;
                    grid-template-columns: 14px 1fr;
                    grid-gap: 7px;
                    align-items: center;
                    font-size: 14px;
                    color: $primary;

                    img {
                        position: relative;
                        top: -2px;
                        max-width: 14px;
                    }
                }
            }
        }

        .skills-block {
            background: #fbfbfc;
            padding: 40px;
            border-top: 1px solid #f2f2f2;
            border-bottom: 1px solid #f2f2f2;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 60px;

            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                padding: 30px;
                grid-gap: 40px;
            }

            .will_plus {
                position: relative;

                &:before {
                    position: absolute;
                    left: -30px;
                    top: 0;
                    content: '';
                    height: 100%;
                    background: #f2f2f2;
                    width: 1px;

                    @media (max-width: 992px) {
                        left: 0;
                        top: -20px;
                        // bottom: -30px;
                        width: 100%;
                        height: 1px;
                    }
                }
            }
        }

        .offer-hr {
            padding: 40px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 60px;

            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                padding: 30px;
                grid-gap: 40px;
            }

            .hr-question {
                position: relative;

                &:before {
                    position: absolute;
                    left: -30px;
                    top: 0;
                    content: '';
                    height: 100%;
                    background: #f2f2f2;
                    width: 1px;

                    @media (max-width: 992px) {
                        left: 0;
                        top: -20px;
                        // bottom: -30px;
                        width: 100%;
                        height: 1px;
                    }
                }

                .hr-card {
                    display: grid;
                    grid-template-columns: 120px 1fr;
                    grid-gap: 27px;
                    align-items: center;
                    position: relative;
                    color: #000;

                    @media (max-width: 768px) {
                        grid-template-columns: 75px 1fr;
                        align-items: center;
                    }

                    .photo {
                        img {
                            max-width: 100%;
                        }
                    }

                    .info {
                        position: relative;
                    }

                    h5 {
                        font-size: 18px;
                    }

                    .position {
                        font-size: 14px;
                        font-weight: 900;
                        line-height: 140%;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }
                }
                .hr-contacts {
                    a {
                        color: #000;
                        text-decoration: underline;
                        font-weight: 600;
                    }

                    &.mobile {
                        display: none;
                    }

                    @media (max-width: 768px) {
                        &.mobile {
                            display: block;
                            padding: 15px 0;
                        }

                        &.desktop {
                            display: none;
                        }
                    }
                }
                ul {
                    position: absolute;
                    top: 60px;
                    right: 0;

                    @media (max-width: 768px) {
                        position: relative;
                        top: auto;
                        right: auto;
                        display: flex;

                        li {
                            margin-right: 5px;
                        }
                    }
                    li {
                        padding: 3px 0;
                        &:before {
                            display: none;
                        }

                        a {
                            i {
                                color: #bdbdbd;
                            }

                            &:hover {
                                i {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }

        .join-team {
            background: #fbfbfc;
            padding: 40px;
            border-top: 1px solid #f2f2f2;
            border-bottom: 1px solid #f2f2f2;
            overflow: hidden;

            @media (max-width: 992px) {
                padding: 30px;
            }

            .cont {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 60px;

                @media (max-width: 992px) {
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                }

                .btn {
                    width: 100%;
                }
            }
        }

        .red_block {
            background: $primary-red;
            color: #fff;
            padding: 22px 40px;

            &.gray {
                background: #9f9fa0;
                color: #fff;
            }

            &.dark {
                background: #1e1e23;
                color: #fff;
            }

            @media (max-width: 992px) {
                padding: 30px;
            }

            .description {
                font-size: 22px;
                margin-bottom: 11px;

                @media (max-width: 992px) {
                    font-size: 15px;
                    margin-bottom: 0;
                }
            }

            .main-text {
                font-size: 46px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: normal; 
                margin: 15px 0;

                span {
                    display: block;
                    padding-left: 94px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;

                    span {
                        display: inline-block;
                        padding: 0;
                    }
                }
            }

            .button {
                text-align: right;
                margin-top: -49px;

                @media (max-width: 992px) {
                    margin-top: 0;
                }
                .btn {
                    margin: 0 0 0 auto;
                    @media (max-width: 992px) {
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}


@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@-moz-keyframes spin-two { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin-two { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin-two { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
//-----------------------------------------------------------
// RESET
//-----------------------------------------------------------

@-webkit-viewport{width:device-width}
@-moz-viewport{width:device-width}
@-ms-viewport{width:device-width}
@-o-viewport{width:device-width}
@viewport{width:device-width}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	//font: inherit;
	color: inherit;
	vertical-align: baseline;
}
textarea, input {
	//font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

//smooth those fonts
body, input, button, select, textarea {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	outline: none;
}
button{
	background: transparent;
	outline: none;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	@extend %l_show-block;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

b,strong {
	font-weight: bold;
}

i,em {
	font-style: italic;
}

input,
select,
textarea,
button {
	//-webkit-appearance: none;
	border-radius: 0;
	//font-family: inherit;
}
select::-ms-expand {
	display: none;
}
input::-ms-clear {
	display: none;
}

select { -webkit-appearance: menulist-button; }

textarea {
	resize: none;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
picture { @extend %l_show-block; height: auto; }

button,
input[type="submit"],
textarea {
	border: 0;
	outline: 0;
	cursor: pointer;
}

iframe {
	@extend %l_show-block;
	max-width: 100%;
}

html { height: 100%; }
body{min-height: 100%}

//-----------------------------------------------------------
// HELPERS
//-----------------------------------------------------------

.clear:before,
.clear:after {
    content: " ";
    display: table;
	clear: both;
}

.hidden {
	@extend %l_hide;
}

.screen-reader {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.svg-sprite {
	&,
	svg {
		height: 0;
		width: 0;
		position: absolute;
		overflow: hidden;
	}
}

.long-links {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
body{
	-webkit-tap-highlight-color: transparent;
}
.mb-0 { margin-bottom: 0 !important; }
.has-primary-red-background-color { background: $primary-red; }
.has-light-gray-background-color { background: $primary-black; }
.has-dark-gray-background-color { background: $primary; }
.has-white-color { color: #fff; }


body {
  &.admin-bar {
    .blog_ajax .blog_sidebar:not(.cases)>div, .single-post-item .content .info { 
      top: 112px; 

      @media (max-width: 1200px) {
        top: 0;
      }
    }
  }

  &.open-nav {
    

    header#header {
      overflow: visible;
      .mobile-logo .blog-title { display: none; }
    }
  }
}

.logo-header .mobile-logo {
  position: relative;
  .blog-title {
    font-size: 36px;
    position: absolute;
    left: 34px;
    text-transform: uppercase;
    font-weight: 800;
    top: -12px;
    transition: transform 1s ease 0s, color 0.2s;
    transform: translateY(100%);
  }


  &.active {
    .blog-title {
      transform: translateY(0%);
    }
  }
}


.sf-field-tag, .sf-field-taxonomy-tagsvacancy  {
  .scroll-wrapper {
    max-height: 140px;
  }
}

.has-background {
  padding: 1.25em 2.375em;
}

.has-text-align-center {
  text-align: center !important;
}

.has-text-align-right {
  text-align: right !important;
}

.has-light-gray-color {
  color: #9f9fa0 !important;
}

.has-primary-red-color {
  color: $primary-red !important;
}

.has-dark-gray-color {
  color: $primary !important;
}

// New design for blog
body.blog:not(.single-post), body.archive:not(.single-post), body.post-type-archive-vacancies:not(.single-post) {
    .blog {
        background: #f7f7f9;
    }

    section .section__title.blog {
      @media (max-width: 480px) {
        font-size: 34px;
      }
    }

    .filters-button {
      display: none;

      @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        border: 2px solid #1e1e23;
        text-align: left;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Mont";
        margin: 0;
        margin-bottom: 20px;
        padding: 12px;
        position: relative;

        .counter-filters {
          background: #FC5959;
          padding: 0px 6px;
          font-size: 12px;
          color: #fff;
          height: 17px;
          line-height: 18px;
          display: inline-block;
        }

        img {
          max-width: 14px;
        }

        span {
          &.icon {
            position: absolute;
            width: 14px;
            height: 14px;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    section .section__title-block {
      padding-bottom: 14px;
    }

    &.blog, &.archive:not(.post-type-archive-clients) {
      .guides__block {
        @media (min-width: 1200px) {
          max-width: 1041px;
        }
        .guides__block {
          padding: 0;
        }
      }
    }
}

body.post-template-default.single.single-post {
    background: #fff;

    #header {
      &.sticky {
        &.no-scroll {
          border-bottom: transparent;
        }
      }
    }

}


.wp-pagenavi {
  .current {
    background: $primary-red;
    color: #fff;
    margin-left: 10px;
    border: 1px solid $primary-red;
    padding: 5px 7px 3px 7px;
    font-weight: 600;
    font-size: 14px;
  }
}

.blog_ajax {
  .mCustomScrollbar {
    .mCSB_draggerContainer {
      opacity: 0.3;
      transition: 0.3s all;
    }

    &:hover {
      .mCSB_draggerContainer {
        opacity: 1;
      }
    }
  }
  .mCSB_scrollTools {
    .mCSB_dragger {
      .mCSB_dragger_bar {
        background-color: $primary-red;
        border-radius: 0;
        width: 2px;
      }
    }
    .mCSB_draggerRail {
      background-color: #b5b5b566;
    }
  }
  .searchandfilter ul li {
    &.sf-field-author {
      & > h4 {
        font-size: 22px;
        font-weight: 900;
        text-transform: uppercase;
        margin-top: 15px;
        padding-left: 0;
        position: relative;
        z-index: 111;
      }
    }
  }
  .searchandfilter ul li {
    &.sf-field-tag, &.sf-field-taxonomy-tagsvacancy {
      & > h4 {
        font-size: 22px;
        font-weight: 900;
        text-transform: uppercase;
        padding-left: 0;
        position: relative;
        z-index: 111;
      }

          ul {
            li {
              display: inline-block;
              margin-right: 5px;
              margin-bottom: 8px;
              padding: 0 !important;
              label {
                pointer-events: all !important;
                font-size: 10px !important;
                line-height: 10px;
                font-weight: 600;
                color: $primary;
                border: 1px solid $primary;
                padding: 5px 7px 3px 7px;
                text-transform: none;
                margin: 0;
                transition: 0.2s all;

                &:before {
                  display: none;
                }
                
                @media (min-width: 1200px) {
                  &:hover {
                    color: #fff;
                    background: $primary;
                  }
                }
              }

              &.sf-option-active {
                label {
                  color: #fff;
                  background: $primary;
                }
              }
            }
        }

      
    }
  }
}


main {
.scriptlesssocialsharing {
  margin: 0 !important;

  .scriptlesssocialsharing__buttons {
      width: auto;

      & > .button {
          width: 30px;
          height: 30px;
          background-size: 14px;
          border: 1px solid #dcdcde;
          margin: 0 6px;
          color: #000;


          @media (max-width: 768px) {
            width: 24px;
            height: 24px;
          }

          &:first-child {
              margin-left: 0px;
          }

          &:last-child {
              margin-right: 0px;
          }

          &:hover {
              border-color:  $primary-red;
              background:  $primary-red;
              color: #fff;
          }
      }
  }
}
}




.single-post-item {
  .breadcrumbs {
    margin-bottom: 20px;
  }

  .wp-embedded-content {
    width: 100%;
  }

  .wp-embed-site-title a {
    padding-left: 20px;

    .wp-embed-site-icon {
      height: 16px;
      width: 16px;
    }
  }

  .wp-embed a {
    color: #000;
  }

  .wp-embed {
    .wp-embed-more {
      font-weight: bold;
      color: #000;
    }
  }

  .wp-block-table table {
    font-size: 14px;
  }
    .post-content {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 18px;
        }
        h5 {
            font-size: 16px;
        }
        h6 {
            font-size: 14px;
        }

        a {
          text-decoration: underline;
          font-weight: bold;
        }
    }

    .content {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 265px;
        grid-gap: 36px;

        @media (min-width: 1200px) {
          &.case {
            grid-template-columns: minmax(0, 1fr) 275px;
          }
        }

        @media (max-width: 1200px) {
          grid-template-columns: minmax(0, 1fr);
        }

        .post-content {
            > .head {
                background: #f7f7f9;
                height: 220px;
                color: #1e1e23;
                display: grid;
                padding: 23px 26px;
                margin-bottom: 35px;

                &.case-head {
                  .title-case {
                    display: grid;
                    grid-template-columns: 1fr 160px;
                    grid-gap: 30px;

                    @media (max-width: 992px) {
                      grid-template-columns: 1fr;
                      grid-gap: 10px;

                      .client-logo {
                        grid-row: 1;

                        img {
                          margin: 0 auto 0 0;
                        }
                      }
                    }

                    img {
                      max-width: 160px;
                      margin: 0 0 0 auto;
                      max-height: 60px;
                    }
                  }
                }

                .title {
                    font-size: 22px;
                    font-weight: 900;
                    text-transform: uppercase;
                }

                .foot {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    align-items: center;
                    align-self: end;

                    .date {
                        font-weight: 600;

                        &.time-to-market {
                          span {
                            text-transform: uppercase;
                          }
                        }

                        @media (max-width: 992px) {
                          font-size: 14px;
                        }
                    }
                }

                &.black, &.dark {
                  background: #1e1e23;
                  color: #fff;

                  .foot .scriptlesssocialsharing {
                    &__heading {
                      color: #fff;
                    }
                    &__buttons>.button {
                      color: #fff;
                      border: 1px solid #ffffff30;
  
                      &:hover {
                        border-color: $primary_red;
                      }
                    }
                  }
                }
                &.grayimg {
                  background:#f7f7f9 url('../img/grayimg.png') center no-repeat;
                  color: #1e1e23;

                  @media (max-width: 540px) {
                    background: #f7f7f9 url('../img/grayimg_mobile.png') center no-repeat;
                    background-size: cover;
                  }

                  .foot .scriptlesssocialsharing {
                    &__buttons>.button {
                      color:#1e1e23;
                      border: 1px solid #00000030;
  
                      &:hover {
                        border-color: $primary_red;
                        background: $primary_red;
                        color: #fff;
                      }
                    }
                  }
                }

                &.blackimg {
                  background: #1e1e23 url('../img/blackimg_big.png') center no-repeat;
                  color: #fff;

                  @media (max-width: 1367px) {
                    background:#1e1e23 url('../img/blackimg.png') center no-repeat;
                  }

                  @media (max-width: 540px) {
                    background:#1e1e23 url('../img/blackimg_mobile.png') center no-repeat;
                    background-size: cover;
                  }

                  .foot .scriptlesssocialsharing {
                    &__heading {
                      color: #fff;
                    }
                    &__buttons>.button {
                      color: #fff;
                      border: 1px solid #ffffff30;
  
                      &:hover {
                        border-color: $primary_red;
                      }
                    }
                  }
                }

                &.grayimgtwo {
                  background: #f7f7f9 url('../img/grayimgtwo.png') center no-repeat;
                  color: #1e1e23;

                  @media (max-width: 1367px) {
                    background: #f7f7f9 url('../img/grayimgtwo.png') center no-repeat;
                  }

                  @media (max-width: 540px) {
                    background:#f7f7f9 url('../img/grayimgtwo_mobile.png') center no-repeat;
                    background-size: cover;
                  }

                  .foot .scriptlesssocialsharing .scriptlesssocialsharing__buttons>.button {
                    color: #1e1e23;
                    border: 1px solid #00000030;

                    &:hover {
                      border-color: $primary_red;
                      background: $primary_red;
                      color: #fff;
                    }
                  }
                }

                &.redimg {
                  background: $primary_red url('../img/redimg_big.png') center no-repeat;
                  color: #1e1e23;

                  @media (max-width: 1367px) {
                    background: $primary_red url('../img/redimg.png') center no-repeat;
                  }

                  @media (max-width: 540px) {
                    background:$primary_red url('../img/redimg_mobile.png') center no-repeat;
                    background-size: cover;
                  }

                  .foot .scriptlesssocialsharing .scriptlesssocialsharing__buttons>.button {
                    color: #1e1e23;
                    border: 1px solid #00000030;

                    &:hover {
                      border-color: $primary_red;
                      background: $primary_red;
                      color: #fff;
                    }
                  }
                }

                &.red {
                  background: $primary_red;
                  color: #fff;

                  .foot .scriptlesssocialsharing {
                    h3 {
                      color: #fff;
                    }
                    .scriptlesssocialsharing__buttons>.button {
                      color: #fff;
                      border: 1px solid #ffffff30;
  
                      &:hover {
                        border-color: #fff;
                        background: #fff;
                        color: $primary_red;
                      }
                    }
                  }
                }

                &.gray {
                  background: #9f9fa0;
                  color: #fff;

                  .foot .scriptlesssocialsharing {
                    h3 {
                      color: #fff;
                    }
                    &__buttons>.button {
                      color: #fff;
                      border: 1px solid #ffffff30;
  
                      &:hover {
                        border-color: #fff;
                        background: #fff;
                        color: #9f9fa0;
                      }
                    }
                  }
                }

                &.custom {

                  .foot .scriptlesssocialsharing {
                    h3 {
                      color: inherit;
                    }
                    .scriptlesssocialsharing__buttons>.button {
                      color: inherit;
                      border: 1px solid #ffffff30;
  
                      &:hover {
                        border-color: #fff;
                        background: #fff;
                        color: #000;
                      }
                    }
                  }
                }
            }

            .text {
              .wp-block-image {
                img {
                  max-width: 100%;
                }
              }
              @media (max-width: 1367px) {
                .wp-block-image img {
                  margin: 0 auto;
                }
              }

              @media (max-width: 992px) {
                .wp-block-image img {
                  max-width: 100%;
                  
                }
              }
                & > p, .wp-block-media-text__content {
                    line-height: 1.857;
                    // text-indent: 30px;
                    text-align: justify;
                }
                & > p, & > ul, & > ol, & > div, & > blockquote, & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > figure {
                    margin-bottom: 21px;
                }

                & > blockquote {
                  line-height: 1.857;
                }

                h1, h2, h3, h4, h5, h6 {
                    margin-bottom: 21px;
                    font-weight: 900;
                    text-transform: uppercase;
                }

                & > a {
                  color: $primary-red;
                  text-decoration: underline;
                }

                & > ul {
                  & > li {
                    position: relative;
                    padding-left: 30px;
                    padding-bottom: 10px;

                    &:before {
                      content: '';
                      position: absolute;
                      top: 11px;
                      left: 0px;
                      width: 20px;
                      height: 2px;
                      background: #FC5959;
                    }

                    &:last-child {
                      padding-bottom: 0;
                    }
                  }
                }

                & > ol {
                  counter-reset: olitemnum;

                  &.navigation {
                    li {
                      a {
                        font-weight: bold;
                      }
                    }
                  }
                  & > li {
                    position: relative;
                    padding-left: 30px;
                    padding-bottom: 10px;
                    
                    &:before {
                      counter-increment: olitemnum;
                      content: counters(olitemnum, "", decimal-leading-zero);
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 25px;
                      height: 2px;
                      font-weight: bold;
                      color: $primary-red;
                    }

                    &:last-child {
                      padding-bottom: 0;
                    }
                  }
                }

              .block-author {
                color: #1e1e23;
                font-size: 12px;
                transition: 0.2s all;
                margin-bottom: 0;
                border-top: 4px solid #f2f2f2;
                padding-top: 36px;

                .content {
                  display: grid;
                  grid-template-columns: 120px 1fr;
                  grid-gap: 20px;
                  //padding: 20px;
                  //border: 1px solid #ededed;

                  @media (max-width: 768px) {
                    grid-template-columns: 60px 1fr;
                  }
                }

                .photo {
                  img {
                    max-width: 100%;
                  }
                }

                .details {
                  h4 {
                    margin-bottom: 0;
                  }

                  .position {
                    color: #292929;
                    padding-top: 7px;
                    font-size: 14px;
                    margin-bottom: 10px;
                    font-weight: bold;
                  }

                  .description {
                    font-style: italic;
                    margin: 0 0 15px 0;
                  }

                  .socials {
                    display: flex;

                    a {
                      width: 22px;
                      height: 22px;
                      line-height: 22px;
                      text-align: center;
                      display: inline-block;
                      color: #1e1e23;
                      border: 1px solid #00000030;
                      margin-right: 9px;

                      &:hover {
                        background: $primary_red;
                        color: #fff;
                        border-color: $primary_red;
                      }

                      &:last-child {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
        }


        .info {
            padding-left: 36px;
            position: sticky;
            top: 80px;

            &:before {
              //border-left: 4px solid #f2f2f2;
              height: 100%;
              width: 4px;
              position: absolute;
              left: 0;
              top: 0;
              content: '';
              background: #f2f2f2;
            }

            @media (max-width: 1200px) {
              border-left: 0;
              padding-left: 0;
              border-top: 4px solid #f2f2f2;
              padding-top: 36px;

              &:before {
                display: none;
              }
            }

            & > h4 {
                font-size: 18px;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 15px;

                &.we_used {
                  margin-top: 30px;
                }

                @media (max-width: 768px) {
                  margin-bottom: 10px;
                }

                &.author-title {
                  margin-top: 45px;
                }
            }

            .related-services {
              margin-top: 45px;

              .related-service {
                border: 3px solid $primary-red;
                padding: 23px 20px;
                opacity: 0;
                margin-bottom: 20px;
                transform: translateX(150%);
                transition: 0.3s all;

                &.active {
                  opacity: 1;
                  transform: translateX(0%);
                }

                &.black-border {
                  border-color: $primary;
                }

                &.red-rotate {
                  border: none;
                  position: relative;
                  padding: 50px 20px;
                  background: transparent;

                  &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: $primary-red;
                    transform: rotate(10deg);
                    left: 0;
                    top: 0;
                  }

                  h4 {
                    color: #fff;
                    position: relative;
                    z-index: 2;
                  }

                  a {
                    color: #fff;
                    position: relative;
                    z-index: 2;

                    span {
                      &:after {
                        background: #fff;
                      }
                    }

                    svg {
                      polygon {
                        stroke: #fff;
                      }
                    }

                    &:hover {
                      color: #fff;

                      span {
                        &:after {
                          background: #fff;
                        }
                      }

                      svg {
                        polygon {
                          stroke: #fff;
                        }
                      }
                    }
                  }
                }

                .service-icon {
                  img {
                    max-width: 140px;
                    margin: 0 auto 30px;
                  }
                }

                h4 {
                  font-size: 21px;
                  text-transform: uppercase;
                  line-height: 1.2;
                  font-weight: 900;
                  color: $primary;
                  margin-bottom: 30px;
                }

                a {
                  letter-spacing: 3.2px;
                  text-transform: uppercase;
                  font-size: 15px;
                  font-weight: bold;
                  position: relative;
                  display: inline-flex;
                  align-items: center;
                  transition: 0.3s all;

                  span {
                    position: relative;

                    &:after {
                      position: absolute;
                      content: '';
                      transition: 0.3s all;
                      bottom: 0;
                      left: 0;
                      width: calc(100% - 3px);
                      height: 2px;
                      background: $primary;
                    }
                  }

                  svg {
                    width: 27px;
                    height: 16px;
                    transition: 0.3s all;
                    margin-left: 2px;

                    polygon {
                      transition: 0.3s all;
                      stroke: $primary;
                    }
                  }

                  &:hover {
                    color: $primary-red;
                    svg {
                      transform: translateX(4px);

                      polygon {
                        stroke: $primary-red;
                      }
                    }

                    span {
                      &:after {
                        background: $primary-red;
                      }
                    }
                  }
                }

                &:last-child {
                  margin: 0;
                }
              }
            }

            .service-blocks {
              margin-top: 45px;

              .service-block {
                background: transparent;
                color: #1e1e23;
                padding: 17px 25px;
                font-size: 14px;
                margin-bottom: 20px;
                box-shadow: inset 0 0 0 1px #e7e7e7;
                position: relative;
                font-weight: 400;

                &:before {
                  position: absolute;
                  content: '';
                  width: 3px;
                  height: 100%;
                  background: #1e1e23;
                  left: 0;
                  top: 0;
                }

                .icon {
                  position: absolute;
                  top: 18px;
                  left: 0;

                  svg {
                    width: 16px;
                    polygon {
                      stroke: none;
                      fill: #1e1e23;
                    }
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
  
                a {
                  text-decoration: underline;
                  font-weight: bold;
                }
  
                &.gray {
                  background: #ebebeb;
                  box-shadow: none;

                  &:before {
                    background: #1e1e23;
                  }
                }

                &.red {
                  background: transparent;
                  box-shadow: inset 0 0 0 1px $primary-red;

                  &:before {
                    background:$primary-red;
                  }

                  a {
                    color: $primary-red;
                  }

                  .icon {
                    svg {
                      polygon {
                        fill: $primary-red;
                      }
                    }
                  }
                }

                &.redBg {
                  background: $primary-red;
                  box-shadow: none;
                  border-left: none;

                  &::before {
                    display: none;
                  }

                  color: #fff;

                  a {
                    color: #fff;
                  }

                  .icon {
                    svg {
                      polygon {
                        fill: #fff;
                      }
                    }
                  }
                }

                &.black {
                  background: #1e1e23;
                  box-shadow: none;

                  &::before {
                    display: none;
                  }

                  color: #fff;

                  a {
                    color: #fff;
                  }

                  .icon {
                    svg {
                      polygon {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }

            .tags {
                position: relative;
                padding-left: 21px;
                font-size: 14px;
                font-weight: 600;
                margin-top: 15px;
  
                &:before {
                  position: absolute;
                  content: '';
                  left: 0;
                  top: 3px;
                  width: 12px;
                  height: 13px;
                  background: url('../img/tag_icon.svg') center no-repeat;
                  background-size: contain;
                }

                &.client-stage {
                  padding-left: 0;
  
                  a {
                    display: inline-flex;
                    font-size: 10px;
                    text-transform: none;
                    letter-spacing: 2.2px;
                    line-height: normal;
                    font-weight: 600;
                    padding: 5px 5px 3px 7px;
                    margin-right: 5px;
                    font-family: "Mont", serif;
                    transition: 0.2s all;
                    border: 1px solid $primary;
                    color: $primary;

                    &:hover {
                      text-decoration: none;
                      background: $primary;
                      color: #fff;
                    }
                  }
  
                  &:before {
                    display: none;
                  }
                }

                & > a {
                  &:last-child {
                    margin-right: 0; 

                    &:after {
                      display: none;
                    }
                  }
                }

                a {
                  position: relative;
                  display: inline-block;
                  margin-right: 5px;

                  &:after {
                    position: relative;
                    content: ', ';
                  }

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }

            .tags_cloud {
                a {
                  display: inline-block;
                  font-size: 10px !important;
                  line-height: normal;
                  font-weight: 600;
                  color: #1e1e23;
                  border: 1px solid #1e1e23;
                  padding: 5px 7px 3px 7px;
                  margin-right: 5px;
                  margin-bottom: 8px;
          
                  &:hover {
                    background: #1e1e23;
                    border-color: #1e1e23;
                    color: #fff;
                  }
                }
            }

            .author {
                color: #1e1e23;
                font-size: 12px;
                transition: 0.2s all;

                .photo-name {
                  display: grid;
                  grid-template-columns: 60px 1fr;
                  grid-gap: 17px;
                  align-items: center;
                  text-transform: uppercase;
                  font-weight: 800;
                  letter-spacing: 2.2px;
                  margin-bottom: 10px;
                }

                img {
                    max-width: 100%;
                }

                h4 {
                    font-weight: 900;
                    max-width: 110px;
                }
            }

            .cats {
              overflow: hidden;
              a {
                  background: #1e1e23;
                  display: block;
                  font-size: 10px;
                  text-transform: uppercase;
                  letter-spacing: 2.2px;
                  line-height: normal;
                  font-weight: 600;
                  color: #fff;
                  padding: 5px 5px 3px 7px;
                  margin-right: 5px;
                  float: left;
                  margin-bottom: 5px;

                  &:hover {
                    background: transparent;
                    color: #1e1e23;
                    box-shadow: inset 0 0 0 1px #1e1e23;
                  }
              }

              a.sub_link {
                      background: $primary_red;
                      display: block;
                      font-size: 10px;
                      text-transform: uppercase;
                      letter-spacing: 2.2px;
                      line-height: normal;
                      font-weight: 600;
                      color: #fff;
                      padding: 5px 5px 3px 7px;
                      margin-right: 5px;
                      font-family: "Mont", serif;
                      float: left;
                      margin-bottom: 5px;

                      &:hover {
                        background: transparent;
                        color: $primary_red;
                        box-shadow: inset 0 0 0 1px $primary_red;
                      }
              }

              &.services-blockchains {
                margin-bottom: 30px;

                ul {
                  li {
                    position: relative;
                    padding: 4px 0 3px 13px;

                    a {
                      padding: 0;
                      font-family: "Mont", serif;
                      font-size: 14px;
                      text-align: left;
                      border: 0;
                      background: none;
                      text-transform: none;
                      letter-spacing: normal;
                      color: $primary;
                      margin: 0;
                      float: none;
                      line-height: 20px;
                      font-weight: normal;

                      &:hover {
                        border: 0;
                        color: $primary;
                        box-shadow: none;
                      }
                    }

                    &:before {
                      position: absolute;
                      left: 0;
                      top: 11px;
                      content: '';
                      width: 4px;
                      height: 4px;
                      background:$primary-red;
                    }
                    &.blockchain {
                      &:before {
                        background: $primary;
                      }
                    }
                  }
                }
              }

              &.technologies_we_used {
                ul {
                  li {
                    position: relative;
                    padding: 4px 0 3px 13px;
                    font-family: "Mont", serif;
                    font-size: 14px;
                    text-align: left;
                    border: 0;
                    background: none;
                    text-transform: none;
                    letter-spacing: normal;
                    color: $primary;
                    margin: 0;
                    line-height: 20px;
                    font-weight: normal;

                    &:before {
                      position: absolute;
                      left: 0;
                      top: 11px;
                      content: '';
                      width: 4px;
                      height: 4px;
                      border-radius: 30px;
                      background:$primary;
                    }
                  }
                }
              }
            }
        }
    }
}


@media screen and (min-width: 48em){
	#blaize_posts_wrap article{
		padding-bottom: 4em;
	}
}

.blog_ajax {
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-gap: 37px;
  position: relative;

  @media (max-width: 1367px) {
    grid-template-columns: 250px 1fr;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-gap: 36px;
  }

  .blog_sidebar {
    border-right: 4px solid #eaeaec;
    padding-right: 30px;
    font-size: 14px;

    &:not(.cases):not(.careers) {
      & > div {
        position: sticky;
        top: 80px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .cat-check-list {
      display: grid;
      grid-gap: 18px;
      margin-bottom: 40px;
    }

    .tags_cloud {
      a {
        display: inline-block;
        font-size: 10px !important;
        line-height: normal;
        font-weight: 600;
        color: #1e1e23;
        border: 1px solid #1e1e23;
        padding: 5px 7px 3px 7px;
        margin-right: 5px;
        margin-bottom: 8px;

        &:hover {
          background: #1e1e23;
          border-color: #1e1e23;
          color: #fff;
        }
      }
    }

    .checkbox-custom {
      position: relative;
      margin: 0;
      display: inline-block;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
  
      & > span {
        text-align: left;
        font-size: 14px;
        color: #1e1e23;
  
        & > label {
          display: grid !important;
          grid-template-columns: 14px 1fr;
          grid-gap: 11px;
          cursor: pointer;
        }
  
        input[type=checkbox] {
          display: inline-block;
          margin: 0;
          border: 0;
          position: relative;
          cursor: pointer;
          top: 2px;
          
            &:after {
              content: '';
              position: relative;
              display: block;
              z-index: 1;
              width: 14px;
              height: 14px;
              background: #e4e4e4;
              border: 1px solid #e4e4e4;
          }
  
          &:before {
            background-size: 9px;
            background: #fc5959 url('../img/check.svg') no-repeat 1px 2px;
            position: absolute;
            left: 2px;
            z-index: 2;
            opacity: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #fc5959;
          }
  
          &:checked:before {
            content: '';
            position: absolute;
            top: 0px;
            opacity: 1;
            left: 0px;
            border: 1px solid #fc5959;
          }
        }
      }
  }
  }

@media (max-width: 1200px) {
  .blog_sidebar {
    border: 2px solid #1e1e23;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #f7f7f9;
    position: absolute;
    pointer-events: none;
    top: -22px;
    padding: 12px;
    display: none;

    & > div {
      position: relative;
      top: 0;
    }

    &.active {
      pointer-events: all;
      position: relative;
      display: block;
    }
  }
}


}

.news-block {
    background: #fff;
    padding: 40px;
    margin-bottom: 40px;
    transition: 0.2s all;

    @media (max-width: 992px) {
      padding: 35px 30px;
    }

    @media (min-width: 1200px) {


    &:hover {
        box-shadow: inset 0 0 0 2px #1e1e23;
        .content {
          .left_side {
            .details {
              .author, .date {
                color: #1e1e23;
              }
            }
          }
        }
    }
  }


  &.career {
    @media (max-width: 992px) {
      padding: 20px 30px;
    }
    .content {
      grid-template-columns: 1fr 160px;
      @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-gap: 10px;
      }
      .left_side {
        .block-title {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 30px;
          align-items: center;

          @media (max-width: 992px) {
            grid-template-columns: 1fr;
            grid-gap: 10px;

            .tags {
              .subcat_link {
                margin-bottom: 0 !important;
              }
            }
          }

          .tags {
            .subcat_link {
              display: block;
              font-size: 10px;
              text-transform: uppercase;
              letter-spacing: 2.2px;
              line-height: normal;
              font-weight: 600;
              color: #1e1e23;
              padding: 5px 5px 3px 7px;
              margin-right: 5px;
              font-family: "Mont";
              float: left;
              margin-bottom: 5px;
              transition: 0.2s all;
              border: 1px solid #1e1e23;
            }
          }

          a {
            display: inline-block;
          }
        }

        .details {
          border-top: 1px solid #f2f2f2;
          padding-top: 20px;
          align-items: center;

          ul {
            display: flex;
          }

          .location {
            display: grid;
            grid-template-columns: 14px 1fr;
            grid-gap: 7px;
            align-items: center;
            font-size: 12px;
            color: #808080;

            img {
              opacity: 0.4;
              max-width: 14px;
            }
          }

          ul {
            li {
              display: inline-block;

              button {
                
                display: block;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 2.2px;
                line-height: normal;
                font-weight: 600;
                color: #fff;
                padding: 5px 5px 3px 7px;
                margin-right: 5px;
                font-family: "Mont";
                float: left;
                // margin-bottom: 5px;
                transition: 0.2s all;

                &.category {
                  background: $primary-red;
                  border: 1px solid $primary-red;

                  &:hover {
                    background: transparent;
                    color: $primary-red;
                  }
                }

                &.techlevel {
                  background: $primary;
                  border: 1px solid $primary;

                  &:hover {
                    background: transparent;
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
      .right_side {
        align-items: center;
        grid-gap: 0;

        @media (max-width: 992px) {
          grid-row: 1;
        }

        img {
          display: block;
          margin:  0 auto;
        }
      }
    }
  }

    .content {
        display: grid;
        grid-template-columns: 1fr 220px;
        grid-gap: 60px;

        @media (max-width: 992px) {
          grid-gap: 20px;
        }

        @media (max-width: 1200px) {
          grid-template-columns: 1fr;
        }

        .left_side {
            display: grid;
            grid-template-rows: auto 1fr auto;
            position: relative;
            grid-gap: 23px;

            & > .date {
              display: none;
              color: #cfcfcf;
              letter-spacing: 2.2px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
            }

            @media (max-width: 1200px) {
              & > .date {
                display: block;
              }
            }


            &:after {
                position: absolute;
                right: -38px;
                background: #f2f2f2;
                width: 4px;
                height: 100%;
                content: '';
                top: 0;

                @media (max-width: 1200px) {
                  right: auto;
                  left: 0;
                  bottom: -38px;
                  top: auto;
                  width: 100%;
                  height: 4px;
                }

                @media (max-width: 992px) {
                  display: none;
                }
            }

            a {
                &.title {
                    color: #1e1e23;

                    h4 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 130%;

                        @media (max-width: 1367px) {
                          font-size: 20px;
                        }
                    }
                }
            }

            & > p {
                font-size: 14px;
                color: #1e1e23;
                line-height: 160%;

                a {
                  position: relative;
                  display: inline-block;
                }
            }

            .details {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 23px;

                @media (max-width: 992px) {
                  grid-template-columns: 1fr;
                }

                .author {
                    color: #cfcfcf;
                    letter-spacing: 2.2px;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 800;
                    transition: 0.2s all;
                }

                .date {
                    color: #cfcfcf;
                    letter-spacing: 2.2px;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    transition: 0.2s all;

                    @media (max-width: 1200px) {
                      display: none;
                    }
                }
            }
        }

        .right_side {
            display: grid;
            grid-template-rows: 1fr auto;
            grid-gap: 20px;

            @media (max-width: 1200px) {
              grid-gap: 0;
            }

            .cats {
              overflow: hidden;
              &.images {
                a {
                  background: transparent;
                  border: 0;
                  width: 100%;

                  &:hover {
                    background: transparent;
                    border: 0;
                    box-shadow: none;
                  }
                }
              }
              a {
                  background: #1e1e23;
                  display: block;
                  font-size: 10px;
                  text-transform: uppercase;
                  letter-spacing: 2.2px;
                  line-height: normal;
                  font-weight: 600;
                  color: #fff;
                  padding: 5px 5px 3px 7px;
                  margin-right: 5px;
                  float: left;
                  margin-bottom: 5px;
                  transition: 0.2s all;
                  white-space: nowrap;

                  &:hover {
                    background: transparent;
                    color: #1e1e23;
                    box-shadow: inset 0 0 0 1px #1e1e23;
                  }

                  @media (max-width: 768px) {
                    img {
                      max-width: 90px;
                    }
                  }
              }

              button.subcat_link {
                      background: $primary_red;
                      display: block;
                      font-size: 10px;
                      text-transform: uppercase;
                      letter-spacing: 2.2px;
                      line-height: normal;
                      font-weight: 600;
                      color: #fff;
                      padding: 5px 5px 3px 7px;
                      margin-right: 5px;
                      font-family: "Mont";
                      float: left;
                      margin-bottom: 5px;
                      transition: 0.2s all;

                      &:hover {
                        background: transparent;
                        color: $primary_red;
                        box-shadow: inset 0 0 0 1px $primary_red;
                      }
              }
            }

            .tags {
              position: relative;
              padding-left: 21px;
              font-size: 14px;
              font-weight: 600;

              & > button {
                &:last-child {
                  margin-right: 0;
                  &::after {
                    display: none;
                  }
                }
              }

              button {
                background: none;
                border: 0;
                font-family: inherit;
                font-size: 14px;
                padding: 0;
                font-weight: 600;
                position: relative;
                margin-right: 5px;

                &:after {
                  position: relative;
                  content: ', ';
                }

                &:hover {
                  text-decoration: underline;
                }
              }

              @media (max-width: 1200px) {
                margin-top: 15px;
              }

              &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 3px;
                width: 12px;
                height: 13px;
                background: url('../img/tag_icon.svg') center no-repeat;
                background-size: contain;
              }
            }
        }
    }
}


.img-left {
  .wp-block-media-text__content {
    padding-right: 0 !important;
  }
}

.img-right {
  .wp-block-media-text__content {
    padding-left: 0 !important;
  }
}


@media (max-width: 600px) {
  .img-left {
    .wp-block-media-text__content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  
  .img-right {
    .wp-block-media-text__content {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  .wp-block-media-text.is-stacked-on-mobile {
    grid-gap: 20px;
  }
}


.custom-image {
  margin: 30px 0;
}

.share-author-block {
    width: 100%;
    // max-width: 1041px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 30px;
    align-items: center;
    margin-bottom: 18px;

    .author {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        grid-gap: 20px;
        .name {
            text-align: right;
            h4 {
                font-weight: 900;
                color: #1e1e23;
                font-size: 18px;
                line-height: normal;
            }
            p {
                font-size: 12px;
                color: #9f9f9f;
            }
        }

        .photo {
            img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
            }
        }
    }
}

.scriptlesssocialsharing {
  margin: 18px 0 !important;
}

.allArticlesBlog {
  .guides__block-list li {
    background: $guides-color-light;
  }
  &__block {
    // max-width: 1041px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
.single {

  .img-white {
    img {
      border: 1px solid #e7e7e8;
    }
  }

  &_blog {
    background: $background-gray;
  }
  &__block {
    // max-width: 1041px;
    width: 100%;
    h2, h3, h4 {
      // margin: 60px 0 40px 0;
      margin: 30px 0;
      max-width: 850px;
      font-weight: 900;
      line-height: 160%;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      color: $primary;
      &:first-child {
        margin-top: 0;
      }
    }
    h1 {
      font-size: 32px;
      font-weight: 900;
      letter-spacing: 0.3em;
      text-transform: uppercase;
    }
    h2 {
      font-size: 26px;
    }
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 22px;
    }
    p {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 160%;
      text-indent: 35px;
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      text-decoration: none;
      color: $primary-red;
    }
    figcaption {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.5px;
      color: $primary-black;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    ul, ol {
      margin-bottom: 20px;
      padding-left: 30px;

      &.navigation {
          li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
          }
      }


      li {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 160%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .wp-block-table {
      margin-bottom: 20px;
      padding: 40px;
      border-collapse: initial;
      background: $white;
      td, th {
        border: none;
        font-size: 22px;
        line-height: 160%;
        color: $primary;
      }
      tr:first-child td {
        color: $primary-black;
      }
      tr td:first-child {
        color: $primary-black;
      }
    }
    .wp-block-quote {
      margin: 60px 0;
      padding: 0;
      display: flex;
      border: none;
      &:before {
        content: "";
        display: block;
        background: url('../img/quote.png') no-repeat center center;
        background-size: cover;
        max-width: 74px;
        width: 100%;
        height: 60px;
      }
      p {
        margin-left: 56px;
        padding-top: 30px;
        max-width: 650px;
        line-height: 140%;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: $primary;
      }
    }
  }
}



// .head-nav-blog {
//   display: grid;
//   grid-template-columns: minmax(0, 1fr) auto;
//   grid-gap: 40px;

//   .tabs-top {
//     overflow: hidden;
//   }
// }


// .tabs-top {
//   display: flex;
//   width: 100%;
//   height: 26px;

//   &.archive {
//     margin-bottom: 35px;
//   }

//   &.swiper-menu-container {
//     height: 38px;
//   }
// }
// ul.tabs {
//   margin: 0;
//   padding: 0;
//   display: flex;
//   width: 100%;
//   li {
//     margin: 0;
//     padding-right: 50px;
//     display: flex;
//     align-items: center;
//     position: relative;
//     font-size: 14px;
//     font-weight: 900;
//     line-height: 140%;
//     letter-spacing: 0.3em;
//     text-transform: uppercase;
//     color: $guides-color-dark;
//     cursor: pointer;
//     &.active {
//       color: $primary-red;
//     }
//   }
// }
// .tab_container {
//   width: 100%;
//   display: flex;
//   overflow: auto;
//   overflow-x: hidden;
//   border-top: none;

//   & > .tab_content {
//     &:first-child {
//       display: block;
//     }
//   }
// }
// .tab_content {
//   margin-top: 35px;
//   width: 100%;
//   display: none;

//   @media (max-width: 480px) {
//     margin-top: 0;
//   }
// }
// .tab_drawer_heading { display: none; }

// .articleBlog {
//   &__block {
//     width: calc(100% - -30px);
//     display: flex;
//     flex-wrap: wrap;
//   }

//   &__item {
//     margin: 0 30px 30px 0;
//     color: $white;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end;
//     padding: 30px;
//     font-size: 14px;
//     background-size: cover;
//     height: 400px;
//     width: 327px;
//     &_big {
//       width: 684px;
//     }

//     &:nth-child(1) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo1.png");
//       background-size: contain;
//     }

//     &:nth-child(2) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo2.png");
//       background-size: contain;
//     }

//     &:nth-child(3) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo3.png");
//       background-size: contain;
//     }

//     &:nth-child(4) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo4.png");
//       background-size: contain;
//     }

//     &:nth-child(5) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo5.png");
//       background-size: contain;
//     }
//     &:nth-child(6) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo6.png");
//       background-size: contain;
//     }
//     &:nth-child(7) {
//       background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blog_photo7.png");
//       background-size: contain;
//     }

//     &-tag {
//       padding: 5px;
//       border-radius: 1px;
//       color: #1e1e23;
//       font-size: 14px;
//       letter-spacing: 2px;
//       text-transform: uppercase;
//       font-weight: bold;
//       background-color: #fff;
//       mix-blend-mode: screen;
//     }

//     &-title {
//       margin: 20px 0 30px 0;
//       font-weight: 400;
//       font-size: 30px;
//       line-height: 120%;
//     }

//     &-more {
//       letter-spacing: 0.3em;
//       text-transform: uppercase;
//       font-weight: 900;
//     }
//   }
// }


// .search {
//   position: relative;
//   width: 100%;
//   #search {
//     padding-right: 24px;
//     width: 0;
//     height: 25px;
//     position: absolute;
//     top: 0;
//     right: 0;
//     font-size: 18px;
//     font-weight: 900;
//     border: none;
//     outline: none;
//     color: $primary;
//     background: none;
//     transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
//     z-index: 3;
//     cursor: pointer;
//     &.focus {
//       width: 100%;
//       z-index: 1;
//       border-bottom: 1px solid $primary;
//       cursor: text;
//     }
//   }
//   #search_submit {
//     height: 22px;
//     width: 22px;
//     position: absolute;
//     top: 0;
//     right: 0;
//     background: url("../img/search.svg") center center no-repeat;
//     background-size: contain;
//     text-indent: -10000px;
//     border: none;
//     z-index: 2;
//     cursor: pointer;
//     transition: opacity .4s ease;
//   }
// }
// #search_submit.search_submit_style {
//   z-index: 3;
// }
.scriptlesssocialsharing {
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  h3 {
    margin-right: 0px;
    font-size: 14px !important;
    font-weight: 900;
    line-height: 140%;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: $primary;
    position: relative;
    top: 1px;
    margin-right: 10px;
  }
  @media (max-width: 992px) {
    .scriptlesssocialsharing__heading {
      display: none;
    }
  }
  .scriptlesssocialsharing__buttons {
    width: 265px;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0;
    }


    .button {
      display: block;
      background-size: contain;
      width: 40px;
      height: 40px;
      transition: .3s ease-in-out;
      color: transparent;
      position: relative;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: "fontello";
        transform: translate(-50%, -50%);
      }
      &:last-child {
        margin: 0;
      }
      &.facebook {
        background: transparent;
        &:before {
          content: '\f09a';
        }
      }
      &.twitter {
        background: transparent;
        &:before {
          content: '\f099';
        }
      }
      &.telegram {
        background: transparent;
        &:before {
          content: '\e804';
        }
      }
      &.whatsapp {
        background: transparent;
        &:before {
          content: '\f232';
        }
      }
    }
  }
}

@media(max-width: 1199px) {
  .single__block {
    max-width: 100%;
    p {
      font-size: 24px;
    }
  }
  .articleBlog {
    &__block {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {
      margin: 0 0 30px 0;
      padding: 30px 10px 30px 20px;
      width: 47%;
      &_big {
        width: 100%;
      }
      &-title {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .tabs-top {
    flex-direction: column-reverse;
    ul.tabs {
      margin-top: 16px;
    }
    .search {
      padding: 0 18px 40px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
  .single__block p {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .tab_container {
    &.archive {
      flex-wrap: wrap;
  
      .articleBlog__item {
        margin: 0 37px 30px 0;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tab_container {
    &.archive {
      flex-wrap: wrap;
  
      .articleBlog__item {
        margin: 0 43px 30px 0;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .tab_container {
    &.archive {
      flex-wrap: wrap;
  
      .articleBlog__item:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }
}

@media(max-width: 767px) {

  .tab_container {
    &.archive {
      flex-wrap: wrap;
  
      .articleBlog__item {
        margin: 0 0px 30px 0;
        width: 100%;
      }
    }
  }



    .share-author-block {
        grid-template-columns: 1fr;
        grid-gap: 10px;

        .author {
          padding-left: 12px;
          grid-template-columns: auto 1fr;
            .name {
                grid-row: 1;
                grid-column: 2;
                text-align: left;
            }
            .photo {
                grid-row: 1;
                grid-column: 1;
            }
        }
    }
  .articleBlog {
    &__item {
      height: 300px;
      &-title {
        font-size: 16px;
      }
      &-more {
        font-size: 12px;
        letter-spacing: 0.2em;
      }
    }
  }
  .tabs-top {
    height: auto;
    ul.tabs {
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      @media (max-width: 480px) {
        margin-top: 10px;
      }


      li {
        padding: 0 0 20px 0;
        line-height: 130%;
      }
    }
  }
  .single__block {
    p {
      font-size: 18px;
    }
    a {
      word-wrap: break-word;
    }
    ul, ol {
      li {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    .wp-block-quote {
      &:before {
        background-size: contain;
        max-width: 65px;
      }
      p {
        margin-left: 42px;
      }
    }

  }
}

@media(max-width: 480px) {
  .articleBlog {
    &__block {
      flex-direction: column;
    }
    &__item {
      width: 100%;
    }
  }

  .tabs {
    display: none;
  }
  .d_active {
    color: $white;
  }
  .single__block {
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    p {
      text-indent: 20px;
    }
    .wp-block-quote {
      position: relative;
      p {
        margin: 80px -30px;
        padding: 0;
        position: relative;
        max-width: 290px;
      }
    }
  }
  .scriptlesssocialsharing {
    align-items: flex-start;
    flex-direction: column;
    h3 {
      margin-bottom: 20px;
      color: $primary-black;
    }
  }
}

@media(max-width: 375px) {
  .single__block .wp-block-quote {
    p {
      max-width: 250px;
    }
  }
}

.gist {
  .gist-data {
    max-height: 400px;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    display: block;
  }
}


.single-post-item .content {
  .client-block.white {
    box-shadow: inset 0 0 0 1px #ededed;
  }
}
.clients {
  background: #1e1e23;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .swiper-pagination {
    display: none;
    position: relative;
    bottom: 0;
    margin-top: 15px;

    .swiper-pagination-bullet {
      background: #8e8e8e;
      &-active {
        background: $primary-red;
      }
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        outline: none;
    }

    @media (max-width: 992px) {
      display: block;
    }
}

.clients_home_slider_wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1041px;

  .client-block {
    transition: inherit;

    .details {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
    &.dark {
      background: #000000;
      .left_side {
        .details {
          .case_info {
            .time_to {
              i {
                color: $primary-red;
              }
            }
          }
        }
      }
    }

    &.white {
      .left_side {
        .details {
          .case_info {
            .time_to {
              i {
                color: $primary-red;
              }
            }
          }
        }
      }
    }
  }
}
.clients_home_slider {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 1041px;
    height: 100%;
    z-index: 1;
}

.row {
  max-width: 75rem;
}


  .client-block {
    height: initial;
    margin: 0;
    position: relative;

    .block_mask {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9999;
      pointer-events: none;

      @media (max-width: 1200px) {
        pointer-events: all;
      }
    }

    &.dark {
      .content {
        .left_side {
          .head_block {
            .single-title {
              border-color: #2e2e33;
            }
          }
          .details {
            .technology {
              .description {
                &:before {
                  background: #2e2e33;
                }
              }
            }
          }
        }
      }
    }
    &.red {
      .content {
        .left_side {
          .head_block {
            .single-title {
              border-color: #fc6565;
            }
          }
          .details {
            .technology {
              .description {
                &:before {
                  background:#fc6565;
                }
              }
            }
          }
        }
      }
    }
    .content {
      grid-template-columns: 1fr;
      height: 100%;

      .left_side {
        @media (max-width: 992px) {
          border: 0 !important;
        }
        .head_block {
          display: grid;
          grid-template-columns: 1fr 160px;
          grid-gap: 30px;
          align-items: center;

          img {
            max-width: 100%;
            margin: 0 0 0 auto;
            max-height: 60px;
          }

          @media (max-width: 992px) {
            grid-template-columns: 1fr;
            padding-bottom: 20px;

            .single-title {
              border-top: 1px solid #f2f2f2;
              padding-top: 20px;
            }

            .single-title {
              grid-row: 2;
            }

            img {
              max-width: 160px;
              margin: 0;
            }
          }
        }

        .details {
          grid-template-columns: 150px 1fr;
          padding-top: 30px;

          &:before {
            top: 0;
          }

          h4 {
            display: block;
            margin-bottom: 10px;
          }

          .services {
            ul {
              margin-bottom: 30px;

              &:last-child {
                margin: 0;
              }
            }
          }

          &:after {
            left: 180px;
            height: calc(100% - 30px);
            top: 30px;

            @media (max-width: 992px) {
              display: none;
            }
          }

          .technology {
            display: grid;
            grid-template-rows: 1fr auto;
            grid-gap: 60px;

            .description {
              font-size: 14px;
              line-height: 20px;
              position: relative;

              &:before {
                position: absolute;
                height: 1px;
                width: 100%;
                left: 0;
                bottom: -30px;
                content: '';
                background: #f2f2f2;
              }
            }
          }

          .case_info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            
            .time_to {
              font-size: 14px;

              i {
                padding-right: 5px;
              }
            }
          }


          @media (max-width: 992px) {
            grid-template-columns: 1fr;
            display: grid;

            .technology {
              display: none;
            }
          }

        }

        &:after {
          display: none;
        }
      }
    }
  }
  













  &__more {
    margin: initial;
    margin-top: 35px;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      width: 365px;
    }
  }

  .guides__block {
    display: block;
  }

  .guides__block-list li {
    // background: $guides-color-black;
    background: #505053;
  }
  .section__title-block {
    color: $white;
  }
  &__row {
    position: relative;
    // margin: 0 25px;
    // display: flex;
    // flex-wrap: wrap;
  }
  &__col {
    padding: 40px;
    width: 50%;
    height: 520px;
    &_img {
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        max-width: 380px;
      }
    }
    .description {
      margin: 20px 0;
      font-size: 22px;
      color: $white;
      line-height: 160%;
      height: 215px;
      overflow: hidden;
    }
    .more-btn {
      display: block;
      font-size: 14px;
      font-weight: 900;
      color: $white;
      line-height: 140%;
      letter-spacing: 0.3em;
      text-transform: uppercase;
    }

    &:nth-child(1) {
      background: #000;

      .description, .more-btn {
        color: #fff;
      }
    }
    &:nth-child(2) {
      background: $background-light-gray;
      .description, .more-btn {
        color: $black
      }
    }
    &:nth-child(3) {
      background: $primary-red;
    }
    &:nth-child(4) {
      background: $primary-black;
    }
    &:nth-child(5) {
      background: $background-light-gray;
      .description, .more-btn {
        color: $black
      }
    }
    &:nth-child(6) {
      background: #000;
      .description, .more-btn {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1199px) {
  .clients {

    &__more {
      justify-content: center;
      .btn {
        width: 365px;
      }
    }

    .guides__block {
      max-width: 100%;
      &-list {
        // display: none;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .section__title-block {
      margin: 0 auto;
      width: 728px;
    }
    &__row {
      margin: 0;
    }
    &__col {
      padding: 0;
      width: 100%;
      height: auto;
      &:first-child {
        .clients__block {
          padding-top: 0;
        }
      }
    }
    &__block {
      margin: 0 auto;
      padding: 100px 0;
      max-width: 728px;
      &_img {
        height: auto;
      }
      .description {
        margin-top: 43px;
        max-height: 8em;
        height: auto;
        font-size: 24px;
      }
      .more-btn {
        margin-top: 57px;
      }
    }
  }
}

@media(max-width: 991px) {
  .clients {
    .section__title-block {
      width: 628px;
    }

    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
    &__col {
      &_img {
        height: 160px;
      }
    }
    &__block {
      padding: 60px 0;
      max-width: 628px;
      .description {
        margin-top: 20px;
        font-size: 20px;
      }
      .more-btn {
        margin-top: 35px;
      }
    }
  }
}

@media(max-width: 767px) {
  .clients {
    .section__title-block {
      width: 428px;
    }
    &__block {
      padding: 40px 0;
      max-width: 428px;
      .description {
        font-size: 18px;
      }
    }
    &__more {
      padding: 0;
      justify-content: center;
      .btn {
        width: 277px;
      }
    }
  }
}

@media(max-width: 480px) {
  .clients {
    &__col_img {
      height: 100px;
      img {
        max-width: 120px !important;
      }
    }
    .section__title-block {
      width: 325px;
    }
    &__block {
      max-width: 325px;
    }
  }
}

@media(max-width: 340px) {
  .clients {
    .section__title-block {
      width: 280px;
    }
    &__block {
      max-width: 280px;
    }
  }
}

// https://codepen.io/mblode/pen/qEGWwBЫ

$color-stack:
  (group: primary, id: normal, color: #fff),
  (group: complement, id: normal, color: #000),
  (group: hover, id: normal, color: #FC5959);

@function color($group, $shade:normal, $transparency:1) {
  @each $color in $color-stack {
    $c-group: map-get($color, group);
    $c-shade: map-get($color, id);
    @if($group == map-get($color, group) and $shade == map-get($color, id)){
      @return rgba(map-get($color, color), $transparency);
    }
  }
}

$b-height: 60px;
$b-margin-top: 30px;
$b-margin-left: 30px;
$b-border-width: 2px;
$b-bun-width: 25px;
$b-bun-height: $b-border-width;

$b-trans-speed: .3s;
$b-transition: all $b-trans-speed ease;

$b-font-size: 34px;
$b-font-weight: 600;
$b-txt-pad: 30px;

$b-line-height: ($b-height / 2 - ($b-font-size / 2.8));
$b-txt-pad-top: ($b-margin-top + $b-height + $b-txt-pad);
$b-bun-width-half: ($b-bun-width / 2);
$b-pad-left: ($b-height / 2 - $b-bun-width-half - 2);
$b-pad-top: ($b-height / 2 - $b-bun-width-half);


header {
  &#header {
    a {
      &.home-link {
        svg {
          width: 32px;
          polygon {
            stroke: none;
            fill: #000;
            transition: all .3s;
          }
        }

        &:hover {
          svg {
            polygon {
              fill: $primary-red;
            }
          }
        }
      }
    }
  }
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-250px, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-250px, 0, 0);
    visibility: hidden;
  }
}

* {
  box-sizing: border-box;
}

body {
  &:after {
    background: color(primary);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 0;
    transition: $b-transition;
    width: 100%;
  }

  &.open-nav-menu {
    overflow: hidden;
  }

  &.open-nav-menu:after {
    opacity: 1;
    visibility: visible;
  }

  &.open-nav {
    overflow: hidden;
    header {
      &#header {
        .fs-menu-header {
              z-index: 999999;
              position: fixed;
              left: 50%;
              transform: translateX(-50%);
        }

        #page-nav-head {
          display: none !important;
        }
      }

      .contacts-link {
        opacity: 1;
        transform: translateY(0%) !important;
        transition: none !important;

        &:before {
          left: 0;
        }
      }
    }
  }
}

body {
  #header {
    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #E7E7E8;

        .contacts-link {
          opacity: 1;
        }

        .logo-header {
            padding: 12px 0;
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;

            &.cdnav {
              grid-template-columns: auto 1fr auto;
            }

            .desktop-logo {
                display: none;
            }

            .mobile-logo {
                display: block;
                height: 26px;
            }
        }
    }
}
}

.big-nav {
  z-index: 11;
  display: inline-block;
  position: relative;
  min-width: 360px;
  max-width: 360px;
  left: -2px;

  &:not(.open-nav) {
    visibility: hidden;
  }

  & > li {
    color: color(complement);
    list-style-type: none;
    padding: 9px 0;
    text-align: left;

    &.has-children {
        & > a {
            position: relative;

            &:after {
                content: '+';
                padding-left: 10px;
            }
        }
    }

    & > ul {
        opacity: 0;
        position: absolute;
        z-index: -10;
        left: calc(100% - -130px);
        width: 0;
        top: 0;
        transition: transform 0.3s;
        transform: translateY(100%);
        visibility: hidden;

           & > li {
                a {
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 11px 0;
                    letter-spacing: -0.2px;
                    display: inline-block;

                    &:hover {
                        color: color(hover);
                    }
                }

                &.has-children {
                    & > a {
                        position: relative;
            
                        &:after {
                            content: '+';
                            padding-left: 5px;
                        }
                    }
                }

                & > ul {
                    opacity: 0;
                    position: absolute;
                    z-index: -10;
                    left: calc(100% - -130px);
                    width: 0;
                    top: 0;
                    transition: transform 0.3s;
                    transform: translateY(100%);
            
                       & > li {
                            a {
                                font-size: 16px;
                                text-transform: uppercase;
                                font-weight: 600;
                                padding: 11px 0;
                                display: block;
                                letter-spacing: -0.2px;
            
                                &:hover {
                                    color: color(hover);
                                }
                            }
            
                        }
                }

            }
    }
    &.active {
        a.b-link {
            color: color(hover);
        }
        & > ul {
            width: 100%;
            opacity: 1;
            display: block;
            z-index: 1;
            transform: translateY(0%);
            visibility: visible;
            
            & > li {
                &.active {
                    & > a {
                        color: color(hover);
                    }
                    & > ul {
                        width: 100%;
                        opacity: 1;
                        display: block;
                        z-index: 1;
                        transform: translateY(0%);
                    }
                }
            }
        }
    }
  }
}

.b-link {
  background: transparent;
  border-left: color(complement, normal, 0) solid $b-border-width;
  color: color(complement);
  font-size: $b-font-size;
  font-weight: $b-font-weight;
  text-decoration: none;
  transition: $b-transition;
  width: auto;
    letter-spacing: 1.5px;
    text-transform: uppercase;

  &:hover,
  &--active {
    color: color(hover);
  }
}

.b-menu {
  top: 2px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  transition: $b-transition;
  user-select: none;
  z-index: 122;
}

.b-bun {
  background: color(complement);
  position: relative;
  transition: $b-transition;

  &--top {
    height: 3px;
    top: 0;
    width: 30px;
  }

  &--mid {
    height: 3px;
    top: 8px;
    width: 21px;
  }

  &--bottom {
    height: 3px;
    top: 16px;
    width: 30px;
  }
}

.contacts-link {
  color: #000;
  font-size: $b-font-size;
  font-weight: $b-font-weight;
  margin-right: 30px;
  position: relative;
  text-decoration: none;
  z-index: 13;
  transition: $b-transition;
  opacity: 0;

  @media (max-width: 480px) {
    margin-right: 20px;
  }

  &:before {
    position: absolute;
    bottom: 0;
    height: 2px;
    background: #000;
    content: '';
    transition: $b-transition;
    width: 100%;
    left: -2px;
  }

  &:hover {
    color: color(hover);

    &:before {
      background: color(hover);
    }
  }
}

.btn-menu {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

  &:hover:not(.open-nav) {
    .bun-top,
    .bun-mid,
    .bun-bottom {
      background: color(primary);
    }
  }

    .contacts-link {
        display: none;
        font-family: Mont, serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        text-decoration: none;
    }

  &.open-nav {
    .b-main {
      border: color(complement) solid $b-border-width;
    }

    .b-bun--top {
      background: color(complement);
      top: 12px;
      transform: rotate(45deg);
    }

    .b-bun--mid {
      opacity: 0;
    }

    .b-bun--bottom {
      background: color(complement);
      top: 6px;
      transform: rotate(-45deg);
    }

    .contacts-link {
        display: block;
        color: color(complement);

        &:hover {
            color: color(hover);

            &:before {
                background: color(hover);
            }
        }
    }
  }
}

@media only screen and (min-width: 1200px) {
  header {
    &#header {
      &.sticky {
        .fs-menu {
          padding-top: 80px;
        }
      }
    }
  }
}

.fs-menu {
    position:fixed;
    height: 100%;
    top: -100%;
    left:0;
    width:100%;
    background: #f7f7f9;
    overflow:hidden;
    z-index:99;
    transition: 0.3s all;
    padding-top: 130px;
    padding-bottom: 30px;

    &.open-nav {
        top: 0;
        display: grid;
        align-items: center;
        grid-template-rows: 1fr auto;
        z-index: 99999;
    }

    .content-menu {
        margin: 0 auto;
        max-width: 1041px;
        width: 100%;
        overflow: hidden;
    }

    .guides__block {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        z-index: -1;

        .guides__block-list li {
            background: #E7E7E8;
        }
    }

    .mainBlock_social {
        display: block;
        position: relative;
        bottom: auto;
        left: auto;

        ul {
          li {
            float: left;
            display: block;
            padding-right: 30px;
            margin: 0;
          }
        }
    }

    .foot-menu {
        .content {
            margin: 0 auto;
            max-width: 1041px;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr auto auto auto;
            align-items: center;
            grid-gap: 60px;

            .location {
                text-align: left;
                font-weight: 600;
                font-size: 14px;

                a {
                    display: block;
                    text-transform: uppercase;
                    color: color(hover);
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }
}



@media (min-width: 1367px) {
  body {
    &.big-container {
      .fs-menu {
        .content-menu { max-width: 1300px; }
        .foot-menu {
          .content {
            max-width: 1300px;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
    #header {
        &.fixed-header {
            .sidebar { top: 7px; }
        }
    }
}


@media (max-width: 1200px) {
  .fs-menu {
    .foot-menu {
      display: none;
      .content {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }
}
}



@media (max-width: 767px) {

  body {
    &.open-nav {
      header {
        &#header {
          &.sticky {
            .logo-header {
              .desktop-logo {
                display: none;
              }
  
              .mobile-logo {
                display: block;
              }
            }
          }
        }
  
      }
    }
  }

    #header {
        &.fixed-header {
            .sidebar { top: 9px; }
        }
    }
}


@media (max-width: 1300px) {
  .fs-menu {
    .foot-menu {
      .content {
        max-width: 1041px;
      }
    }
  }
}


@media (min-width: 1281px) {
    header {
        &#header {
            z-index: 15;
            .fs-menu-header {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
            }
        }
    }
    .blockchain_development {
        #blockchain_development { 
            padding-top: 150px; 
        }
    }
}

@media (max-width: 1199px) {
    .fs-menu .content-menu {
        max-width: 728px;
        height: 100%;
    }
}


@media (max-width: 1280px) {
    #header {
      &.sticky {
        .sidebar {
          top: 7px;
        }
      }
    }
}


@media (max-width: 991px) {
  .fs-menu {
    .content-menu {
        max-width: 628px;
    }
  }
}

@media (max-width: 767px) {
    .fs-menu {
        .content-menu {
            max-width: 428px;
        }
    }
}



@media (max-width: 767px) and (max-width: 480px) {
  #header {
    &.sticky {
      .sidebar {
        top: 10px;
      }
    }
  }
}

@media (max-width: 480px) {
    .fs-menu {
        .content-menu {
            max-width: 325px;
        }
    }
}

@media (max-width: 340px) {
    .fs-menu {
        .content-menu {
            max-width: 280px;
        }
    }
}

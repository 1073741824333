.development {
  background: $white;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .guides__block-list li {
    background: $guides-color-light;
  }

  &-btn-wrap {
    padding-right: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1199px) {
      margin-left: 0;
      padding: 0 90px 0 0;
    }

    @media (max-width: 991px) {
      padding: 0 80px 0 0;
    }

    @media (max-width: 768px) {
      padding: 30px 0 0 0;

      .btn {
        margin: 0 auto;
      }
    }
  }

  &__bg {
    background: url('../img/development-img.png') no-repeat top right;
    background-size: contain;
    position: absolute;
    right: -257px;
    bottom: 80px;
    width: 1306px;
    height: 1340px;
    z-index: -1;
  }
  .section__title-block {
    color: $primary;
  }
  .dev-counters {
    counter-reset: itemnum;
  }
  &__research-block {
    counter-reset: itemnum;
    margin-top: 60px;

    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  &__row {
    padding-bottom: 40px;
    display: flex;
    justify-content: space-around;

  &:last-child {
      padding: 0;
    }
  }
  &__col {
    width: 440px;
  }
  .description {
    display: flex;
    span {
      min-width: 80px;
      display: flex;
      justify-content: center;
      font-size: 70px;
      line-height: 130%;
      text-transform: uppercase;
      color: transparent;
      pointer-events: none;
    }
    &-text {
      padding: 45px 0  0 14px;
      a {
        &:hover {
          h3 {
            color: $primary-red;
          }
        }
      }
      h3 {
        margin-bottom: 20px;
        display: inline-block;
        font-size: 14px;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 4.2px;
        text-transform: uppercase;
        color: $primary;
        transition: all .3s;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          counter-increment: itemnum;
          content: counters(itemnum, "", decimal-leading-zero);
          line-height: 130%;
          text-transform: uppercase;
          color: $primary-red;
          min-width: 92px;
          font-weight: normal;
          justify-content: flex-start;
          font-size: 70px;
          transform: translate(-101px, -57%);
          text-align: right;
        }
      }

      ul {
        padding-left: 0px;
        li {
          position: relative;
          font-size: 24px;
          &:before {
            content: '';
            position: absolute;
            top: 15px;
            left: -50px;
            width: 25px;
            height: 2px;
            background: $primary-red;
          }
        }
      }

      ul li, p {
        font-size: 22px;
        line-height: 160%;
        color: $primary;
      }
    }
  }
}

@media (max-width: 1199px) {
  .development {
    //padding-bottom: 0;
    .section__title-block {
      padding: 0 0 80px 0;
    }
    .description {
      span {
        min-width: 92px;
        justify-content: flex-start;
        font-size: 82px;
      }
      &-text {
        padding: 45px 0 0 40px;
        h3 {
          font-size: 18px;

          &:before {
            transform: translate(-140px, -51%);
            font-size: 70px;
          }
        }
        p {
          font-size: 24px;
        }
      }
    }
    &__row {
      padding: 0;
      flex-direction: column;
    }
    &__col {
      padding-bottom: 60px;
      width: 70%;
    }
    &__bg {
      right: -465px;
      bottom: -3px;
      width: 1025px;
      height: 1052px;
    }
    &__empty {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .development {
    &__bg {
      right: -530px;
      bottom: 10px;
      width: 1025px;
      height: 1052px;
    }
    &__col {
      padding-bottom: 80px;
      width: 65%;
    }
    .section__title-block {
      padding: 0 0 60px 0;
    }
    .description {
      span {
        min-width: 80px;
        font-size: 70px;
      }
      &-text {
        padding: 45px 0 0 30px;
        h3 {
          font-size: 14px;
          &:before {
            transform: translate(-86px, -43%);
            font-size: 48px;
            min-width: 52px;
          }
        }
        p, ul li {
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .development {
    &__bg {
      right: -369px;
      width: 700px;
      height: 720px;
    }
    &__col {
      padding-bottom: 40px;
      width: 65%;
    }
    .section__title-block {
      padding: 0 0 40px 0;
    }
    .description {
      span {
        min-width: 52px;
        font-size: 48px;
      }
      &-text {
        padding: 25px 0 0 30px;
        p, ul li {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .development {
    .section {
      &__title-block {
        padding-bottom: 20px;
      }
    }
    &__bg {
      width: 508px;
      height: 520px;
      right: -320px;
      bottom: 100px;
    }
    &__col {
      padding-bottom: 20px;
      width: 100%;
    }
    .description {
      flex-direction: column;
      span {
        padding: 0 20px;
      }
      &-text {
        padding: 25px 0 0 20px;
        h3 {
          &:before {
            transform: translate(-4px, -91px);
            font-size: 48px;
            min-width: 52px;
          }
        }
        ul {
          padding-left: 40px;
          li {
            letter-spacing: 1px;
            &:before {
              top: 12px;
              left: -40px;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 375px) {
  .development__bg {
    bottom: 50px;
  }
}

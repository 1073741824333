body.page-template-page-testing {
	.sidebar_wrap {
		display: none;
	}

	.btn-menu {
		display: grid !important;
		align-items: center;
	}
}

@media (min-width: 1300px) {
	body.big-container:not(.search) {
		.guides__block {
			max-width: 1300px;
		}

		.guides__block-list {
			max-width: 1300px;
		}
	}
}

@media (min-width: 1300px) and (max-width: 1360px) {
	body.big-container:not(.search) {
		.guides__block {
			padding-left: 15px;
			padding-right: 15px;
		}

		header#header .logo-header {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}



#how_works {
	background: #fff;
	position: relative;
	overflow: hidden;
	z-index: 1;

	.guides__block {
		& > h4 {
			font-size: 32px;
			line-height: 48px;
			font-weight: bold;
		}
	}

	h2 {
		padding-bottom: 30px;
	}

	.list {
		display: grid;
		position: relative;
		counter-reset: itemnum;
		grid-gap: 120px;

		.row {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 120px;
			margin: 0;

			&.line-right {
				position: relative;

				&:before {
					position: absolute;
					top: 40px;
					left: auto;
					right: -100%;
					height: 2px;
					width: 100%;
					content: '';
					background: #FF5C5C;
				}

				& > .item {
					&:first-child {
						&:after {
							display: none;
						}
					}
				}
			}

			&.line-left {
				position: relative;

				&:before {
					position: absolute;
					top: 40px;
					right: auto;
					left: -100%;
					height: 2px;
					width: 100%;
					content: '';
					background: #FF5C5C;
				}
			}
		}

		.item {
			position: relative;
			padding-top: 70px;

			& > h4 {
				display: inline-block;
				font-size: 14px;
				font-weight: 900;
				line-height: 140%;
				letter-spacing: 4.2px;
				text-transform: uppercase;
				color: #1E1E23;
				transition: all .3s;
				margin-bottom: 20px;

				&:before {
					position: absolute;
					top: 0;
					counter-increment: itemnum;
					content: counters(itemnum, "", decimal-leading-zero);
					font-size: 22px;
					font-weight: 600;
					line-height: 20px;
				}
			}

			& > img {
				margin-bottom: 40px;
			}

			& > p {
				font-size: 22px;
				line-height: 36px;

				@media (max-width: 767px) {
					font-size: 18px;
					line-height: 160%;
				}
			}

			&.red-line {
				&:before {
					position: absolute;
					top: 40px;
					left: 0;
					height: 2px;
					width: calc(100% - -120px);
					content: '';
					background: #FF5C5C;
				}
			}

			&.arrow {
				&:after {
					content: '';
					border: 11px solid transparent;
					border-left: 10px solid #FF5C5C;
					position: absolute;
					left: 0px;
					top: 40px;
					transform: translateY(-50%);
				}
			}
		}
	}
}



.blockchain_development {
	color: $primary;
	position: relative;
	z-index: 3;


	h1 {
		font-size: 56px;
		line-height: 80px;
		font-weight: 300;
	}

	h2 {
		font-size: 56px;
		line-height: 80px;
		font-weight: 300;
	}
	
	section {
		position: relative;
	}

	#blockchain_development {
		padding: 120px 0 0 0;

		.content {
			display: grid;
			grid-template-columns: 50% 1fr;
			grid-gap: 80px;

			.text {
				padding-top: 30px;
				padding-bottom: 120px; 

				h1 {
					margin-bottom: 40px;
				}

				@media (min-width: 1200px) and (max-width: 1300px) {
					h1 {
						font-size: 42px;
						line-height: 62px;
					}
				}

				& > p {
					font-size: 22px;
					line-height: 36px;
					margin-bottom: 30px;

					&:last-child {
						margin: 0;
					}
				}
			}


			.image {
				align-self: end;
				text-align: right;
				background: url(/wp-content/uploads/2020/02/main_photo.jpg) bottom right no-repeat;
				background-size: cover;
				height: 740px;

				@media (min-width: 1200px) {
					&.for-enterprises {
						height: 620px;
					}
				}
			}
		}
	}



	#quote {
		position: absolute;
		width: 100%;
		max-width: 1041px;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		background: $primary-red;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 40px;
		padding: 50px;

		.text {
			line-height: 36px;
			font-size: 22px;
			color: $white;
			font-weight: bold;
		}
	}



	.what_is_blaize {
		padding: 220px 0 0px 0;
		overflow: hidden;
		color: $white;

		&.for-enterprises {
			padding-top: 120px;

			@media (max-width: 768px) {
				padding-top: 60px;
			}
		}



		h2 {
			padding-bottom: 30px;
		}

		&.manifesto {
			.numbers {
				padding: 40px 0 0 0;

				@media (min-width: 1200px) and (max-width: 1300px) {
					display: none;
				}

				h3 {
					padding-bottom: 30px;
				}

				.counters {
					grid-column-gap: 0;
					.block {
						grid-template-columns: 110px 1fr;

						@media (max-width: 768px) {
							grid-template-columns: 76px 1fr;
						}
					}
				}
			}
		}

		.content {
			display: grid;
			grid-template-columns: 450px 1fr;
			grid-gap: 120px;

			.text {
				padding-bottom: 60px;
				& > p {
					font-size: 22px;
					line-height: 36px;
					margin-bottom: 30px;

					&:last-child {
						margin: 0;
					}
				}
			}

			.image {
				align-self: end;
				background: url(/wp-content/uploads/2020/07/what_photo.jpg) no-repeat;
				background-position: bottom right;
				background-position-x: right;
				background-position-y: bottom;
				background-size: cover;
				height: 100%;
			}
		}
	}

	.beyond_our_clients {
		background: $background-light-gray;
		.guides__block-list {
			z-index: 0;
			li {
				background: $guides-color-light;
			}
		}
		h4 {
			font-size: 32px;
			line-height: 48px;
			position: relative;
			z-index: 1;

			@media (min-width: 1200px) and (max-width: 1300px) {
				font-size: 24px;
			}
		}
		.content {
			display: grid;
			grid-template-columns: 32% 1fr;
			grid-gap: 70px;
			position: relative;
			z-index: 1;

			@media (min-width: 1200px) and (max-width: 1300px) {
				grid-template-columns: 32% 1fr;
			}

			.text {
				position: relative;
				h2 {
					padding: 40px 0 0 0;

					@media (min-width: 1200px) and (max-width: 1300px) {
						font-size: 42px;
    					line-height: 60px;
					}
				}
				p {
					font-size: 22px;
					line-height: 160%;
					margin-top: 38px;
				}
			}

			.list {
				padding-top: 40px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 70px;


				h4 {
					&.list_title {
						grid-column: 1/3;
						bottom: -14px;

						@media (max-width: 767px) {
							grid-column: 1/2;
							bottom: auto;
							font-size: 24px;
							line-height: 36px;
						}
					}
				}

				.item {
					display: grid;
					grid-template-rows: 100px auto 1fr;
					grid-gap: 10px;
					
					.icon {
						display: grid;
						align-items: center;
						margin-bottom: 30px;
						
						img {
							max-width: 80px;
						}
					}

					h4 {
						display: inline-block;
						font-size: 14px;
						font-weight: 900;
						letter-spacing: 4.2px;
						text-transform: uppercase;
						margin-bottom: 10px;
						color: #1E1E23;
						transition: all .3s;
						position: relative;
						line-height: 140%;
					}

					p {
						font-size: 22px;
						line-height: 160%;
					}
				}
			}
		}
	}



.our_approach {
		padding: 120px 0 0px 0;
		color: $white;

		h2 {
			padding-bottom: 30px;
		}

		h3 {
			font-size: 32px;
			line-height: 48px;
			font-weight: bold;
		}

		.content {
			display: grid;
			grid-template-columns: 520px 1fr;
			grid-gap: 120px;

			.image {
				align-self: end;
				background: url(/wp-content/uploads/2020/02/Approach_photo.jpg) center right no-repeat;
				background-size: cover;
				height: 604px;
			}

			.text {
				& > p {
					font-size: 22px;
					line-height: 36px;
					margin-bottom: 30px;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}



	#where_to_start {
		.guides__block {
			& > h4 {
				font-size: 32px;
				line-height: 48px;
			}

			& > p {
				font-size: 22px;
				line-height: 36px;
				padding-bottom: 90px;
				display: block;
				width: 100%;
				max-width: 780px;
			}
		}
		.contact-form {
			.input_block_wrap {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 60px;
			}

			.wpcf7-form {
				.control--checkbox {
					margin-bottom: 50px;

					.checkbox-text {
						font-size: 14px;

						a {
							text-decoration: underline;
						}
					}
				}
			}

			.btn-red {
				margin: 0 auto;
				width: 100%;
				max-width: 520px;
			}
		}
	}


	.services_this {
		position: relative;
		overflow: hidden;
		z-index: 1;

		h2 {
			padding-bottom: 30px;
		}

		.description {
			display: block;
			width: 100%;
			max-width: 780px;
			margin: 0 auto;
			padding-bottom: 120px;

				& > p {
					font-size: 22px;
					line-height: 36px;
					margin-bottom: 30px;

					&:last-child {
						margin: 0;
					}
				}
		}

		ul {
			&.num-list {
				max-width: 980px;
				margin: 0 auto;
				counter-reset: listitem;
				width: 100%;
				display: grid;
				grid-gap: 60px;

				& > li {
						h4 {
							display: grid;
							grid-template-columns: 78px 1fr;
							align-items: baseline;
							grid-gap: 20px;

							font-size: 14px;
							font-weight: 900;
							letter-spacing: 4.2px;
							text-transform: uppercase;
							color: #1E1E23;
							transition: all .3s;
							position: relative;
							line-height: 140%;

							&:before {
								counter-increment: listitem; 
								content: counters(listitem, "", decimal-leading-zero);
								font-size: 72px;
								line-height: 96px;
								font-weight: normal;
								color: $primary-red;
								text-align: right;
								position: relative;
								left: -3px;
							}
						}

						& > p {
							padding-left: 100px;
							font-size: 22px;
							line-height: 36px;
						}
				}
			}
		}
	}




.black_section {
	color: #000;
	padding: 0;
	overflow: hidden;

	h2 {
		padding: 0 50px 80px 50px;
	}

	&.manifesto {
		background: $background-light-gray;

		.guides__block {
			padding: 120px 0 160px 0;
			position: relative;

			.guides__block-list {
				li {
					background: $guides-color-light;
				}
			}

			
		}
	}

	.description {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 40px;
		align-items: center;
		padding-bottom: 120px;
		position: relative;
		z-index: 10;
		color: #000;

		p {
			font-size: 22px;
			line-height: 36px;
			margin-bottom: 30px;
			max-width: 780px;
			padding: 0;

			&:before {
				display: none;
			}

			&:last-child {
				margin: 0;
			}
		}
	}



		ul {
			&.num-list {
				position: relative;
				z-index: 10;
				max-width: 984px;
				margin: 0 auto;
				counter-reset: listitem;
				width: 100%;
				display: grid;
				grid-gap: 80px;
				grid-row-gap: 130px;
				grid-template-columns: 1fr 1fr;

				& > li {
						& > div {
							display: grid;
							align-items: baseline;
							grid-gap: 20px;
							margin-bottom: 20px;
							
							h4 {
								display: inline-block;
								font-size: 14px;
								font-weight: 900;
								line-height: 140%;
								letter-spacing: 4.2px;
								text-transform: uppercase;
								color: #1E1E23;
								transition: all .3s;
								position: relative;
								padding-left: 100px;

								&:before {
									counter-increment: listitem; 
									content: counters(listitem, "", decimal-leading-zero);
									font-size: 70px;
									line-height: 54px;
									font-weight: normal;
									color: $primary-red;
									text-align: right;
									position: absolute;
									min-width: 92px;
									text-align: right;
									left: 0;
									transform: translateY(-63%);
								}

								span {
									display: block;
								}
							}

							a {
								&:hover {
									color: $primary_red;

									h4 {
										color: $primary_red;
									}
								}
							}
						}

						& > ul {
							padding-left: 100px;
							font-size: 22px;
							line-height: 36px;

							li {
								display: block;
								font-size: 22px;
								line-height: 160%;
								color: #1E1E23;
								position: relative;

								&:before {
									content: '';
									position: absolute;
									top: 15px;
									left: -50px;
									width: 25px;
									height: 2px;
									background: #FC5959;
								}
							}
						}
				}
			}
		}

}


	#description {
		padding: 150px 0;

		.guides__block {
			& > p {
				font-size: 22px;
				line-height: 36px;
				max-width: 780px;
				margin: 0 auto 30px auto;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}



	#cases {
		color: $white;

		.guides__block {
			& > h2 {
				padding-bottom: 40px;
				text-transform: uppercase;
			}

			& > p {
				font-size: 22px;
				line-height: 36px;
				padding-bottom: 70px;
				max-width: 780px;
			}
		}

		.cases {
			display: grid;
			grid-gap: 50px;
			position: relative;
			padding-bottom: 80px;

			.case {
				position: relative;

				.image {
					width: 220px;
					height: 120px;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					display: grid;
    				align-items: center;

					img {
						max-width: 160px;
						margin: 0 auto;
					}
				}

				.content {
					background: $white;
					color: $primary;
					display: grid;
					align-items: center;
					min-height: 225px;
					width: calc(100% - 220px);
					padding: 0 230px 0 200px;
					// max-width: 1040px;
					margin: 0 auto;
					font-size: 22px;
					line-height: 36px;
					font-weight: 400;

				}

				.read-btn {
					position: absolute;
					left: auto;
					right: 0;
					top: 50%;
					transform: translateY(-50%);

					a {
						&.btn-red {
							width: 260px;
						}
					}
				}
			}
		}

		.all-cases {
			a {
				&.btn {
					&.btn-white {
						width: 100%;
						max-width: 520px;
						margin: 0 auto;
					}
				}
			}
		}
	}


	#contacts {
		h2 {
			&.section__title {
				font-size: 56px;
				line-height: 80px;
				max-width: 680px;
				padding-bottom: 40px;
			}
		}

		.contacts__col {
			bottom: 0;
			margin-bottom: -95px;
		}
	}


}


@media (min-width: 1201px) and (max-width: 1300px) {
	.blockchain_development {
		.what_is_blaize {
			.content {
				grid-gap: 60px;
				grid-template-columns: 430px 1fr;
			}
		}
	}
}



@media (max-width: 1200px) {

	#how_works {
		padding: 0;

		.guides__block {
			padding: 80px 0px;
		}
	}

	.blockchain_development {

	#blockchain_development {

		.guides__block {
			max-width: 100%;
			
			.guides__block-list {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.content {
			grid-template-columns: 1fr;
			padding-top: 80px;
			grid-gap: 0;


			.text {
				max-width: 728px;
				margin: 0 auto;
				padding-bottom: 90px;

				h1 {
					max-width: 630px;
				}
			}

			.image {
				height: 580px;
				background: url(/wp-content/uploads/2020/03/Photo.jpg) center right no-repeat;
    			background-size: cover;
			}
		}
	}

	#quote {
		width: calc(100% - 140px);
		left: 140px;
		    transform: translate(0%, -50%);
	}


	.what_is_blaize {
		h2 {
			padding-bottom: 50px;
		}

		.guides__block {
			padding: 0 0 80px 0;
		}

		&.manifesto {
			.numbers {
				padding: 0;
			}
		}
		.content {
			grid-template-columns: 1fr;
			grid-gap: 50px;

			.text {
				padding-bottom: 0;
			}

			.image {
				background: url(/wp-content/uploads/2020/03/what_photo.jpg) center right no-repeat;
    			background-size: cover;
    			height: 516px;
			}
		}
	}



	.beyond_our_clients {
		padding: 0;

		.guides__block {
			padding: 80px 0;
		}
		.content {
			grid-template-columns: 1fr;
			grid-gap: 60px;
		}
	}


	.our_approach {
		padding: 80px 0 0 0;

		.content {
			grid-template-columns: 1fr;
			grid-gap: 50px;
			padding: 80 0px 0 0;

			h2 {
				padding-bottom: 40px;
			}

			.image {
				grid-row: 2;
			}
		}
	}


	#where_to_start {
		padding: 80px 0;
	}


	.services_this {
		padding: 80px 0;

		h2 {
			padding-bottom: 50px;
		}

		.description {
			max-width: 100%;
			padding-bottom: 50px;
		}

		ul {
			&.num-list {
				grid-gap: 40px;
			}
		}
	}


	.black_section {
		padding: 0;

		&.manifesto {
			.guides__block {
				&:before {
					right: -50%;
				}
			}
		}

		h2 {
			padding-left: 0;
		}

		.description {
			grid-template-columns: 120px 1fr;
			align-items: start;
			padding-bottom: 60px;

			img {
				display: block;
				max-width: 100%;
			}
		}

		ul {
			&.num-list {
				& > li {
					& > div {
						&:before {
							top: 18px;
						}
					}
				}
			}
		}
	}


	#description {
		padding: 80px 0;
	}


	#cases {
		padding: 80px 0;

		.cases {
			.case {
				margin-top: 60px;
				.image {
					top: 0;
					transform: translate(-50%, -50%);
					left: 50%;
				}

				.content {
					padding: 100px 90px 40px 90px;
					width: 100%;
				}

				.read-btn {
					position: relative;
					top: 0;
					left: 50%;
    				transform: translateX(-50%);
					width: 100%;
					background: #fff;
					padding-bottom: 60px;

					a {
						&.btn-red {
							margin: 0 auto;
						}
					}
				}
			}
		}
	}

	#contacts {
		padding: 80px 0;

		.section__title-block {
			padding-bottom: 0;
		}

		.contacts__col {
			margin: 0;

			.contacts__col_form {
				margin: 30px 0 0 0;
			}
		}
	}



	}
}



@media (max-width: 991px) {
	.blockchain_development {
		#blockchain_development {
			.content{
				.text {
					max-width: 628px;
				}
			} 
		}

		.black_section {
			ul {
				&.num-list {
					grid-gap: 40px;
					grid-row-gap: 110px;
				}
			}
		}
	}
}

@media (min-wdth: 768px) and (max-width: 860px) {

	.blockchain_development {
		#cases {
			.cases {
				.case {
					.image {
						transform: translate(-30%, -50%);
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {

#how_works {
			.guides__block {
				padding: 55px 0;
			}

			h2 {
				font-weight: bold;
				padding-bottom: 50px;
			}

			.guides__block {
				& > h4 {
					font-size: 18px;
				}
			}

			.list {
					padding-top: 0;
					grid-template-columns: 1fr;
					grid-gap: 40px;

					.item {
						&.red-line {
							&:before {
								width: 200%;
							}
						}

						&.red-line-mobile:before {
						    position: absolute;
						    top: 40px;
						    left: -50%;
						    height: 2px;
						    width: 200%;
						    content: '';
						    background: #FF5C5C;
						}

						& > img {
							margin-bottom: 24px;
						}

						.arrow-right-mobile {
							&:after {
								content: '';
								border: 11px solid transparent;
								border-left: 10px solid #FF5C5C;
								position: absolute;
								left: auto;
								right: 0;
								top: 40px;
								transform: translateY(-50%);
							}
						}
					}

					.row {
						grid-template-columns: 1fr;
						grid-gap: 60px;

						&.line-right {
							& > .item {
								&:first-child {
									&:after {
										display: block;
									}
								}

								&:last-child {
									&:before {
										left: -50%;
									}
								}
							}
						}
					}
			}
		}


	.blockchain_development {
		h2 {
			font-size: 32px;
			line-height: 48px;
		}
		#blockchain_development {
			.content{
				padding-top: 0px;

				.text {
					max-width: 428px;
					padding-bottom: 60px;

					h1 {
						font-size: 32px;
						line-height: 48px;
					}
				}

				.image {
					height: 400px;
					background: url(/wp-content/uploads/2020/04/Photo_mobile.jpg) center right no-repeat;
					background-size: cover;
				}
			} 
		}

		#quote {
			position: relative;
			width: 100%;
			left: 0;
			transform: none;
			grid-template-columns: 1fr;

			.icon {
				img {
					margin: 0 auto;
				}
			}
		}

		.what_is_blaize {
			padding-top: 60px;
			overflow: hidden;

			h2 {
				padding-bottom: 42px;
			}

			.guides__block {
				padding-bottom: 45px;
			}

			.content {
				grid-gap: 40px;

					.image {
						width: calc(100% - -60px);
						left: -30px;
						background: url(/wp-content/uploads/2020/04/what_photo_mobile.jpg) center right no-repeat;
						background-size: cover;
						position: relative;
					}
			}
		}


		.beyond_our_clients {
			.guides__block {
				padding: 55px 0;
			}

			h4 {
				font-size: 18px;
			}

			h2 {
				font-weight: bold;
			}

			.content {
				grid-gap: 50px;

				.text {
					h2 {
						padding: 10px 0 0 0;
					}

					& > p {
						display: none;
					}
				}

				.list {
					padding-top: 0;
					grid-template-columns: 1fr;
					grid-gap: 40px;
				}
			}
		}

		.our_approach {
			padding-top: 55px;
			overflow: hidden;

			.content {
				.image {
					width: calc(100% - -60px);
					left: -30px;
					background: url(/wp-content/uploads/2020/04/Approach_photo_mobile.jpg) center right no-repeat;
					background-size: cover;
					position: relative;
					height: 410px;
				}
			}
		}

		#where_to_start {
			padding: 55px 0;
			
			.guides__block {
				& > h4 {
					font-size: 18px;
					line-height: 32px;
					padding-bottom: 20px;
				}

				& > h2 {
					font-weight: bold;
					padding-bottom: 20px;
				}

				& > p {
					padding-bottom: 20px;
				}
			}
			.contact-form {
				.input_block_wrap {
					grid-template-columns: 1fr;
					grid-gap: 0;
				}
			}
		}

		.services_this {
			padding: 55px 0;

			h2 {
				font-weight: bold;
				padding-bottom: 20px;
			}

			ul {
				&.num-list {
					& > li {
						h4 {
							grid-template-columns: 1fr;
							grid-gap: 0;
							padding-bottom: 10px;

							&:before {
								text-align: left;
								font-size: 56px;
								line-height: 80px;
							}
						}

						& > p {
							padding: 0;
						}
					}
				}
			}
		}


		.black_section {
			&.manifesto {
				.guides__block {
					padding: 55px 0;
				}
			}

			.description {
				grid-template-columns: 1fr;

				img {
					max-width: 120px;
				}
			}

			ul {
				&.num-list {
					grid-template-columns: 1fr;

					& > li {
						& > div {
							grid-template-columns: 1fr;
							grid-gap: 5px;
							margin-bottom: 15px;

							h4 {
								padding-left: 70px;

								&:before {
									font-size: 48px;
									min-width: 62px;
									transform: translateY(-54%);
								}
							}
						}

						& > ul {
							padding-left: 70px;
						}
					}
				}
			}
		}

		#description {
			padding: 60px 0;
		}

		#cases {
			padding: 55px 0;

			.guides__block {
				& > h2 {
					padding-bottom: 30px;
				}
			}

			.cases {
				padding-bottom: 50px;
				.case {
					.content {
						padding: 110px 20px 50px 20px;
					}

					.read-btn {
						padding: 0;

						a {
							&.btn-red {
								width: 100%;
							}
						}
					}
				}
			}
		}

		#contacts {
			padding: 55px 0;
			h2 {
				&.section__title {
					font-size: 32px;
					line-height: 48px;
					font-weight: bold;
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.blockchain_development {
		#blockchain_development {
			.content{
				.text {
					max-width: 325px;
				}
			} 
		}

		.black_section {
			ul {
				&.num-list {
					& > li {
						& > ul {
							li {
								&:before {
									top: 12px;
									left: -40px;
								}
							}
						}
					}
				}
			}
		}
	}
}


@media (max-width: 340px) {
	.blockchain_development {
		#blockchain_development {
			.content{
				.text {
					max-width: 280px;
				}
			} 
		}
	}
}

$thick : 1px;
$pad : 0.7em;
$extra : calc(#{$pad} * 1.2);
$color : #000;


.cd-section {
	position: relative;
}

.cd-nav-trigger {
	display: none;
}

#page-nav-head {
  z-index: 1;
  display: inline-block;
  vertical-align: sub;
  position: relative;
  top: -1px;

  ul {
    counter-reset: cditemnum;

    & > li {
      float: left;
      font-size: 12px;
      letter-spacing: 3.2px;
      padding: 5px 9px 1px 12px;
      position: relative;
      overflow: hidden;
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: $thick;
        background: $color;
        z-index: -1;
        transition: 
          transform 0.8s cubic-bezier(1,0,.37,1) 0.2s,
          right 0.2s cubic-bezier(.04,.48,0,1) 0.6s,
          left 0.4s cubic-bezier(.04,.48,0,1) 0.6s;
        transform-origin: left;
      }
      & > a {
        & > span {
          &.cd-dot {
            &:before {
              position: relative;
              counter-increment: cditemnum;
              content: counters(cditemnum, "", decimal-leading-zero);
            }
          }
          &.line {
            position: absolute;
            background: $color;
            
            &.-right,
            &.-left {
              width: $thick;
              bottom: 0;
              top: 0;
              transform: scale3d(1,0,1);
            }  
            
            &.-top {
              height: $thick;
              left: 0;
              right: 0;
              transform: scale3d(0,1,1);
            }
            
            &.-right {
              right: 0;
              transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.23s;
              transform-origin: top;
            }
            
            &.-top {
              top: 0;
              transition: transform 0.08s linear 0.43s;
              transform-origin: left;
            }
            
            &.-left {
              left: 0;
              transition: transform 0.08s linear 0.51s;
              transform-origin: bottom;
            } 
          }

        }

        &:hover {
          color: #000;
          .cd-label {
            display: block;
          }
        }
      }

&.is-selected {
  text-shadow: 1px 0 0.65px #000, 1px 0 0.65px #000;
  margin-right: -1px;
  &:after {
    transform: scale3d(0,1,1);
    left: 0;
    right: 0;
    transform-origin: left;
    transition: 
    transform 0.2s cubic-bezier(1,0,.65,1.01) 0.17s,
    left 0.2s cubic-bezier(1,0,.65,1.01),
    right 0s 0.3s;
  }

  & > a {
    & > span {
      &.line {
        transform: scale3d(1,1,1);
        
        &.-right {
          transition: transform 0.08s linear 0.48s;
          transform-origin: top;
        }
    
        &.-top {
          transition: transform 0.08s linear 0.4s;
          transform-origin: left;
        }
    
        &.-left {
          transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.2s;
          transform-origin: bottom;
        }
      }

    }
  }

  a {
    color: #000;
  }

  .cd-label {
    display: block;
  }
}
    }
  }

	li {
      a {
        display: block !important;
        color: #bababa;
      }
	}

	a {
		display: block !important;
		
		span {
			float: left;
            display: block;
		}

		&:hover .cd-label {
			opacity: 1;
		}
	} 

	.cd-dot {
		position: relative;
	}

	.cd-label {
		position: relative;
        margin-left: 10px;
        display: none;
        text-transform: uppercase;
		-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
		-moz-transition: -moz-transform 0.2s, opacity 0.2s;
		transition: transform 0.2s, opacity 0.2s;
	}
}

body {
  &.top {
    #page-nav-head {
      ul {
        & > li {
          &:after {
            transition: 
            transform 0.8s cubic-bezier(1,0,.37,1) 0.2s,
            left 0.2s cubic-bezier(.04,.48,0,1) 0.6s,
            right 0.4s cubic-bezier(.04,.48,0,1) 0.6s;
            transform-origin: right;
            right: -1px;
          }
    
          &.is-selected {
            &:after {
              transform-origin: right;
              transition:
                transform 0.2s cubic-bezier(1,0,.65,1.01) 0.17s,
                right 0.2s cubic-bezier(1,0,.65,1.01),
                left 0s 0.3s;
            }

            & > a {
              & > span {
                &.line {
                  transform: scale3d(1,1,1);

                  &.-right {
                    transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.2s;
                    transform-origin: bottom;
                  }

                  &.-top {
                    transition: transform 0.08s linear 0.4s;
                    transform-origin: right;
                  }

                  &.-left {
                    transition: transform 0.08s linear 0.48s;
                    transform-origin: top;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
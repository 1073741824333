.btn {
  padding: 18px 0;
  text-align: center;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 14px;
  opacity: 1;
  line-height: 140%;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance:none;
  &-white {
    background: $white;
    color: $primary;
  }
  &-black {
    background: $primary;
    color: $white;
  }
  &-red {
    background: $primary-red;
    color: $white;
  }

}

@media(max-width: 1199px) {
  .btn {
    padding: 20px 0;
    width: 365px;
  }
}
@media(max-width: 991px) {
  .btn {
    width: 314px;
  }
}
@media(max-width: 767px) {
  .btn {
    padding: 15px 0;
    width: 320px;
  }
}

@media(max-width: 480px) {
  .btn {
    width: 277px;
  }
}
// Header styles

header {
	&#header {
		position: fixed;
		top: 0;
		z-index: 99;
		width: 100%;
		left: 0;

		.logo-header { 
			padding: 50px 0 0 0;
			transition: padding 0.3s linear;
			display: grid;
			grid-template-columns: 1fr auto;
			overflow: hidden;

			&.cdnav {
				grid-template-columns: auto 1fr auto;

				.pageNav {
					text-align: center;
					transform: translateY(0%);
					height: 23px;

					#page-nav-head {
						display: inline-block;
					}
				}
			}

			a {
				display: inline-block;
			}
			
			.desktop-logo {
				display: block;

				.logo-home {
					img {
						width: 90px;
						position: relative;
					}
				}

				.logo-small {
					img {
						width: 90px;
					}
				}
			}
 
			.mobile-logo {
				display: none;

				a {
					&.logo {
						img, svg {
							width: 32px;
						}
					}
				}

				.logo-title {
					margin: 40px 0 0 10px;
					img {
					    max-width: 210px;
					}
				}
			}
		}

		&:not(.sticky) {
			.logo-header {
				&.cdnav {
					.pageNav {
						transition: 0.3s all;
						transform: translateY(-100%);
						
						#page-nav-head {
							display: none;
						}
					}
				}
			}
		}
	}
}

main {
	.pageHeader {
		padding-top: 0;
	}
	&.blog:not(.archive) {
		& > .pageHeader {
			padding-top: 70px;
		}

		&.single-post {
			& > .pageHeader {
				padding-top: 80px;
			}
		}
	}

	&.blog.archive {
		& > .pageHeader {
			padding-top: 180px;

			@media (max-width: 992px) {
				padding-top: 80px;
			}
		}
	}

	.pageHeader {
		&.staticPage {
			padding-top: 180px;
		}
	}

	&.careers {
		& > .pageHeader {
			padding-top: 70px;
		}
	}

	&.audit {
		& > .pageHeader {
			padding-top: 180px;
		}
	}

	&.custom-page {
		.top_section {
			padding-top: 180px;
		}
	}

	& > div {
		&.single-careers {
				.pageHeader {
					padding-top: 70px;
				}
		}
	}

}

.pageHeader {
  padding: 50px 0 90px 0;
  position: relative;
  z-index: 1;

  &-block {
    margin-bottom: 80px;

    img {
      width: 194px;
      height: auto;
    }
  }
  .mobile-logo {
    margin-bottom: 60px;
    display: none;
    img, svg {
      max-width: 32px;
    }
  }
}

@media (max-width: 992px) {
	header#header {
		.logo-header {
			&.cdnav {
				.pageNav {
					#page-nav-head {
						display: none;
					}
				}
			}
		}
	}
}

@media (min-width: 601px) {
	body {
		&.admin-bar {
			header {
				&#header {
					top: 46px;
	
					.menu-btn {
						top: 46px;
					}
	
					#responsive-menu {
						top: 46px;
					}
				}
			}

			header {
				&#header {
					&.sticky {
						top: 46px;
					}
				}
			}
		}
	}
}


@media (min-width: 782px) {
	body {
		&.admin-bar {
			header {
				&#header {
					top: 32px;
	
					.menu-btn {
						top: 32px;
					}
	
					#responsive-menu {
						top: 32px;
					}
				}
			}

			header {
				&#header {
					&.sticky {
						top: 32px;
					}
				}
			}
		}
	}
}

@media(max-width: 767px) {

	main {
		.pageHeader {
			padding-top: 0;
		}
		&.blog {
			& > .pageHeader {
				padding-top: 80px;
			}
		}
	
		.pageHeader {
			&.staticPage {
				padding-top: 80px;
			}
		}
	
		&.careers {
			& > .pageHeader {
				padding-top: 80px;
			}
		}

		&.audit {
			& > .pageHeader {
				padding-top: 80px;
			}
		}
	
		&.custom-page {
			.top_section {
				padding-top: 80px;
			}
		}
	
		& > div {
			&.single-careers {
				.pageHeader {
					padding-top: 80px;
				}
			}
		}
	}


  .pageHeader {
    padding-bottom: 40px;
    &-block {
      img {
        width: 130px;
      }
    }
  }

  header {
	  &#header {
		.logo-header {
			padding: 24px 0 0 0;
			.desktop-logo {
				display: none;
			}

			.mobile-logo {
				display: block;
			}
		}

		.feed {
			text-align: right;

			.contacts-link {
				opacity: 0;
				display: inline-block;
				font-family: Mont, serif;
				font-style: normal;
				font-weight: 800;
				font-size: 14px;
				line-height: 140%;
				letter-spacing: 0.3em;
				text-transform: uppercase;
				text-decoration: none;
				margin-right: 54px;

				&.active, &:hover {
					color: $primary-red;

					&:before {
						background: $primary-red;
					}
				}
			}
		}

		&.sticky {
			position: fixed;
			background: #fff;
			border-bottom: 1px solid rgb(231, 231, 232);

			.logo-header {
				padding: 12px 0px;
				.mobile-logo {
					display: grid;
					grid-template-columns: auto 1fr;
					.logo-title {
						display: none;
					}
				}
			}

			.feed {
				.contacts-link {
					opacity: 1;
				}
			}

			.sidebar {
				top: 11px;
			}
		}
	  }
  }
}

@media (max-width: 600px) {

	#wpadminbar {
		position: fixed !important;
	}

	body.admin-bar {
		header {
			&#header {
				top: 46px;
	
				.menu-btn {
					top: 46px;
				}
	
				#responsive-menu {
					top: 46px;
				}
			}
		}
	}

  .pageHeader {
    padding: 24px 0 40px 0;
    &-block {
      display: none;
    }
    .mobile-logo {
      display: block;
    }
  }
}


@media (min-width: 1200px) and (max-width: 1250px) {
	header{
		&#header {
			.logo-header {
				.desktop-logo {
					.logo-home {
						img {
							left: -70px;
						}
					}
				}
			}
		}
	}
}

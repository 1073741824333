a {
	text-decoration: none;
	color: inherit;
	transition: all $transition-time;
    outline: none;
    .mobile-device &{
      cursor: default;
    }
  @include mq(767, max) {
    cursor: default;
  }
}
%t_color--white { color: white; }
%t_color--black { color: black; }

%t_text-transform--uppercase { text-transform: uppercase; }
%t_text-transform--none { text-transform: none; }
%t_text-align--center { text-align: center; }
%t_text-align--left { text-align: left; }
%t_text-align--right { text-align: right; }

.mobile-block {
  width: 140px;
  height: 140px;
  background-color: transparent;
  position: relative;
}

.mobile-element {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
}
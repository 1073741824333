#post_contact {
  background: #EDEDED;
  .content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 80px;
    align-items: center;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-gap: 60px;
    }

    @media (max-width: 768px) {
      grid-gap: 40px;
    }

    .text {
      h3 {
        text-transform: uppercase;
        font-size: 32px;
        margin-bottom: 6px;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      p {
        font-size: 24px;
        font-weight: 400;
        text-transform: none;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .form {
      padding: 50px 40px;
      background: #fff;

      input[type="submit"] {
        margin-top: 30px;
        width: 100%;
      }

      .ajax-loader {
        position: absolute;
      }
    }
  }
}
.team {
  background: $background-gray;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .guides__block-list li {
    background: $guides-color-light;
  }
  &__row {
    max-width: 1041px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__col {
    display: flex;
    .team-img {
      position: relative;
      overflow: hidden;
      img {
        max-width: 260px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        &:hover {
          -webkit-transform: scale(1.08);
          transform: scale(1.08);
        }
      }
    }
    .description {
      padding-left: 20px;
      h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 120%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $black;
      }
      h4 {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: $black;
      }
      a {
        padding-top: 40px;
        display: block;
        font-size: 22px;
        color: $black;
        text-decoration: underline;
      }
      .team-contacts {
        .link-responsive-linkedin {
          display: none;
        }
      }
    }
    &:nth-child(2) {
      margin-top: -50px;
      flex-direction: row-reverse;
      justify-content: flex-start;
      .description {
        padding-left: 0;
        padding-right: 20px;
        text-align: right;
        h3 {
          align-items: flex-end;
        }
      }
    }
    &:nth-child(3) {
      margin: -50px 0 0 0;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .description {
        padding: 0 20px 0 0;
        max-width: 260px;
        width: 100%;
        text-align: right;
        h3 {
          align-items: flex-end;
        }
      }
    }
    &:nth-child(4) {
      margin-top: 50px;
      flex-direction: row-reverse;
      justify-content: flex-start;
      .description {
        padding-left: 0;
        padding-right: 20px;
        text-align: right;
        h3 {
          align-items: flex-end;
        }
      }
    }
    &:nth-child(5) {
      margin-top: -50px;
    }
    &:nth-child(6) {
      margin-top: -55px;
      justify-content: flex-end;
    }
  }
  &-btn-wrap {
    padding-right: 80px;
  }
}

@media(max-width: 1199px) {
  .team {
    &__row {
      max-width: 100%;
      .team__col {
        margin: 0 0 120px 0;
        flex-direction: column;
        justify-content: flex-end;
        &:last-child {
          margin: 0;
          align-items: flex-end;
        }
        .team-img img {
          max-width: 100%;
          width: 100%;
          height: 422px;
          overflow: hidden;
          object-fit: cover;
          object-position: top left;
        }
        .description {
          padding: 0;
          max-width: 100%;
          text-align: right;
          h3 {
            padding-top: 32px;
            font-size: 40px;
            flex-direction: row;
            justify-content: flex-end;
            p {
              margin-right: 10px;
              &:last-child {
                margin: 0;
              }
            }
          }
          h4 {
            padding-top: 15px;
            font-size: 20px;
          }
          a {
            padding-top: 32px;
            font-size: 24px;
          }
          .team-contacts {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .link-responsive-linkedin {
              display: block;
              margin-left: 32px;
              position: static;
              img {
                width: 42px;
                height: 42px;
              }
            }
          }
        }
      }
    }
    &-btn-wrap {
      padding-right: 0;
    }
  }
}

@media(max-width: 991px) {
  .team {
    &__row {
      .team__col {
        margin-bottom: 80px;
        .description {
          h3 {
            font-size: 32px;
          }
          h4 {
            font-size: 14px;
          }
          a {
            padding-top: 20px;
            font-size: 18px;
          }
          .team-contacts .link-responsive-linkedin img {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .team {
    &__row {
      .team__col {
        margin-bottom: 60px;
        .team-img img {
          width: 100%;
          height: 300px;
        }
        .description {
          h3 {
            padding-top: 20px;
            font-size: 24px;
          }
          h4 {
            font-size: 12px;
          }
          a {
            font-size: 16px;
          }
          .team-contacts .link-responsive-linkedin {
            margin-left: 20px;
          }
        }
      }
    }
    &-btn-wrap {
      padding-right: 55px;
    }
  }
}

@media(max-width: 480px) {
  .team {
    &__row .team__col {
      .team-img img {
        height: 200px;
      }
      .description .team-contacts {
        align-items: flex-end;
      }
      &.team-btn-wrap {
        padding: 0;
        align-items: center;
      }
    }
  }
}

.technologies {
  background: $background-light-gray;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .guides__block-list li {
    background: $guides-color-light;
  }
  &__row {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    &:last-child {
      padding: 0;
    }

    @media (min-width: 992px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &__col {
    width: 460px;
  }
  .description {
    display: flex;
    &-text {
      padding: 45px 0  0 62px;
      a {
        &:hover {
          h3 {
            color: $primary-red;
          }
        }
      }
      h3 {
        margin-bottom: 20px;
        display: inline-block;
        font-size: 14px;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: $primary;
        transition: all .3s;
      }
      ul li, p {
        font-size: 22px;
        line-height: 160%;
        color: $primary;
      }
    }
  }
 &-btn-wrap {
   padding-right: 130px;
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
 }
}

@media (max-width: 1199px) {
  .technologies {
    .section__title-block {
      padding: 0 0 80px 0;
    }
    &__row {
      padding: 0;
      max-width: 100%;
      flex-direction: column;
    }
    &__col {
      margin-left: 30px;
      padding-bottom: 60px;
      width: 100%;
    }
    &-0 {
      .description-text {
        ul li:before {
          top: calc(50% - 5px);
          width: 10px;
          height: 10px;
          background: $primary-red;
        }
      }
    }
    &-1 {
      .description-text {
        ul li:before {
          top: calc(50% - 1px);
          width: 25px;
          height: 2px;
          background: $guides-color-dark;
        }
      }
    }
    &-2 {
      .description-text {
        ul li:before {
          top: calc(50% - 1px);
          width: 25px;
          height: 2px;
          background: $primary-red;
        }
      }
    }
    &-3 {
      .description-text {
        ul li:before {
          top: calc(50% - 5px);
          width: 10px;
          height: 10px;
          background: $guides-color-dark;
          border-radius: 600px;
        }
      }
    }
    &-4 {
      .description-text {
        ul li:before {
          top: calc(50% - 5px);
          width: 10px;
          height: 10px;
          background: $primary-black;
          border-radius: 600px;
        }
      }
    }
    .description {
      &-text {
        padding-left: 130px;
        h3 {
          font-size: 18px;
        }
        ul {
          padding-left: 43px;
          li {
            position: relative;
            font-size: 24px;
            &:before {
              content: '';
              position: absolute;
              left: -43px;
            }
          }
        }
      }
    }
    &-btn-wrap {
      margin-left: 0;
      padding: 0 90px 0 0;
    }
  }
}

@media (max-width: 991px) {
  .technologies {
    &__col {
      padding-bottom: 80px;
    }
    .section__title-block {
      padding: 0 0 60px 0;
    }
    .description-text {
      padding-left: 92px;
      h3 {
        font-size: 14px;
      }
      ul {
        padding-left: 30px;
        li {
          font-size: 20px;
          &:before {
            left: -30px;
          }
        }
      }
    }
    &-btn-wrap {
      padding: 0 80px 0 0;
    }
  }
}

@media (max-width: 767px) {
  .technologies {
    &__col {
      margin-left: 0;
      padding-bottom: 40px;
    }
    .section__title-block {
      padding: 0 0 40px 0;
    }
    .description-text {
      padding-left: 70px;
      ul {
        li {
          font-size: 18px;
        }
      }
    }
    &-btn-wrap {
      padding: 0 55px 0 0;
    }
  }
}

@media (max-width: 480px) {
  .technologies {
    .description {
      margin-left: 25px;
      flex-direction: column;
      &-text {
        padding: 26px 0 0 0;
        ul {
          margin-left: 70px;
          li {
            letter-spacing: 1px;
          }
        }
      }
    }
    &-0 .description,
    &-3 .description,
    &-4 .description {
       &-text {
         ul li:before {
           top: 8px;
         }
       }
    }
    &-1 .description,
    &-2 .description {
      &-text {
        ul li:before {
          top: 12px;
        }
      }
    }
    &-btn-wrap {
      padding: 0;
      justify-content: center;
    }
  }
}

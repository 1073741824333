.team_carousel_section {
    overflow: hidden;
    position: relative;

    .section__title-block {
        padding-bottom: 0;
    }

    h2 {
        padding-bottom: 50px;
    }

    p {
        text-transform: none;
        font-size: 22px;
        line-height: 36px;
        padding-bottom: 70px;
        max-width: 780px;

        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 160%;
        }
    }

    .swiper-pagination {
        display: none;;
    }

    .team-btn-wrap {
        text-align: center;
        padding: 40px 0 0 0;
        position: relative;
        z-index: 1;

        .btn {
            display: block;
            margin: 0 auto;
        }
    }

    .block-team-carousel {
        position: relative;

        .center-item-team {
            position: absolute;
            pointer-events: none;
            z-index: 99;
            display: grid;
            grid-template-columns: 1fr 1fr;
            background: #fff;
            max-width: 560px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);


            .description {
                position: relative;
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr auto;
                padding: 20px;
                box-shadow: inset 0 0 0 1px #E7E7E8;
                background: #fff;

                .name {
                    font-size: 32px;
                    line-height: 46px;
                    font-weight: bold;

                    span {
                        display: block;
                    }
                }

                p {
                    font-size: 18px;
                    font-weight: bold;

                }

                .mail-link {
                    font-size: 22px;
                    line-height: 36px;
                }
            }
        }
    }

    .swiper-team-wrap {
        position: relative;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto 70px;

        .swiper-button {
            &-prev {
              color: #000;
              border: 1px solid #000;
              opacity: 0.3;
              transform: none;
              
                &:hover {
                    opacity: 1;
                }
            }
    
            &-next {
              color: #000;
              border: 1px solid #000;
              opacity: 0.3;
              pointer-events: all !important;
              cursor: pointer;
              transform: none;

              &:hover {
                  opacity: 1;
              }
            }
          }
    }

    .swiper-team-container { 
        position: relative;
        overflow: hidden;

        .swiper-scrollbar {
            display: none;
        }

        .swiper-slide {
            transform:scale(0.9);
            opacity: 0.6;
            
            &.swiper-slide-prev, &.swiper-slide-next {
                transform:scale(1);
                opacity: 0.8;
            }

            &.swiper-slide-active {
                transform: scale(1);
                opacity: 1;
                z-index: 111;
                .item-team{
                    transform: translateX(-50%);
                    position: relative;
                    z-index: 10;
                    grid-template-columns: 1fr;
    
                    .description{
                        display: block!important;
                    }
                }
            }
        }
    
        .item-team { 
            display: grid; 
            grid-template-columns: 1fr; 
            transition: 0.7s all; 
            background: #fff; 
            position: relative; 
            transform:scale(0.9);
    
            .photo { 
                transition: 0.2s all; 
                background-size: cover !important; 
                background-repeat: no-repeat !important; 
    
                img { 
                    position: relative; 
                    z-index: 2; 
                    max-width: 270px; 
                    width: 100%; 
                }
            }
    
            .description {
                display: grid;
                grid-template-rows: auto 1fr auto;
                padding: 20px;
                position: absolute;
                top: 0;
                left: 100%;
                width:260px;
                height: 100%;
                background-color: #fff;

                @media (min-width: 992px) {
                    &:before {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border: 1px solid #E7E7E8;
                        content: '';
                        border-left: 0;
                    }
                }
    
                .name { 
                    font-size: 32px; 
                    line-height: 46px; 
                    font-weight: bold; 
    
                    span { 
                        display: block; 
                    }
                }
    
                p { 
                    font-size: 18px; 
                    font-weight: bold; 
                }
    
                .mail-link { 
                    font-size: 18px; 
                    line-height: 36px;
                    position: absolute;
                    bottom: 20px;
                }
            }
        }
    }
    
    .description{
        display: none !important;
    }
    
    .sized{
      transform: scale(0.9);
    }
    .photo {
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}

body {
    &.home {
        .team_carousel_section {
            background: $background-light-gray;
            .guides__block-list {
                z-index: 0;

                li {
                    background: $guides-color-light;
                }
              }
              .section__title-block {
                  z-index: 2;
              }
              .swiper-team-wrap {
                max-width: 1041px;
              }
            .swiper-team-container {
                z-index: 2;
            }
        }
    }
}


@media (max-width: 1200px) {
    .team_carousel_section {
		padding: 80px 0px;

		.team-carousel {
			padding: 0 140px;
		}

		& > .guides__block {

			&.team-photo {
				max-width: 100%;
			}
		}
	}
}


@media (max-width: 991px) {
    .team_carousel_section {
        padding: 55px 0 0 0;

        h2 {
            padding-bottom: 30px;
        }

        p {
            padding-bottom: 30px;
        }

        .swiper-pagination {
            display: block;
            position: relative;
            bottom: 0;
            margin-top: 15px;

            .swiper-pagination-bullet-active {
                background: $primary-red;
            }

            .swiper-pagination-clickable .swiper-pagination-bullet {
                outline: none;
            }
        }

        & > .guides__block {

            &.team-photo {
                .photo {
                    height: 400px;
                    background: url('/wp-content/uploads/2020/04/Photo_big_mobile.jpg') center no-repeat;
                    background-size: cover;

                    img {
                        display: none;
                    }
                }
            }
        }
        .swiper-team-wrap {
            margin-bottom: 30px;
        }
        .swiper-team-container {
            padding: 0 50px;

            .swiper-slide {
                &.swiper-slide-active {
                    .item-team {
                        transform: none;
                        
                        .description {
                            position: relative;
                            left: 0;
                            width: 100%;

                            .mail-link {
                                position: relative;
                                bottom: 0;
                            }
                        }
                    }
                }

                &.swiper-slide-next, &.swiper-slide-prev {
                    opacity: 0.6;
                }
            }


            .item-team {
                grid-template-columns: 1fr;
                background: none;

                .description {
                    padding: 20px 0 0 0;
                    box-shadow: none;
                    background: none;

                    .name {
                        font-size: 22px;
                        line-height: 36px;

                        span {
                            display: inline-block;
                        }
                    }

                    .mail-link {
                        font-size: 18px;
                    }

                    p {
                        padding: 0;
                    }
                }
            }	
        }
    }

    body {
        &.home {
            .team_carousel_section {
                padding: 55px 0;
            }
        }
    }
}

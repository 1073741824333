section {
  padding: 70px 0;
  .section {
    &__title-block {
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: uppercase;
      font-size: 70px;

      &.archive {
        font-size: 30px;
        font-weight: 400;
        text-transform: none;

        span {
          &.page-description {
            text-transform: uppercase;
            font-size: 70px;
            display: block;
            line-height: 130%;
          }
        }
      }
    }

    &__description {
      font-size: 30px;
      font-weight: 400;
      text-transform: initial;
      margin-bottom: 15px;
    }

    &__title {
      font-weight: 400;
      line-height: 130%;

      &.blog {
        margin-left: -5px;
        font-size: 60px;
        font-weight: 500;

        @media (max-width: 768px) {
          margin-left: -3px;
        }
      }


      &--left {
        margin-left: 130px;
      }
    }
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}
.guides__block {
  margin: 0 auto;
  max-width: 1041px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-list {
    position: absolute;
    top: 0;
    max-width: 1041px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: -1;

    li {
      width: 1px;
      height: 100%;
      background: #E7E7E8;
    }
  }
}

.link-linkedin {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  img {
    width: 100%;
    height: auto;
    &:hover {
      transform: scale(1) !important;
      -webkit-transform: scale(1) !important;
    }
  }
}

.pageHeader-block .logo-small {
  img {
    width: 90px;
  }
}

@media (max-width: 1199px) {
  .guides__block {
    max-width: 728px;
    &-list {
      max-width: 728px;
    };
  }
  section {
    .section {
      &__title-block {
        &.archive {
          .section__title {
            font-size: 30px;

            span {
              &.page-description {
                font-size: 70px;
              }
            }
          }
        }
      }
      &__description  {
        font-size: 32px;
      }
      &__title {
        &--left {
          margin-left: 92px;
        }
      }
    }
  }
  .link-linkedin {
    display: none;
  }
}

@media (max-width: 991px) {
  .guides__block {
    max-width: 628px;
    &-list {
      max-width: 628px;
    };
  }
  section {
    padding: 100px 0;
    .section {
      &__title-block {
        &.archive {
          .section__title {
            font-size: 22px;
  
            span {
              &.page-description {
                font-size: 65px;
              }
            }
          }
        }
      }

      &__description  {
        font-size: 22px;
      }
      &__title {
        font-size: 65px;
        &--left {
          margin-left: 80px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .guides__block {
    max-width: 428px;
    &-list {
      max-width: 428px;
    }
  }
  section {
    padding: 60px 0;
    .section {

      &__title-block {
        &.archive {
          .section__title {
            font-size: 16px;
  
            span {
              &.page-description {
                font-size: 40px;
              }
            }
          }
        }
      }


      &__description {
        font-size: 16px;
      }
      &__title {
        font-size: 40px;
        &--left {
          margin-left: 55px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  section {
    .section__title-block {
      padding-bottom: 20px;
    }
  }
  .guides__block {
    max-width: 325px;
    &-list {
      max-width: 325px;
      li:nth-child(n+5) {
        display: none;
      }
    }
  }
}

@media (max-width: 375px) {
  section {
    .section {
      &__title {
        font-size: 34px;
        &--left {
          margin-left: 35px;
        }
      }
    }
  }
}
@media (max-width: 340px) {
  .guides__block {
    max-width: 280px;
    &-list {
      max-width: 280px;
    }
  }
}

.notFound {
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  &.pageHeader {
    padding: 0;
  }
  .guides__block {
    position: relative;
    height: 100vh;
    justify-content: center;
    &-list li {
      background: $guides-color-light;
    }
  }
  .pageHeader-block {
    position: absolute;
    top: 50px;
    left: -102px;
  }
  &_title {
    display: flex;
    flex-direction: column;
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 70px;
      font-weight: 400;
      color: $primary;
      line-height: 130%;
      text-transform: uppercase;
      span {
        font-size: 30px;
        line-height: 120%;
        text-transform: initial;
      }
      p {
        margin-left: 40px;
      }
    }
    .btn {
      margin-top: 60px;
    }
  }

  .modeling_element {
    background: url("../img/Modeling_element_light.png") no-repeat center center;
    background-size: contain;
    width: 780px;
    height: 800px;
    position: absolute;
    bottom: -110px;
    left: -389px;
    z-index: -1;
  }
  .blockchain_element {
    background: url("../img/blockchain_element.png") no-repeat center center;
    background-size: contain;
    width: 180px;
    height: 180px;
    position: absolute;
    bottom: 70px;
    left: 300px;
  }
  .mobile_element {
    background: url("../img/mobile_element.png") no-repeat center center;
    background-size: contain;
    width: 180px;
    height: 180px;
    position: absolute;
    top: -40px;
    right: 300px;
  }
  .front_end_element {
    background: url("../img/front_end_element.png") no-repeat center center;
    background-size: contain;
    width: 180px;
    height: 180px;
    position: absolute;
    top: 130px;
    right: 40px;
  }
  .brasket-right {
    background: url("../img/brasket-right.png") no-repeat center center;
    background-size: contain;
    width: 24px;
    height: 60px;
    position: absolute;
    bottom: 50px;
    right: -64px;
  }
  &_description {
    max-width: 518px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 160px;
      font-weight: 200;
      line-height: 130%;
      text-transform: uppercase;
      &:last-child {
        margin: 0;
      }
    }
    &_animation {
      margin: 0 50px;
      background: url("../img/front_end_element.png") no-repeat center center;
      background-size: contain;
      width: 160px;
      height: 160px;
    }
  }
}

@media (max-width: 1366px) {
  .notFound {
    .guides__block {
      max-width: 991px;
    }
    &_title .btn {
      width: 246px;
    }
    .modeling_element {
      left: -410px;
    }
  }
}
@media (max-width: 1199px) {
  .notFound {
    .guides__block {
      max-width: 728px;
      justify-content: flex-start;
    }
    .pageHeader-block {
      margin: 50px 0 0 -102px;
      position: static;
    }
    &_title {
      margin-top: 150px;
      h2 {
        font-size: 73px;
      }
      .btn {
        width: 274px;
      }
    }
    .modeling_element {
      width: 883px;
      height: 905px;
      bottom: 130px;
      left: -337px;
    }
    .blockchain_element {
      width: 340px;
      height: 340px;
      right: -200px;
      bottom: 550px;
      left: initial;
    }
    .mobile_element {
      width: 204px;
      height: 204px;
      right: 260px;
    }
    .front_end_element {
      width: 222px;
      height: 222px;
      top: initial;
      bottom: 295px;
      left: -230px;
    }
    &_description {
      max-width: 530px;
      right: 55px;
      bottom: 170px;
      span {
        font-size: 180px;
      }
      &_animation {
        width: 180px;
        height: 180px;
      }
    }
  }
}
@media (max-width: 1200px) and (max-height: 768px) {
  .notFound {
    &_title {
      margin-top: 80px;
    }
    &_description {
      max-width: 365px;
      right: 0;
      bottom: 140px;
      span {
        font-size: 140px;
      }
      &_animation {
        margin: 0 20px;
        width: 140px;
        height: 140px;
      }
    }
  }
}
@media(max-width: 991px) {
  .notFound {
    .guides__block {
      max-width: 628px;
    }
    .pageHeader-block {
      margin-left: -25px;
    }
    &_title {
      margin-top: 60px;
    }
    .modeling_element {
      width: 600px;
      height: 600px;
      bottom: 50px;
      left: -200px;
    }
    .blockchain_element {
      width: 200px;
      height: 200px;
      right: -140px;
    }
    .mobile_element {
      top: -80px;
      right: 80px;
    }
    .front_end_element {
      width: 150px;
      height: 150px;
      bottom: 150px;
      left: -130px;
    }
    .brasket-right {
      right: -25px;
    }
    &_description {
      max-width: 410px;
      right: 0;
      span {
        font-size: 165px;
      }
      &_animation {
        width: 140px;
        height: 140px;
      }
    }
  }
}
@media(max-width: 767px) {
  .notFound {
    .guides__block {
      max-width: 428px;
    }
    .pageHeader-block {
      margin-left: -15px;
    }
    &_title {
      h2 {
        font-size: 37px;
        span {
          font-size: 18px;
        }
      }
      .btn {
        width: 214px;
      }
    }
    .mobile_element {
      width: 500px;
      height: 500px;
      left: -227px;
    }
    .blockchain_element {
      width: 150px;
      height: 150px;
      right: -50px;
    }
    .mobile_element {
      width: 150px;
      height: 150px;
      top: -65px;
      right: 60px;
      left: initial;
    }
    .front_end_element {
      bottom: 80px;
      left: 0;
    }
    .brasket-right {
      width: 16px;
      height: 44px;
      right: -15px;
    }
    &_description {
      margin-top: 150px;
      max-width: 100%;
      position: static;
      span {
        font-size: 120px;
      }
      &_animation {
        margin: 0 30px;
      }
    }
  }
}
@media (max-width: 480px) {
  .notFound {
    .guides__block {
      max-width: 325px;
    }
    &.pageHeader .mobile-logo {
      margin: 40px 0 0 0;
    }
    &_title {
      .btn {
        margin-top: 30px;
      }
    }
    .modeling_element {
      width: 520px;
      height: 520px;
      left: -260px;
    }
    .front_end_element {
      bottom: 40px;
      left: -100px;
    }
    .blockchain_element {
      top: 230px;
      right: -120px;
      bottom: initial;
    }
    .mobile_element {
      width: 110px;
      height: 110px;
      top: -72px;
      right: 105px;
    }
    .brasket-right {
      right: 16px;
      width: 20px;
      height: 61px;
    }
    &_description {
      margin-top: 50px;
      span {
        font-size: 120px;
      }
      &_animation {
        margin: 0 25px;
        width: 120px;
        height: 120px;
      }
    }
  }
}
@media (max-width: 850px) and (max-height: 480px) {
  .notFound {
    .pageHeader-block {
      margin-top: 20px;
    }
    &_title {
      margin-top: 40px;
      h2 {
        font-size: 22px;
      }
      .btn {
        margin-top: 40px;
      }
    }
    .modeling_element {
      width: 500px;
      height: 500px;
      bottom: -50px;
      left: -333px;
    }
    .blockchain_element {
      width: 150px;
      height: 150px;
      right: -150px;
      bottom: 130px;
    }
    .front_end_element {
      left: -180px;
    }
    .mobile_element {
      width: 150px;
      height: 150px;
      right: 10px;
    }
    &_description {
      margin: 0;
      max-width: 220px;
      position: absolute;
      bottom: 0;
      right: 10px;
      span {
        font-size: 100px;
      }
      &_animation {
        margin: 0 15px;
        width: 80px;
        height: 80px;
      }
    }
  }
}
@media (max-width: 375px) {
  .notFound {
    &_title {
      h2 {
        font-size: 28px;
        span {
          font-size: 16px;
        }
      }
    }
    .front_end_element {
      width: 120px;
      height: 120px;
    }
  }
}
@media (max-width: 340px) {
  .notFound {
    .guides__block {
      max-width: 280px;
    }
  }
}
@media (max-width: 320px) {
  .notFound {
    &_title {
      margin-top: 30px;
    }
    .front_end_element {
      width: 120px;
      height: 120px;
      bottom: -44px;
      left: -65px;
    }
    .mobile_element {
      right: 60px;
    }
    .blockchain_element {
      width: 120px;
      height: 120px;
      top: 220px;
      right: -80px;
    }
    .brasket-right {
      right: 0;
      bottom: 20px;
    }
  }
}
@media (max-width: 568px) and (max-height: 320px) {
  .notFound {
    &_title {
      .btn {
        margin-top: 20px;
        width: 160px;
      }
    }
    .modeling_element {
      width: 300px;
      height: 300px;
      bottom: -10px;
      left: -189px;
    }
    .blockchain_element {
      width: 100px;
      height: 100px;
      right: -90px;
    }
    .front_end_element {
      width: 100px;
      height: 100px;
      left: -110px;
    }
    .mobile_element {
      width: 100px;
      height: 100px;
      right: 56px;
    }
    &_description {
      bottom: 16px;
      right: 0px;
      span {
        font-size: 80px;
      }
    }
  }
}

@media(min-width: 1920px) and (min-height: 1080px) {
  .notFound {
    .guides__block {
      max-height: 1080px;
    }
  }
}

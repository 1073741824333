.blogPage {
  &__block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    &-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-bottom: 30px;
    padding: 30px 10px 30px 30px;
    width: 1041px;
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 14px;
    color: $white;
    background: linear-gradient(180deg, rgba(30, 30, 35, 0) 0%, rgba(30, 30, 35, 0.75) 69.79%), url("../img/blogPage_1.png");
    background-size: contain;
    &-tag {
      padding: 5px;
      border-radius: 1px;
      color: $black;
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: bold;
      background-color: $white;
      mix-blend-mode: screen;
    }

    &-title {
      margin: 20px 0 30px 0;
      max-width: 620px;
      font-weight: 400;
      font-size: 30px;
      line-height: 120%;
    }

    &-more {
      letter-spacing: 0.3em;
      text-transform: uppercase;
      font-weight: 900;
    }
  }
}

@media(max-width: 480px) {
  .blogPage {
    &__item {
      width: 325px;
      height: 386px;
      background-repeat: no-repeat;
      background-position: center center;
      &-title {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
  }
}
body {
  &.home {
    .sliderAnim {
      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }

    .mainBlock {
      padding: 0 !important;
      .pageHeader-block {
        &.mobile-logo {
          .logo-title {
            display: none;
          }
        }
      }
    }

    section {
      overflow: hidden;
    }
  }
}
.block-content {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
}
.mainBlock {
    background: $background-light-gray;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    &.pageHeader {
        padding: 0;

        &.show-red {
            .guides__block { 
                z-index: 1; 

                &-list {
                  z-index: 1;
                }
            }
            .guides__block-list li { 
                background: rgba(255,255,255,.5); 
            }

            #PageTitle-3, #PageTitle-4 {
              z-index: 333 !important;
            }
        }
  }
  
  .guides__block {
    position: relative;
    display: block;
    height: 100%;
    &-list li {
      background: $guides-color-light;
    }
  }


  @media (max-width: 1024px) {
    .mainBlock {
      height: 100%;
    }
    .guides__block {
        height: 100vh;
      }
  }


  .pageHeader-block {
    max-width: 1041px;
    width: 100%;
    position: absolute;
    top: 50px;
    left: calc(50% - 624px);
    z-index: 1;
    &.mobile-logo {
      position: absolute;
      top: 24px;
      display: none;
      .logo {
        img {
          max-width: 32px;
          width: 100%;
        }
      }
      .logo-title {
        margin: 40px 0 0 10px;
        img {
          max-width: 240px;
          width: 100%;
        }
      }
    }
  }

  .slick-slide .slide {
    height: 100vh;
    overflow: hidden;
  }
  &_title {
    max-width: 797px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 380px;
    left: 130px;
    z-index: 1;
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 88px;
      font-weight: 400;
      color: $primary;
      line-height: 103%;
      text-transform: uppercase;
      span {
        max-width: 210px;
        width: 100%;
        font-size: 22px;
        line-height: 160%;
        text-transform: initial;
      }
      p {
        margin-left: 40px;
      }
    }
    .btn {
      margin-top: 60px;
    }
  }

  //.modeling_element {
  //  background: url("../img/modeling_element_main.png") no-repeat center center;
  //  background-size: contain;
  //  width: 780px;
  //  height: 800px;
  //  position: absolute;
  //  bottom: -324px;
  //  left: -130px;
  //  z-index: -1;
  //}
  //.blockchain_element {
  //  background: url("../img/blockchain_element.png") no-repeat center center;
  //  background-size: contain;
  //  width: 210px;
  //  height: 238px;
  //  position: absolute;
  //  top: 220px;
  //  left: -105px;
  //}
  //.mobile_element {
  //  background: url("../img/mobile_element.png") no-repeat center center;
  //  background-size: contain;
  //  width: 250px;
  //  height: 250px;
  //  position: absolute;
  //  top: -112px;
  //  right: 395px;
  //}
  //.front_end_element {
  //  background: url("../img/front_end_element.png") no-repeat center center;
  //  background-size: contain;
  //  width: 250px;
  //  height: 280px;
  //  position: absolute;
  //  bottom: 110px;
  //  right: 135px;
  //}
  &_social {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 675px);
    z-index: 2;
    ul {
      li {
        margin-bottom: 30px;
        &:last-child {
          margin: 0;
        }
        a {
          display: block;
        }
      }
    }
  }
  //.slide2 {
  //  .blockchain_element {
  //    background: url("../img/slide2_blockchain_element.svg") no-repeat center center;
  //    background-size: contain;
  //    width: 306px;
  //    height: 306px;
  //    position: absolute;
  //    top: 250px;
  //    left: -100px;
  //    z-index: -1;
  //    transform: rotate(-15deg);
  //  }
  //  .mainBlock_title {
  //    top: 320px;
  //    z-index: 1;
  //    h2 {
  //      line-height: 115%;
  //      p {
  //        margin: 0 105px 0 0;
  //      }
  //      &:nth-child(2) {
  //        margin-left: 98px;
  //      }
  //    }
  //    &_adaptive {
  //      display: none;
  //    }
  //  }
  //}
  //.slide3 {
  //  .mainBlock_title {
  //    top: 320px;
  //    z-index: 1;
  //    h2 {
  //      line-height: 103%;
  //      p {
  //        margin: 0 90px 0 0;
  //      }
  //      &:nth-child(1) {
  //        margin-left: 110px;
  //      }
  //      &:nth-child(3) {
  //        margin-left: 115px;
  //      }
  //    }
  //    &_adaptive {
  //      display: none;
  //    }
  //  }
  //  .cube {
  //    position: absolute;
  //    background: url("../img/slide3_cube.svg") no-repeat center center;
  //    background-size: contain;
  //    &-1 {
  //      top: 20px;
  //      left: -53px;
  //      width: 70px;
  //      height: 70px;
  //      transform: rotate(140deg);
  //    }
  //    &-2 {
  //      top: 330px;
  //      left: -200px;
  //      transform: rotate(144deg);
  //    }
  //    &-3 {
  //      bottom: 142px;
  //      left: -285px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(160deg);
  //    }
  //    &-4 {
  //      top: 140px;
  //      left: 270px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(130deg);
  //    }
  //    &-5 {
  //      bottom: 300px;
  //      left: 140px;
  //      width: 70px;
  //      height: 70px;
  //      transform: rotate(140deg);
  //    }
  //    &-6 {
  //      bottom: -25px;
  //      left: 0;
  //      width: 145px;
  //      height: 145px;
  //      transform: rotate(145deg);
  //    }
  //    &-7 {
  //      top: -66px;
  //      right: 235px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(171deg);
  //    }
  //    &-8 {
  //      top: 215px;
  //      right: 320px;
  //      width: 145px;
  //      height: 145px;
  //      transform: rotate(156deg);
  //    }
  //    &-9 {
  //      right: 510px;
  //      bottom: 190px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(130deg);
  //    }
  //    &-10 {
  //      top: 75px;
  //      right: -77px;
  //      width: 70px;
  //      height: 70px;
  //      transform: rotate(1deg);
  //    }
  //    &-11 {
  //      top: 360px;
  //      right: 15px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(171deg);
  //    }
  //    &-12 {
  //      right: 135px;
  //      bottom: 78px;
  //      width: 145px;
  //      height: 145px;
  //      transform: rotate(156deg);
  //    }
  //    &-13 {
  //      right: -170px;
  //      bottom: -25px;
  //      width: 90px;
  //      height: 90px;
  //      transform: rotate(171deg);
  //    }
  //  }
  //}
  //.slide4 {
  //  background: $primary-red;
  //  .guides__block {
  //    z-index: 1;
  //    &-list {
  //      li {
  //        background: rgba(255,255,255,.5);
  //      }
  //    }
  //  }
  //  .mainBlock_title {
  //    top: 320px;
  //    h2 {
  //      span {
  //        max-width: 125px;
  //      }
  //      &:nth-child(3) {
  //        margin-left: 107px;
  //      }
  //    }
  //  }
  //  .cube {
  //    position: absolute;
  //    background: url("../img/cube_white.svg") no-repeat center center;
  //    background-size: contain;
  //    &-1 {
  //      top: 16px;
  //      left: -22px;
  //      width: 130px;
  //      height: 130px;
  //      transform: rotate(30deg);
  //    }
  //    &-2 {
  //      bottom: 170px;
  //      left: -47px;
  //      width: 260px;
  //      height: 260px;
  //      transform: rotate(150deg);
  //    }
  //    &-3 {
  //      top: -125px;
  //      right: 190px;
  //      width: 390px;
  //      height: 390px;
  //      transform: rotate(30deg);
  //    }
  //    &-4 {
  //      bottom: -288px;
  //      right: -15px;
  //      width: 520px;
  //      height: 520px;
  //      transform: rotate(165deg);
  //    }
  //  }
  //}
  //.slide5 {
  //  background: $primary-red;
  //  .guides__block {
  //    z-index: 1;
  //    &-list {
  //      li {
  //        background: rgba(255,255,255,.5);
  //      }
  //    }
  //  }
  //  .mainBlock_title {
  //    top: 320px;
  //    z-index: 1;
  //    h2 {
  //      line-height: 103%;
  //      p {
  //        margin: 0 106px 0 0;
  //      }
  //      &:nth-child(2) {
  //        margin-left: 155px;
  //      }
  //      &:nth-child(3) {
  //        margin-left: 70px;
  //      }
  //    }
  //    &_adaptive {
  //      display: none;
  //    }
  //  }
  //  .cube {
  //    position: absolute;
  //    background: url("../img/cube_white.svg") no-repeat center center;
  //    background-size: contain;
  //    &-1 {
  //      top: -130px;
  //      left: -212px;
  //      width: 260px;
  //      height: 260px;
  //      transform: rotate(18deg);
  //    }
  //    &-2 {
  //      bottom: -160px;
  //      left: -575px;
  //      width: 520px;
  //      height: 520px;
  //      transform: rotate(165deg);
  //    }
  //    &-3 {
  //      top: -300px;
  //      right: 180px;
  //      width: 390px;
  //      height: 390px;
  //      transform: rotate(167deg);
  //    }
  //    &-4 {
  //      right: 195px;
  //      bottom: -5px;
  //      width: 650px;
  //      height: 650px;
  //      transform: rotate(13deg);
  //    }
  //  }
  //}
}

@media (max-width: 1366px) {
  .mainBlock {
    &_social {
      left: calc(50% - 545px);
    }
  }
}
@media(max-width: 1366px) and (max-height: 768px) {
  .mainBlock {
    &_title {
      top: 188px;
    }
    //.slide2 .mainBlock_title,
    //.slide3 .mainBlock_title,
    //.slide4 .mainBlock_title {
    //  top: 188px;
    //}
  }

}
@media(max-width: 1280px) {
  .mainBlock {
    .pageHeader-block {
      top: 70px;
    }
    &_title {
      top: 220px;
    }
    &_social {
      display: none;
    }
  }
}
@media(max-width: 1280px) and (max-height: 720px) {
  .mainBlock {
    &_title {
      top: 210px;
    }
    .blockchain_element {
      width: 176px;
      height: 158px;
      top: 144px;
    }
    .front_end_element {
      width: 222px;
      height: 200px;
      right: 256px;
      bottom: 148px;
    }
  }
}
@media(max-width: 1199px) {
  .mainBlock {
    .pageHeader-block {
      max-width: 728px;
      left: calc(50% - 465px);
    }
    &_title {
      max-width: 100%;
      top: 470px;
      left: 0;
      h2 {
        font-size: 73px;
        line-height: 124%;
        span {
          max-width: 150px;
          font-size: 20px;
        }
        p {
          margin-left: 30px;
        }
      }
    }
    .modeling_element {
      width: 883px;
      height: 905px;
      bottom: 156px;
      left: -335px;
    }
    .blockchain_element {
      width: 342px;
      height: 342px;
      top: initial;
      right: -240px;
      bottom: 450px;
      left: initial;
    }
    .mobile_element {
      width: 204px;
      height: 204px;
      top: -90px;
      right: 262px;
    }
    .front_end_element {
      width: 222px;
      height: 222px;
      right: initial;
      bottom: 296px;
      left: -230px;
    }
    //.slide2 {
    //  .mainBlock_title {
    //    display: none;
    //    top: 470px;
    //    h2 {
    //      line-height: 124%;
    //      &:nth-child(2) {
    //        margin-left: 0;
    //      }
    //      p {
    //        margin: 0 0 0 42px;
    //      }
    //    }
    //    &_adaptive {
    //      display: block;
    //    }
    //  }
    //  .blockchain_element {
    //    width: 458px;
    //    height: 458px;
    //    top: initial;
    //    right: -190px;
    //    bottom: 335px;
    //    left: initial;
    //    transform: rotate(-30deg);
    //  }
    //}
    //.slide3 {
    //  .mainBlock_title {
    //    display: none;
    //    top: 470px;
    //    h2 {
    //      line-height: 124%;
    //      &:nth-child(1),
    //      &:nth-child(3) {
    //        margin-left: 0;
    //      }
    //      p {
    //        margin: 0 0 0 30px;
    //      }
    //    }
    //    &_adaptive {
    //      display: block;
    //    }
    //  }
    //  .cube {
    //    &-1 {
    //      top: 265px;
    //      left: 230px;
    //      width: 72px;
    //      height: 72px;
    //      transform: rotate(15deg);
    //    }
    //    &-2 {
    //      top: initial;
    //      bottom: 300px;
    //      left: -68px;
    //      width: 212px;
    //      height: 212px;
    //      transform: rotate(30deg);
    //    }
    //    &-3 {
    //      top: 520px;
    //      right: 174px;
    //      bottom: initial;
    //      left: initial;
    //      width: 150px;
    //      height: 150px;
    //      transform: rotate(160deg);
    //    }
    //    &-4 {
    //      top: initial;
    //      right: 330px;
    //      bottom: 455px;
    //      left: initial;
    //      width: 50px;
    //      height: 50px;
    //      transform: rotate(135deg);
    //    }
    //    &-5 {
    //      top: 240px;
    //      right: -155px;
    //      bottom: initial;
    //      left: initial;
    //      width: 130px;
    //      height: 130px;
    //      transform: rotate(165deg);
    //    }
    //    &-6 {
    //      top: initial;
    //      right: -190px;
    //      bottom: 540px;
    //      left: initial;
    //      width: 144px;
    //      height: 139px;
    //      transform: rotate(15deg);
    //    }
    //    &-7 {
    //      top: initial;
    //      right: 150px;
    //      bottom: 265px;
    //      left: initial;
    //      width: 103px;
    //      height: 103px;
    //      transform: rotate(160deg);
    //    }
    //    &-8,
    //    &-9,
    //    &-10,
    //    &-11,
    //    &-12,
    //    &-13 {
    //      display: none;
    //    }
    //  }
    //}
    //.slide4 {
    //  .mainBlock_title {
    //    top: 470px;
    //    h2 {
    //      line-height: 124%;
    //      &:nth-child(3) {
    //        margin-left: 0;
    //      }
    //      span {
    //        max-width: 150px;
    //      }
    //      p {
    //        margin: 0 0 0 30px;
    //      }
    //    }
    //  }
    //  .cube {
    //    &-1 {
    //      top: 270px;
    //      right: 75px;
    //      bottom: initial;
    //      left: initial;
    //      width: 370px;
    //      height: 370px;
    //      transform: rotate(160deg);
    //    }
    //    &-2 {
    //      top: initial;
    //      right: initial;
    //      bottom: 145px;
    //      left: -225px;
    //      width: 366px;
    //      height: 366px;
    //      transform: rotate(30deg);
    //    }
    //    &-3 {
    //      top: 95px;
    //      right: -270px;
    //      bottom: initial;
    //      left: initial;
    //      width: 185px;
    //      height: 185px;
    //      transform: rotate(165deg);
    //    }
    //    &-4 {
    //      top: initial;
    //      right: -70px;
    //      bottom: 95px;
    //      left: initial;
    //      width: 262px;
    //      height: 262px;
    //      transform: rotate(160deg);
    //    }
    //  }
    //}
    //.slide5 {
    //  .mainBlock_title {
    //    display: none;
    //    top: 470px;
    //    h2 {
    //      line-height: 124%;
    //      &:nth-child(2) {
    //        margin-left: 0;
    //      }
    //      &:nth-child(3) {
    //        margin-left: 0;
    //      }
    //      p {
    //        margin: 0 0 0 30px;
    //      }
    //    }
    //    &_adaptive {
    //      display: block;
    //    }
    //  }
    //  .cube {
    //    &-1 {
    //      top: initial;
    //      right: initial;
    //      bottom: -10px;
    //      left: -330px;
    //      width: 427px;
    //      height: 427px;
    //      transform: rotate(30deg);
    //    }
    //    &-2 {
    //      top: 80px;
    //      right: -215px;
    //      bottom: initial;
    //      left: initial;
    //      width: 674px;
    //      height: 674px;
    //      transform: rotate(160deg);
    //    }
    //    &-3 {
    //      top: initial;
    //      right: -300px;
    //      bottom: -120px;
    //      left: initial;
    //      width: 381px;
    //      height: 381px;
    //      transform: rotate(160deg);
    //    }
    //    &-4 {
    //      display: none;
    //    }
    //  }
    //}
  }
}

@media (max-width: 1023px) {
  body {
    &.home {
      .sliderAnim {
        &.desktop {
          display: none;
        }
  
        &.mobile {
          display: block;
        }
      }
    }
  }

  .mainBlock {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}


@media(max-width: 991px) {
  .mainBlock {
    .pageHeader-block {
      max-width: 628px;
      left: calc(50% - 338px);
    }
    &_title {
      top: 300px;
      h2 {
        font-size: 60px;
        span {
          font-size: 16px;
        }
        p {
          margin-left: 5px;
        }
      }
    }
    .modeling_element {
      width: 600px;
      height: 600px;
      bottom: 50px;
      left: -200px;
    }
    .blockchain_element {
      width: 250px;
      height: 250px;
      right: -165px;
      bottom: 300px;
    }
    .mobile_element {
      right: 210px;
    }
    .front_end_element {
      left: -160px;
    }
    .slide2,
    .slide3,
    .slide4,
    .slide5 {
      .mainBlock_title {
        top: 300px;
        h2 p {
          margin-left: 5px;
        }
      }
    }
    //.slide3 {
    //  .cube {
    //    &-3 {
    //      top: initial;
    //      bottom: 100px;
    //    }
    //    &-5 {
    //      right: -65px;
    //    }
    //    &-6 {
    //      right: -65px;
    //      bottom: 440px;
    //      width: 90px;
    //      height: 90px;
    //    }
    //    &-7 {
    //      display: none;
    //    }
    //  }
    //}
    //.slide4 {
    //  .cube {
    //    &-1 {
    //      top: 150px;
    //      right: 150px;
    //      width: 300px;
    //      height: 300px;
    //    }
    //    &-2 {
    //      left: -180px;
    //      width: 280px;
    //      height: 280px;
    //    }
    //    &-3 {
    //      top: 150px;
    //      right: -150px;
    //      width: 150px;
    //      height: 150px;
    //    }
    //    &-4 {
    //      right: -50px;
    //      bottom: 95px;
    //      width: 200px;
    //      height: 200px;
    //    }
    //  }
    //}
    //.slide5 {
    //  .cube {
    //    &-1 {
    //      top: initial;
    //      right: initial;
    //      bottom: -10px;
    //      left: -300px;
    //      width: 400px;
    //      height: 400px;
    //      transform: rotate(30deg);
    //    }
    //    &-2 {
    //      top: 40px;
    //      right: -120px;
    //      bottom: initial;
    //      left: initial;
    //      width: 474px;
    //      height: 474px;
    //      transform: rotate(160deg);
    //    }
    //    &-3 {
    //      top: initial;
    //      right: -300px;
    //      bottom: -120px;
    //      left: initial;
    //      width: 381px;
    //      height: 381px;
    //      transform: rotate(160deg);
    //    }
    //  }
    //}
  }
}
@media(max-width: 1024px) and (max-height: 768px) {
  .mainBlock {
    .guides__block {
      display: flex;
      justify-content: center;
    }
    &_title {
      position: static;
    }
    .blockchain_element {
      width: 250px;
      height: 250px;
      right: -242px;
      bottom: 350px;
    }
  }
}
@media(max-width: 767px) {

  .block-content {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
  }


  .mainBlock {
    .pageHeader-block {
      max-width: 428px;
      top: 50px;
      left: calc(50% - 228px);
    }
    &_title {
      h2 {
        font-size: 37px;
        line-height: 140%;
        letter-spacing: 1.2px;
        span {
          font-size: 14px;
          line-height: 130%;
        }
        p {
          margin-left: 10px;
        }
      }
    }
    .blockchain_element {
      width: 150px;
      height: 150px;
      right: -50px;
    }
    .mobile_element {
      width: 150px;
      height: 150px;
      top: -75px;
      right: 139px;
    }
    .front_end_element {
      width: 150px;
      height: 150px;
      bottom: 50px;
      left: 0;
    }
    //.slide2,
    //.slide3,
    //.slide4,
    //.slide5 {
    //  .mainBlock_title {
    //    h2 {
    //      line-height: 140%;
    //    }
    //  }
    //}
    //.slide2 {
    //  .blockchain_element {
    //   width: 234px;
    //   height: 242px;
    //   bottom: 200px;
    //   right: -95px;
    //  }
    //}
    //.slide3 {
    //  .cube {
    //    &-1 {
    //      left: 180px;
    //    }
    //    &-2 {
    //      top: 400px;
    //      bottom: initial;
    //    }
    //    &-4 {
    //      right: 130px;
    //    }
    //  }
    //}
    //.slide4 {
    //  .cube {
    //    &-1 {
    //      top: 100px;
    //      right: 100px;
    //      width: 200px;
    //      height: 200px;
    //    }
    //    &-2 {
    //      left: -180px;
    //      width: 280px;
    //      height: 280px;
    //    }
    //    &-3 {
    //      top: 350px;
    //      right: -57px;
    //      width: 100px;
    //      height: 100px;
    //    }
    //    &-4 {
    //      right: 40px;
    //      bottom: 55px;
    //      width: 150px;
    //      height: 150px;
    //    }
    //  }
    //}
    //.slide5 {
    //  .cube {
    //    &-1 {
    //      width: 350px;
    //      height: 350px;
    //    }
    //    &-2 {
    //      width: 400px;
    //      height: 400px;
    //    }
    //    &-3 {
    //      right: -250px;
    //      bottom: -120px;
    //      width: 330px;
    //      height: 330px;
    //    }
    //  }
    //}
  }
}
@media(max-width: 480px) {
  .mainBlock {
    .pageHeader-block {
      max-width: 325px;
      left: calc(50% - 162px);
      &.mobile-logo {
        display: block;
        .logo-title img {
          max-width: 210px;
        }
      }
    }
    &_title {
      position: absolute;
      top: 230px;
      left: 12px;
      h2 {
        span {
          display: none;
        }
        p {
          margin-left: 0;
        }
      }
    }
    .modeling_element {
      width: 525px;
      height: 538px;
      bottom: 100px;
      left: -290px;
    }
    .blockchain_element {
      right: -100px;
      bottom: 222px;
    }
    .mobile_element {
      width: 110px;
      height: 110px;
      top: -75px;
      right: 108px;
    }
    .front_end_element {
      bottom: 140px;
      left: -83px;
    }
    //.slide2,
    //.slide3,
    //.slide4,
    //.slide5 {
    //  .mainBlock_title {
    //    top: 230px;
    //    h2 p {
    //      margin-left: 0;
    //    }
    //  }
    //}
    //.slide3 {
    //  .cube {
    //    &-1 {
    //      top: initial;
    //      right: initial;
    //      bottom: 220px;
    //      left: -5px;
    //      width: 82px;
    //      height: 80px;
    //      transform: rotate(112deg);
    //    }
    //    &-2 {
    //      top: initial;
    //      right: initial;
    //      bottom: 60px;
    //      left: -40px;
    //      width: 50px;
    //      height: 50px;
    //      transform: rotate(112deg);
    //    }
    //    &-3 {
    //      top: 190px;
    //      right: 35px;
    //      bottom: initial;
    //      left: initial;
    //      width: 40px;
    //      height: 40px;
    //      transform: rotate(170deg);
    //    }
    //    &-4 {
    //      top: 310px;
    //      right: 70px;
    //      bottom: initial;
    //      left: initial;
    //      width: 64px;
    //      height: 64px;
    //      transform: rotate(150deg);
    //    }
    //    &-5 {
    //      top: initial;
    //      right: 120px;
    //      bottom: 200px;
    //      left: initial;
    //      width: 37px;
    //      height: 37px;
    //      transform: rotate(50deg);
    //    }
    //    &-6 {
    //      top: initial;
    //      right: 10px;
    //      bottom: 80px;
    //      left: initial;
    //      width: 50px;
    //      height: 50px;
    //      transform: rotate(112deg);
    //    }
    //  }
    //}
    //.slide4 {
    //  .cube {
    //    &-1 {
    //      top: initial;
    //      right: initial;
    //      bottom: 200px;
    //      left: -40px;
    //      width: 123px;
    //      height: 123px;
    //      transform: rotate(112deg);
    //    }
    //    &-2 {
    //      top: 155px;
    //      right: -20px;
    //      bottom: initial;
    //      left: initial;
    //      width: 200px;
    //      height: 200px;
    //      transform: rotate(165deg);
    //    }
    //    &-3 {
    //      top: initial;
    //      right: 100px;
    //      bottom: 135px;
    //      left: initial;
    //      width: 65px;
    //      height: 65px;
    //      transform: rotate(50deg);
    //    }
    //    &-4 {
    //      top: initial;
    //      right: -60px;
    //      bottom: -5px;
    //      left: initial;
    //      width: 105px;
    //      height: 105px;
    //      transform: rotate(112deg);
    //    }
    //  }
    //}
    //.slide5 {
    //  .cube {
    //    &-1 {
    //      top: initial;
    //      right: initial;
    //      bottom: 115px;
    //      left: -200px;
    //      width: 238px;
    //      height: 238px;
    //      transform: rotate(112deg);
    //    }
    //    &-2 {
    //      top: 5px;
    //      right: -110px;
    //      bottom: initial;
    //      left: initial;
    //      width: 337px;
    //      height: 337px;
    //      transform: rotate(165deg);
    //    }
    //    &-3 {
    //      top: initial;
    //      right: -85px;
    //      bottom: 40px;
    //      left: initial;
    //      width: 160px;
    //      height: 160px;
    //      transform: rotate(50deg);
    //    }
    //  }
    //}
  }
}
@media (max-width: 736px) and (max-height: 414px) {
  .mainBlock {
    &_title{
      h2 {
        line-height: 120%;
      }
    }
    .blockchain_element {
      width: 140px;
      height: 140px;
      bottom: 150px;
      right: -120px;
    }
    .front_end_element {
      width: 120px;
      height: 120px;
      bottom: 10px;
      left: -50px;
    }
  }
}

@media(max-width: 375px) {
  .mainBlock {
    .mobile_element {
      right: 108px;
    }
    //.slide4 {
    //  .cube {
    //    &-2 {
    //      top: 120px;
    //      right: -20px;
    //      bottom: initial;
    //      left: initial;
    //      width: 150px;
    //      height: 150px;
    //      transform: rotate(165deg);
    //    }
    //    &-3 {
    //      bottom: 150px;
    //    }
    //  }
    //}
    //.slide5 {
    //  .cube {
    //    &-1 {
    //      bottom: 100px;
    //      left: -165px;
    //      width: 200px;
    //      height: 200px;
    //      transform: rotate(112deg);
    //    }
    //    &-2 {
    //      right: -80px;
    //      width: 280px;
    //      height: 280px;
    //    }
    //  }
    //}
  }
}

@media(max-width: 812px) and (max-height: 375px) {
  .mainBlock {
    &_title {
      position: absolute;
      top: 140px;
      h2 {
        font-size: 28px;
        line-height: 140%;
        span {
          max-width: 100px;
          font-size: 12px;
          line-height: 100%;
        }
        p {
          margin-left: 10px;
        }
      }
    }
    .blockchain_element {
      width: 140px;
      height: 140px;
      bottom: initial;
      right: 0;
      top: 80px;
    }
    .mobile_element {
      width: 140px;
      height: 140px;
      top: -60px;
      right: 242px;
    }
    .front_end_element {
      width: 120px;
      height: 120px;
      bottom: -40px;
      left: -80px;
    }
  }
}

@media(max-width: 340px) {
  .mainBlock {
    .pageHeader-block {
      max-width: 280px;
      left: calc(50% - 138px);
    }
    .mobile_element {
      right: 85px;
    }
  }
}
@media(max-width: 320px) {
  .mainBlock {
    &_title {
      h2 {
        font-size: 28px;
      }
    }
    .blockchain_element {
      width: 120px;
      height: 120px;
      right: -55px;
    }
    .front_end_element {
      width: 100px;
      height: 100px;
      bottom: 100px;
      left: -25px;
    }
    //.slide2 {
    //  .blockchain_element {
    //    width: 180px;
    //    height: 180px;
    //    right: -120px;
    //  }
    //}
    //.slide3 {
    //  .cube {
    //    &-1 {
    //      top: 150px;
    //      bottom: initial;
    //    }
    //    &-3 {
    //      top: 100px;
    //    }
    //    &-4 {
    //      top: 210px;
    //    }
    //    &-5 {
    //      bottom: 170px;
    //    }
    //    &-6 {
    //      bottom: 30px;
    //    }
    //  }
    //}
    //.slide4 {
    //  .cube {
    //    &-1 {
    //      bottom: 150px;
    //      left: -22px;
    //      width: 100px;
    //      height: 100px;
    //    }
    //    &-2 {
    //      top: 130px;
    //      right: 0;
    //      width: 120px;
    //      height: 120px;
    //    }
    //    &-3 {
    //      top: initial;
    //      right: 65px;
    //      bottom: 120px;
    //      left: initial;
    //      width: 65px;
    //      height: 65px;
    //      transform: rotate(50deg);
    //    }
    //    &-4 {
    //      top: initial;
    //      right: -60px;
    //      bottom: -5px;
    //      left: initial;
    //      width: 105px;
    //      height: 105px;
    //      transform: rotate(112deg);
    //    }
    //  }
    //}
  }
}
@media(max-width: 568px) and (max-height: 320px) {
  .mainBlock {
    .pageHeader-block {
      top: 24px;
    }
    &_title {
      top: 100px;
    }
    .blockchain_element {
      width: 120px;
      height: 120px;
      top: 50px;
    }
    .mobile_element {
      top: -90px;
      right: 144px;
    }
  }
}


@media(min-width: 1920px) and (min-height: 1080px) {
  .mainBlock {
    max-height: 1080px;
    .guides__block {
      max-height: 1080px;
    }
    .slick-slide .slide {
      max-height: 1080px;
    }
  }
}

.contacts {
  padding-bottom: 0;
  background: $background-gray;
  position: relative;
  overflow: visible !important;
  z-index: 3;
  .section__title-block {
    
    @media (min-width: 1199px) {
      margin-bottom: -116px;
    }
  }
  .guides__block-list li {
    background: $guides-color-light;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__col {
    width: 50%;
    position: relative;
    @media (min-width: 1199px) {
      margin-bottom: -95px;
    }

    .description {
      max-width: 430px;
      font-size: 22px;
      line-height: 160%;
      color: $guides-color-dark;

      @media (min-width: 1199px) {
        padding-top: 134px;
      }

      .links {
        margin-top: 20px;
        a {
          color: #000000;
          text-decoration: none;
          font-size: 26px;
          display: inline-block;
          font-weight: 500;

          & > div {
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-gap: 16px;
            align-items: center;
            margin-bottom: 10px;

            svg {
              width: 24px;
              max-width: 24px;
              path {
                fill: #000;
                transition: all .3s;
              }
            }

            &:nth-child(2) {
              margin-bottom: 0;
            }
          }

          &:hover {
            color: $primary-red;
            & > div {
              svg {
                path {
                  fill: $primary-red;
                }
              }
            }
          }
        }
      }
    }
    &_form {
      padding: 50px 40px 40px 40px;
      width: 470px;
      background: $white;
      .btn {
        margin-top: 80px;
        width: 100%;
        font-size: 14px;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
      }

      .checkbox-text {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .contacts {
    padding-bottom: 120px;
    .section__title-block {
      padding-bottom: 60px;
    }
    &__row {
      flex-direction: column;
      align-items: center;
    }
    &__col {
      width: auto;
      bottom: 0;
      .description {
        max-width: 540px;
        font-size: 24px;
      }
      &_form {
        margin-top: 120px;
        padding: 0;
        bottom: 0;
        width: 540px;
        background: transparent;
        .btn {
          margin-top: 64px;
        }
      }
    }
  }
}

@media(max-width: 991px) {
  .contacts {
    padding-bottom: 100px;
    &__col {
      .description {
        font-size: 20px;

        .links {
          a {
            font-size: 22px;
          }
        }
      }
      &_form {
        margin-top: 60px;
      }
    }
  }
}

@media(max-width: 767px) {
  .contacts {
    padding-bottom: 60px;
    &__col {
      .description {
        font-size: 18px;
      }
      &_form {
        margin-top: 40px;
        width: 100%;
      }
    }
  }
}

@media(max-width: 480px) {
  .contacts {
    .section__title-block {
      padding-bottom: 20px;
    }
    &__col {
      &_form {
        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

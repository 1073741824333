.faq {
    background: $background-light-gray;
    .guides__block {
        &-list {
            z-index: 0;
            li {
                background: $guides-color-light;
            }
        }
    }
    &_cont {
        display: grid;
        grid-template-columns: 391px 1fr;
        counter-reset: spoilers;
        position: relative;
        padding-right: 1px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
        }
        
        .spoiler {
            .head {
                position: relative;

                &:after {
                    position: absolute;
                    left: 0;
                    counter-increment: spoilers;
                    content: "Q" counter(spoilers) ": ";
                    color: #fff;
                    background: $primary-red;
                    top: 0;
                    height: 100%;
                    width: 50px;
                    text-align: center;
                    padding-top: 18px;
                }
            }

            .cont {
                position: relative;
                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 50px;
                    height: 1px;
                    width: calc(100% - 50px);
                    background: #ededed;
                }
                &:after {
                    content: '';
                    position: absolute;
                    background: $primary-red;
                    top: -1px;
                    height: calc(100% - -1px);
                    left: 0;
                    width: 50px;
                }
            }
        }
    }
}

.single-post-item .content .text {
    counter-reset: spoilers;

    .spoiler {
        margin: 0 0 21px 0;
        .head {
            background: #f7f7f9;
            &:after {
                position: absolute;
                left: 0;
                counter-increment: spoilers;
                content: "Q" counter(spoilers) ": ";
                color: #fff;
                background: $primary-red;
                top: 0;
                height: 100%;
                width: 50px;
                text-align: center;
                padding-top: 18px;
            }
        }

        .cont {
            background: #f7f7f9;
            position: relative;
            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 50px;
                height: 1px;
                width: calc(100% - 50px);
                background: #ededed;
            }
            &:after {
                content: '';
                position: absolute;
                background: $primary-red;
                top: -1px;
                height: calc(100% - -1px);
                left: 0;
                width: 50px;
            }
        }
    }
}

section#team_carousel {
  padding-bottom: 0;
  @media (min-width: 992px) {
    overflow: visible;
  }

  @media (max-width: 992px) {
    .swiper-team-wrap {
      margin: 0;
    }
  }
}

section#blog {
  @media (min-width: 992px) {
    padding-top: 130px;
  }
}

.vac-block {
  width: 100%;
  max-width: 1041px;
  margin: -100px auto 0 auto;
  background: $primary-red;
  color: #fff;
  position: relative;
  transform: translateY(50%);
  padding: 20px 40px;
  z-index: 9;

  @media (max-width: 992px) {
    transform: none;
    margin: 50px 0 0 0;
    padding: 30px 40px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 6px;

    @media (max-width: 992px) {
      font-size: 22px;
      line-height: normal;
    }
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px;
  }

  .foot {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-gap: 20px;

      a.btn {
        margin: 0 auto;
      }
    }
  }
}

#partners {
  background: #151518;
  position: relative;
  color: #fff;
  overflow: hidden;
  z-index: 1;

  .guides__block {
    .swiper-pagination {
      display: none;
      position: relative;
      bottom: 0;
      margin-top: 15px;

      @media (max-width: 768px) {
        display: block;
      }

      &-bullet {
        background: #fff;
        outline: none;
      }

      .swiper-pagination-bullet-active {
          background: $primary-red;
      }

      .swiper-pagination-clickable .swiper-pagination-bullet {
          outline: none;
      }

      .swiper-pagination-bullet {
        margin: 0 4px;
      }
  }
    .swiper-partners {
      position: relative;
      .swiper-slide {
        text-align: center;
        height: initial;
        justify-content: center;
        display: flex;

        a {
          display: block;
          width: 100%;
        }

          img {
            max-width: 100%;
            width: 100%;
            display: block;
            max-height: 55px;
            object-fit: contain;
          }
      }
    }

    .swiper-partners-container {
      position: relative;
      overflow: hidden;
    }
  }

  .guides__block-list li {
    background: #232326;
  }
  &__row {
    max-width: 1041px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  &-btn-wrap {
    padding-right: 80px;
  }
}

@media(max-width: 1199px) {
  .partners {
    &__row {
      max-width: 100%;
      
    }
    &-btn-wrap {
      padding-right: 0;
    }
  }
}
@media (max-width: 768px) {
  .partners {
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
  }
}

@media(max-width: 480px) {
  .partners {
    &__row .team__col {
      .team-img img {
        height: 200px;
      }

      &.team-btn-wrap {
        padding: 0;
        align-items: center;
      }
    }
  }
}

.manifesto {
  background: $primary;
  position: relative;
  z-index: 1;
  .guides__block-list li {
    background: #505053;
  }
  .section__title-block {
    color: $white;
  }

  .numbers {
      padding-top: 80px;
      padding-left: 50px;
      max-width: 910px;
      h3 {
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        padding-bottom: 50px;
      }

      .counters {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 60px;
            grid-column-gap: 50px;

          .block {
            display: grid;
            grid-template-columns: 150px 1fr;
            grid-gap: 30px;

              span {
                min-width: 80px;
                font-size: 70px;
                line-height: 110%;
                text-transform: uppercase;
                color: #FC5959;
                text-align: right;
              }

              p {
                font-size: 22px;
                line-height: 160%;
                color: #fff;
                max-width: 290px;

                @media (max-width: 767px) {
                  font-size: 18px;
              }

              }
          }
      }
  }

  .description {
    display: block;
    padding: 0 50px;
    font-size: 22px;
    color: $white;
    
    p {
      padding-bottom: 30px;
      line-height: 130%;
      font-weight: 400;
      padding-left: 50px;
      position: relative;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 160%;
    }

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
            position: absolute;
            left: 0;
            top: 14px;
            content: '';
            width: 16px;
            height: 4px;
            background: #fff;
        }
    }
    &__left {
      padding-top: 5px;
      max-width: 132px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: right;
      p {
        padding: 0;
        font-weight: 900;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
      }
    }
    &__right {
      margin: 0 50px 0 128px;
    }
    &-bottom {
      padding-top: 30px;
      .description__left {
        justify-content: flex-end;
      }
    }
  }
  &-btn-wrap {
    padding-top: 70px;
    max-width: 910px;
    display: flex;
    justify-content: flex-end;
  }
}


@media(max-width: 1199px) {
  .manifesto {
      .numbers {
          padding-left: 0;
          padding-right: 0;
          .counters {
              grid-template-columns: 1fr;
              grid-gap: 50px;
          }
      }
    .description {
        padding: 0;
      &__left {
        max-width: 92px;
        p {
          font-size: 18px;
          letter-spacing: 0.23em;
        }
      }
      &__right {
        margin: 0 0 0 92px;
        p {
          font-size: 24px;
        }
      }
    }
    &-btn-wrap {
      justify-content: center;
    }
  }
}
@media(max-width: 991px) {
  .manifesto {
    .description {
      &__left {
        max-width: 80px;
        p {
          font-size: 14px;
        }
      }
      &__right {
        margin-left: 80px;
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .manifesto {
    .numbers {
      .counters {
        .block {
          grid-template-columns: 100px 1fr;

          span {
            font-size: 48px; min-width: 52px;
          }
        }
      }
    }
    .description {
      &__left {
        max-width: 52px;
        p {
          font-size: 12px;
        }
      }
      &__right {
        margin-left: 52px;
        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .manifesto {
    .description {
      flex-direction: column;
      &__right {
        padding-top: 16px;
        margin: 0;
      }
      &__left {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        p {
          padding-right: 10px;
        }
      }
      &-bottom {
        .description__left {
          justify-content: flex-start;
        }
      }
    }
    &-btn-wrap {
      padding-top: 40px;
    }
  }
}
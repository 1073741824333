.footer {
  padding: 90px 0 20px 0;
  background: $primary;
  z-index: 2;
  position: relative;
  &__logo-wrap {
    margin-bottom: 50px;
    img {
      width: 120px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;

    &.part {
      border-top: 1px solid #ffffff0d;
      padding: 30px 0;
      margin-top: 20px;
    }

    .copyright {
      color: #9F9FA0;
      opacity: 0.8;
    }

    .marketing {
      color: #9F9FA0;
      opacity: 0.8;

      display: inline-flex;
      align-items: center;

      img {
        width: 100px;
        margin-left: 8px;
        height: 21px;
      }
    }

    .partners {
      display: flex;

      @media (max-width: 768px) {
        display: flex;
        flex-flow: column;
        
        & > div {
          margin-bottom: 20px;

          &.clath {
            order: 2; 
          }

          &.top_dev {
            order: 1; 
          }

          &.GoodFirms {
            order: 3; 
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .clath {
        max-width: 200px;
        margin-right: 20px;
      }

      .top_dev {
        margin-right: 40px;
        img {
          max-width: 52px;
        }
      }

      .GoodFirms {
        img {
          width: 188px;
          max-height: 42px;
          max-width: 188px;
        }
      }

    }
  }
  &__col {
    max-width: 25%;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.5px;
    color: $white;
    &:last-child {
      width: 260px;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 900;
      line-height: 140%;
      letter-spacing: 0.3em;
      text-transform: uppercase;
    }
    .footer_address {
      p {
        margin-bottom: 10px;
        span {
          display: block;
        }
      }
    }
    .copyright {
      margin-top: 80px;
      color: $primary-black;
      opacity: 0.8;
    }
    a {
      display: block;
    }
    .subscribe_wrap {
      position: relative;
      input[type=email] {
        margin: 0;
        padding: 0 26px 0 12px;
        height: 41px;
        background: rgba(214,214,214,0.1);
        border: none;
        color: #fff;
        font: 400 13.3333px Arial;
        font-family: $mont;

        &::placeholder {
          color: rgba(214,214,214,0.4);
        }
      }
      input[type=submit] {
        padding: 11px;
        position: absolute;
        right: 0px;
        top: 0;
        height: 100%;
        background: $white url('../img/arrow-right.png') center center no-repeat;
        font-size: 0;
        background-size: 18px;
        opacity: 0.7;
        width: 40px;
        img {
          max-width: 18px;
        }
      }

      .ajax-loader {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../img/loading_white.svg');
        min-width: 36px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        margin: 0;

        &:before {
          display: none;
        }
      }

      .wpcf7-response-output {
        margin: 2em 0 1em;
        font-size: 12px;
        width: 100%;
      }

      .partner {
        margin-top: 20px;
      }
    }
    .socials {
      &-responsive {
        display: none;
      }
    }
  }
}
progress{
  position:fixed;
  left:0;
  top:53px;
  width:100%;
  height:12px;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  border:none;
  background:transparent;
  color:$primary-red;
  z-index: 1;
  display: none;
}
 
progress::-webkit-progress-bar{
  background:transparent;
}
 
progress::-webkit-progress-value{
  background: #00000020;
}
progress::-moz-progress-bar{
  background: #00000020;
}
.progress-container{
  width:100%;
  background:transparent;
  position:fixed;
  top:0;
  left:0;
  height:40px;
  display:block;
}
 
.progress-bar{
  background: #00000020;
  width: 0;
  display:block;
  height:inherit;
}

@media (max-width: 767px) {
  progress {
    display: block;
  }
}

@media (max-width: 1199px) {
  .footer {
    padding: 106px 0 116px 0;
    &__logo-wrap {
      img {
        width: 182px;
      }
    }
    &__row {
      flex-wrap: wrap;
      padding-right: 0;
    }
    &__col {
      margin-bottom: 130px;
      width: 50%;
      max-width: 100%;
      font-size: 24px;
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      &:nth-child(3) {
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
        width: 100%;
      }
      h3 {
        margin-bottom: 40px;
        font-size: 18px;
      }
      .footer_address {
        p {
          margin-bottom: 60px;
          &:last-child {
            margin: 0;
          }
        }
      }
      .footer_contacts {
        max-width: 300px;
        text-align: left;
      }
      .copyright {
        display: none;
      }
      .socials {
        display: none;
        &-responsive {
          display: flex;
          li {
            margin-right: 48px;
          }
        }
      }
      .subscribe_wrap {
        width: 100%;
        form {
          position: relative;
          width: 100%;
        }
        input[type=email] {
          max-width: 100%;
          width: calc(100% - 72px);
          height: 72px;
        }
        input[type=submit] {
          top: 0;
          right: 0;
          width: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ajax-loader {
          right: 72px;
        }
      }
    }
    form {
      position: relative;
      width: 100%;
      input[type=submit] {
        top: 0;
      }
    }
  }
}

@media(max-width: 991px) {
  .footer {
    padding: 60px 0;
    &__logo-wrap img {
      width: 150px;
    }
    &__col {
      margin-bottom: 60px;
      h3 {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .footer_address {
        p {
          margin-bottom: 30px;
          font-size: 20px;
        }
      }
      .footer_contacts {
        a {
          font-size: 20px;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .footer {
    &__col {
      margin-bottom: 40px;
      h3 {
        font-size: 14px;
        margin-bottom: 18px;
      }
      .footer_address {
        p {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
      .footer_contacts {
        a {
          font-size: 16px;
        }
      }
      .socials-responsive {
        justify-content: space-between;
        li {
          margin: 0;
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .footer {
    &__row {
      flex-direction: column;
    }
    &__col {
      margin-bottom: 60px;
      max-width: 100%;
      width: 100%;
      align-items: flex-start !important;
      .socials-responsive {
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
          margin: 0 20px 30px 0;
        }
      }
      .subscribe_wrap {
        width: 100%;
        input[type=email] {
          height: 54px;
          width: calc(100% - 60px);
          font-size: 18px;
        }
        input[type=submit] {
          width: 60px;
        }

        .ajax-loader {
          right: 60px;
        }
      }
    }
  }
}

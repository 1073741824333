.l-section{
  &Left{}
  &Right{}
  &25percent{
    max-width: 25%;
    flex: 0 1 25%;
  }
  &33percent{
    max-width: 33%;
    flex: 0 1 33.3%;
  }
  &50percent{
    max-width: 50%;
    flex: 0 1 50%;
  }
  &67percent{
    max-width: 67%;
    flex: 0 1 67%;
  }
  &75percent{
    max-width: 75%;
    flex: 0 1 75%;
  }
  &2columns{
    max-width: 50%;
    flex: 0 1 50%;
  }
  &3columns{
    max-width: 33.3%;
    flex: 0 1 33.3%;
  }
  &4columns{
    max-width: 25%;
    flex: 0 1 25%;
  }
  &5columns{
    max-width: 25%;
  }
  &6columns{
    max-width: 16.6%;
  }
  &8columns{
    max-width: 12.5%;
  }
  &10columns{
    max-width: 10%;
  }
  &12columns{
    max-width: 8.3%;
  }
}
.account-menu{
  width: 280px;
}
.l{
  &-content{
    &-header{
      display: flex;
      justify-content: space-between;
    }
    &-main{
      width: calc(100% - 120px);
      padding: 35px 15px 70px;
      @include mq(1240) {
        width: calc(100% - 90px);
      }
      @include mq(1440) {
        width: calc(100% - 280px);
      }
      .stylesheet-guide &{
        margin-left: 280px;
      }
    }

  }
  &-container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    &_bet{


    }
  }
  &-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  &-percent-11{
    max-width: 11%;
    flex: 0 1 11%;
  }
  &-percent-16{
    max-width: 16.66%;
    flex: 0 1 16.66%;
  }
  &-percent-18{
    max-width: 18%;
    flex: 0 1 18%;
  }
  &-percent-25{
    max-width: 25%;
    flex: 0 1 25%;
  }
  &-percent-30{
    max-width: 30%;
    flex: 0 1 30%;
  }
  &-percent-33{
    max-width: 33.33%;
    flex: 0 1 33.33%;
  }
  &-percent-40{
    max-width: 40%;
    flex: 0 1 40%;
  }
  &-percent-44{
    max-width: 44%;
    flex: 0 1 44%;
  }
  &-percent-50{
    max-width: 50%;
    flex: 0 1 50%;
  }
  &-percent-56{
    max-width: 56%;
    flex: 0 1 56%;
  }
  &-percent-60{
    max-width: 60%;
    flex: 0 1 60%;
  }
  &-percent-65{
    max-width: 65%;
    flex: 0 1 65%;
  }
  &-percent-70{
    max-width: 70%;
    flex: 0 1 70%;
  }
  &-percent-75{
    max-width: 75%;
    flex: 0 1 75%;
  }
//cols
  &-col-1,
  &-col-2,
  &-col-3,
  &-col-4,
  &-col-5,
  &-col-6,
  &-col-7,
  &-col-8,
  &-col-9,
  &-col-10,
  &-col-11,
  &-col-12{
    padding-left: 15px;
    padding-right: 15px;
  }
  &-col-1{
    max-width: 8.33333333%;
    flex: 0 1 8.33333333%;
  }
  &-col-2{
    max-width: 16.66666667%;
    flex: 0 1 16.66666667%;
  }
  &-col-3{
    max-width: 25%;
    flex: 0 1 25%;
  }
  &-col-4{
    max-width: 33.33333333%;
    flex: 0 1 33.33333333%;
  }
  &-col-5{
    max-width: 41.66666667%;
    flex: 0 1 41.66666667%;
  }
  &-col-6{
    max-width: 50%;
    flex: 0 1 50%;
  }
  &-col-7{
    max-width: 58.33333333%;
    flex: 0 1 58.33333333%;
  }
  &-col-8{
    max-width: 66.66666667%;
    flex: 0 1 66.66666667%;
  }
  &-col-9{
    max-width: 75%;
    flex: 0 1 75%;
  }
  &-col-10{
    max-width: 83.33333333%;
    flex: 0 1 83.33333333%;
  }
  &-col-11{
    max-width: 91.66666667%;
    flex: 0 1 91.66666667%;
  }
  &-col-12{
    max-width: 100%;
    flex: 0 1 100%;
  }
}
.air-indent{
  margin: 0 20px 0 10px;
}

.u_overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

%u_bg--white { background-color: white; }
%u_bg--black { background-color: black; }
